import React from "react";

import { RefreshIcon } from "@heroicons/react/solid";

import { TransactionTable } from "./TransactionTable";

export default function DisplayExpenses({
	transactions,
	loading,
	onReload,
	isAccounting,
	globalFilter,
	filters,
	dispatch
}) {
	return (
		<>
			<div className="w-7/8 bg-white rounded-lg">
				<div className="flex w-full border-b-2 justify-between items-center ">
					<div className="ml-4 text-2xl">
						Transactions{" "}
						{loading && (
							<span className="text-sm text-green-400 pb-2">are loading</span>
						)}
					</div>

					<button
						data-testid="refresh-button"
						className="-ml-px mr-3 relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white
								 text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:z-10 focus:outline-none
								 focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-500
								  transition ease-in-out duration-150"
						aria-label="Load"
						onClick={onReload}
					>
						{loading ? (
							<span className="text-lg">Loading</span>
						) : (
							<RefreshIcon className="w-5 h-5" />
						)}
					</button>
				</div>

				<div className=" p-5">
					<TransactionTable
						transactions={transactions}
						isAccounting={isAccounting}
						globalFilter={globalFilter}
						filters={filters}
						dispatch={dispatch}
					/>
				</div>
			</div>
		</>
	);
}
