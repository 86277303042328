import React, { useEffect, useState } from "react";
import { round } from "mathjs";
import { useForm } from "react-form";

import * as editActions from "etegris/transaction/editActions";
import InputField from "../../inputs/InputField";
import TextAreaField from "../../inputs/TextAreaField";
import DateField from "../../inputs/DateField";

const AttachmentExpenseTailWind = ({
	attachment,
	vendorNo,
	validateExistanceOfInvoice,
	dispatch
}) => {
	// const [isExpense, setIsExpense] = useState(attachment.isExpense ? attachment.isExpense : false);
	const [net, setNet] = useState(0);

	const defaultValues = React.useMemo(
		() => ({
			date: attachment.date,
			total: attachment.total,
			gst: attachment.gst ? attachment.gst : "",
			description: attachment.description ? attachment.description : "",
			invoice: attachment.invoice ? attachment.invoice : "",
			remark: attachment.remark ? attachment.remark : "",
			glDate: attachment.glDate
		}),
		[]
	);

	const formValidate = async () => {
		// TODO: need to do trigger validation for Invoice Field
	};

	const {
		Form,
		values,
		runValidation
		// pushFieldValue,
		// removeFieldValue,
		// meta: {
		// 	isSubmitting,
		// 	isSubmitted,
		// 	canSubmit,
		// 	error
		// }
	} = useForm({
		defaultValues,
		onSubmit: async values => {
			await new Promise(resolve => setTimeout(resolve, 1000));
			console.log(values);
		},
		validatePristine: true,
		validate: formValidate,
		debugForm: false
	});

	useEffect(() => {
		setNet(round(values.total - values.gst, 2).toFixed(2));
	}, [values]);

	useEffect(() => {
		runValidation();
	}, []);

	const handleFieldChange = fieldInfo => {
		if (!fieldInfo) return;

		if (!fieldInfo.fieldName) return;

		if (!attachment[fieldInfo.fieldName] && !fieldInfo.value) return;
		if (attachment[fieldInfo.fieldName] === fieldInfo.value) return;

		const action = {
			type: editActions.updateAttachmentField,
			payload: { attachment, fieldInfo }
		};

		dispatch(action);
	};

	if (!attachment.isExpense) {
		return (
			<div className="w-full flex">
				<div>
					<h4>Additional documentation. This is not an expense!</h4>
				</div>
				<label>
					<input
						type="checkbox"
						className="form-checkbox"
						id="attachmentIsExpense"
						checked={attachment.isExpense}
						onChange={event => {
							const checked = event.target.checked;
							handleFieldChange({ fieldName: "isExpense", value: checked });
						}}
						data-testid="attachment-isExpense-not"
					/>
					<label htmlFor="attachmentIsExpense" className="ml-2 text-gray-600">
						Is Expense
					</label>
				</label>
			</div>
		);
	}

	return (
		<Form>
			<div className="mt-1 flex items-center justify-between">
				<div className="w-1/4">
					<DateField
						field="date"
						id="date"
						label="Date *"
						placeholder="Enter Invoice Date"
						onValueChanged={handleFieldChange}
						// disabled={disabled}
					/>
				</div>

				<div className="block w-1/4">
					<InputField
						type="number"
						field="total"
						id="total"
						label="Total *"
						placeholder="Enter Total"
						onBlur={event => {
							let val = event.target.value;
							val = val ? parseFloat(val) : 0;

							handleFieldChange({ fieldName: "total", value: val });
						}}
					/>
				</div>

				<div className="block w-1/4">
					<InputField
						type="number"
						field="gst"
						id="gst"
						label="Gst"
						placeholder="Enter Gst"
						onBlur={event => {
							let val = event.target.value;
							val = val ? parseFloat(val) : 0;

							handleFieldChange({ fieldName: "gst", value: val });
						}}
					/>
				</div>

				<label className="block w-1/4 ml-2">
					<span className="block text-sm font-medium text-gray-500 text-right">
						Net
					</span>
					<span className="h-8 pl-2 block w-full pt-2 border-b-2 text-right text-gray-700">
						{net}
					</span>
				</label>
			</div>

			<div className="mt-3 flex items-center justify-between">
				<label className="block w-9/12">
					<TextAreaField
						name="description"
						field="description"
						label="Description"
						placeholder="Enter Description"
						onBlur={event => {
							const val = event.target.value;
							handleFieldChange({ fieldName: "description", value: val });
						}}
					/>
				</label>

				<div className=" w-3/12 pl-2">
					<label>
						<input
							type="checkbox"
							className="form-checkbox"
							id="attachmentIsExpense"
							checked={attachment.isExpense}
							onChange={event => {
								let checked = event.target.checked;
								handleFieldChange({ fieldName: "isExpense", value: checked });
							}}
							data-testid="attachment-isExpense"
						/>
						<label htmlFor="attachmentIsExpense" className="ml-2 text-gray-600">
							Is Expense
						</label>
					</label>
				</div>
			</div>

			<div className="flex">
				<div
					data-testid="debugging"
					className="mt-2 flex items-center justify-between"
				>
					<div>
						<InputField
							type="text"
							field="invoice"
							id="invoice"
							label="Invoice"
							placeholder="Enter Invoice"
							length={25}
							onBlur={event => {
								const val = event.target.value;
								handleFieldChange({ fieldName: "invoice", value: val });
							}}
							validatePristine={true}
							validate={
								validateExistanceOfInvoice
									? (value, { debounce }) => {
											return debounce(async () => {
												return validateExistanceOfInvoice(
													vendorNo,
													value,
													attachment.id
												);
											}, 500);
									  }
									: null
							}
						/>

						{/*{*/}
						{/*	invoiceValidating && <div className="text-red-500">Validating Invoice</div>*/}
						{/*}*/}
						{/*{*/}
						{/*	invoiceError ? <span className="text-red-600">Invoice already exists!</span> : null*/}
						{/*}*/}
					</div>
				</div>
				<div className="mt-2 ml-2 ">
					<DateField
						field="glDate"
						id="glDate"
						label="G/L Date"
						placeholder="Enter G/L Date"
						onValueChanged={handleFieldChange}
						// disabled={disabled}
					/>
				</div>
			</div>

			<div className="mt-2 flex items-center justify-between">
				<InputField
					type="text"
					field="remark"
					id="remark"
					label="Remark"
					placeholder="Enter Remark"
					onBlur={event => {
						const val = event.target.value;
						handleFieldChange({ fieldName: "remark", value: val });
					}}
					length={30}
				/>
			</div>
		</Form>
	);
};

export default AttachmentExpenseTailWind;
