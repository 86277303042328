import React, { useEffect, useRef, useState } from "react";

import { useService } from "../etegrisService/ServiceProvider";
import { useEditExpenseState } from "./transaction/EditExpenseContext";
import { useUserState } from "./employee/user-context";
import { getJsonFromEditState } from "../etegrisService/serviceEditTransaction";

// const patcher = new DiffPatcher();

function ServiceDiv() {
	const [saving, setSaving] = useState(false);
	const state = useEditExpenseState();
	const service = useService();
	const userState = useUserState();

	let prevState = useRef();
	let prevJson = useRef();

	useEffect(() => {
		// first time loading don't need to save anything
		if (!prevState.current) {
			prevState.current = state;
			prevJson.current = state.json;
			return;
		}

		const { json, fieldInfos, patch } = getJsonFromEditState(
			prevJson.current,
			prevState.current.transaction,
			state
		);

		const run = async () => {
			setSaving(true);

			if (fieldInfos.length) {
				console.log("ServiceDiv.js ---- fieldInfos", fieldInfos);
				await service.saveTransactionFieldById(
					state.transaction.id,
					fieldInfos
				);
			}
			if (patch) {
				service.saveHistory(
					state.transaction.id,
					userState.user.username,
					JSON.stringify(patch)
				);
			}

			setSaving(false);
		};
		run();

		prevState.current = state;
		prevJson.current = json;
	}, [state]);

	return (
		<React.Fragment>
			{saving && (
				<div className="absolute">
					Service Status: <strong>Saving</strong>
				</div>
			)}
		</React.Fragment>
	);
}

export default ServiceDiv;
