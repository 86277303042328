import React, { useEffect, useState } from "react";

import * as status from "a-constants/status";
import useTransactions from "./transactions/useTransactions";

const EtegrisDashBoard = () => {
	const [total, setTotal] = useState(0);
	const [statusCounts, setStatusCounts] = useState([]);

	const { state } = useTransactions();

	useEffect(() => {
		const tot = state.transactions.filter(
			tran => tran.status !== status.deleted
		).length;

		const counts = status.orderedStatus.map(status => {
			const count = state.transactions.filter(tran => tran.status === status)
				.length;
			return {
				status,
				count
			};
		});

		setTotal(tot);
		setStatusCounts(counts);
	}, [state.transactions]);

	// useEffect(() => {
	//
	// 	refreshTransactions();
	//
	// }, []);

	return (
		<div>
			<div className="mt-6 mr-15 bg-white overflow-hidden shadow rounded-lg">
				<div className="border-b border-gray-400 px-4 py-5 sm:px-6 ">
					<div className="text-gray-600 text-2xl">Dashboard</div>
				</div>
				<div className=" w-1/3 px-4 py-5 sm:p-6 text-gray-600 text-lg">
					{statusCounts.map(status => {
						return (
							<div key={status.status} className="flex justify-between">
								<span> {status.status}</span>
								<span> {status.count} </span>
							</div>
						);
					})}
					<div className="flex justify-between mt-2 border-t-2">
						<span>Total transactions</span>
						<span>{total}</span>
					</div>
				</div>
			</div>
		</div>
	);
};

export default EtegrisDashBoard;
