import React from "react";
import moment from "moment";

export const ChatMessage = ({ loginUser, mesg }) => {
	const isLoginUser = messageUserId => {
		if (!loginUser) return false;

		return loginUser.id === messageUserId;
	};

	return (
		<div>
			{!isLoginUser(mesg.userId) && (
				<div className="ml-2 text-gray-400">{mesg.username}</div>
			)}

			{isLoginUser(mesg.userId) ? (
				<div className="flex">
					<div className="w-1/6"></div>
					<div className="w-4/5 mt-2 py-1 px-2 border-2 border-gray-100 rounded-lg text-right">
						<div>{mesg.comment}</div>
						<div className="text-gray-400 text-xs text-right">
							{moment(mesg.createdAt).format("YYYY/MM/DD HH:mm")}
						</div>
					</div>
				</div>
			) : (
				<div className="w-4/5 ml-4 mt-2 px-2 py-1 border-2 border-gray-100 rounded-lg">
					<div>{mesg.comment}</div>
					<div className="text-gray-400 text-xs ">
						{moment(mesg.createdAt).format("YYYY/MM/DD HH:mm")}
					</div>
				</div>
			)}
		</div>
	);
};
