import React, { useState, useEffect } from "react";
import moment from "moment";

import * as status from "a-constants/status";
import * as editActions from "etegris/transaction/editActions";

function getCurrentStatusAllowsGoingOnAPHold(currentStatus) {
	return (
		currentStatus === status.ap ||
		currentStatus === status.apVerified ||
		currentStatus === status.apInitial
	);
}

export const ExpenseStatus = ({ dispatch, editTransaction, user }) => {
	const [canGoOnAPHold, setCanGoOnAPHold] = useState(
		getCurrentStatusAllowsGoingOnAPHold(editTransaction.transaction.status)
	);

	useEffect(() => {
		let currentStatusAllowsGoingOnAPHold = getCurrentStatusAllowsGoingOnAPHold(
			editTransaction.transaction.status
		);

		setCanGoOnAPHold(currentStatusAllowsGoingOnAPHold);
	}, [editTransaction]);

	const canChangeStatus = () => {
		if (!editTransaction.valid) return false;

		if (editTransaction.transaction.status === status.ap && !user.isAccounting)
			return false;

		if (editTransaction.transaction.status == status.apVerified) return false;
		if (editTransaction.transaction.status == status.processed) return false;

		return true;
	};

	function handleUpdateReady() {
		dispatch({
			type: editActions.changeStatus,
			payload: {
				username: user.username,
				date: moment().toISOString()
			}
		});
	}

	function handleUpdateReset() {
		dispatch({ type: editActions.resetStatus });
	}

	function handleUpdateApHold() {
		dispatch({ type: editActions.resetApHold });
	}

	function handleDeleteTransaction() {
		dispatch({ type: editActions.deleteTransaction });
	}

	return (
		<div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
			<div className="-ml-4 -mt-4 flex justify-between items-center flex-wrap sm:flex-nowrap">
				<div className="ml-4 mt-4">
					<div className="flex items-center">
						<div className="">
							<div className="leading-6  text-gray-500">Status</div>
							<div className="text-2xl font-medium text-xl text-gray-700">
								{editTransaction.transaction
									? editTransaction.transaction.status
									: "no Transaction"}
							</div>
						</div>
					</div>
				</div>

				<div className="ml-4 mt-4 flex-shrink-0 flex">
					<button
						data-testid="change-status"
						className={`ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 ${
							editTransaction.valid
								? canChangeStatus()
									? "bg-green-400 hover:bg-green-500"
									: "bg-gray-300"
								: "bg-red-400"
						} focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
						onClick={handleUpdateReady}
						disabled={!canChangeStatus()}
					>
						{editTransaction.transaction
							? status.nextStatus(editTransaction.transaction.status)
							: "No transaction"}
					</button>

					<button
						data-testid="reset-status"
						className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
						onClick={handleUpdateReset}
						disabled={
							editTransaction.transaction &&
							editTransaction.transaction.status === status.uploaded
						}
					>
						Reset
					</button>

					<button
						data-testid="apHold-status"
						className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
						onClick={handleUpdateApHold}
						disabled={!canGoOnAPHold}
					>
						A/P Hold
					</button>
					<button
						data-testid="delete-transaction"
						className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
						onClick={handleDeleteTransaction}
						disabled={editTransaction.transaction.status === status.processed}
					>
						Delete
					</button>
				</div>
			</div>
			{editTransaction.message && (
				<div className="mt-2 border-2 border-red-200 rounded-md p-2 text-lg text-red-700">
					{editTransaction.message}
				</div>
			)}
		</div>
	);
};
