const cleanEditTransaction = async (loaded, saveFieldsForId) => {
	const attachments = loaded.attachments ? loaded.attachments : [];

	const transferExpense =
		attachments.length > 0 && attachments[0].isExpense === undefined
			? true
			: false;

	const cleanedAttachments = attachments.map(origAttach => {
		let attach = { ...origAttach };

		if (!attach.isExpense) attach.isExpense = false;
		if (!attach.date) attach.date = "";
		if (!attach.total) attach.total = 0;
		if (!attach.gst) attach.gst = 0;
		if (!attach.net) attach.net = 0;
		if (!attach.description) attach.description = "";
		if (!attach.invoice) attach.invoice = "";
		if (!attach.remark) attach.remark = "";

		return attach;
	});

	if (transferExpense) {
		const attach = cleanedAttachments[0];

		attach.isExpense = true;
		attach.date = loaded.transaction.invoiceDate
			? loaded.transaction.invoiceDate
			: "";
		attach.total = loaded.transaction.total;
		attach.gst = loaded.transaction.gst;
		attach.invoice = loaded.transaction.invoice
			? loaded.transaction.invoice
			: "";
		attach.remark = loaded.transaction.remark ? loaded.transaction.remark : "";

		const fields = [{ fieldName: "isExpense", value: true }];

		if (attach.date) fields.push({ fieldName: "date", value: attach.date });

		if (attach.total) fields.push({ fieldName: "total", value: attach.total });

		if (attach.gst) fields.push({ fieldName: "gst", value: attach.gst });

		if (attach.invoice)
			fields.push({ fieldName: "invoice", value: attach.invoice });

		if (attach.remark)
			fields.push({ fieldName: "remark", value: attach.remark });

		await saveFieldsForId(attach.id, fields);
	}

	loaded.attachments = cleanedAttachments;

	return loaded;
};

export default cleanEditTransaction;
