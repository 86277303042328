import React from "react";

import { useUserState } from "../employee/user-context";
import useTransactions from "./useTransactions";

import DisplayExpenses from "./DisplayExpenses";

const DisplayExpensesState = () => {
	const { refreshTransactions, state, dispatch } = useTransactions();

	const userState = useUserState();

	return (
		<DisplayExpenses
			transactions={state.transactions}
			loading={state.loadingTransactions}
			onReload={refreshTransactions}
			isAccounting={userState.user.isAccounting}
			globalFilter={state.globalFilter}
			filters={state.filters}
			dispatch={dispatch}
		/>
	);
};

export default DisplayExpensesState;
