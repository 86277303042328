import { original } from "immer";
import { round } from "mathjs";
import { v4 as uuidv4 } from "uuid";

import editValidation from "./editValidation";

import * as actions from "./editActions";
import * as status from "../../a-constants/status";

function sortMessagesAsc(messages) {
	const sorted = messages.sort(function(a, b) {
		var x = a.createdAt ? a.createdAt.toLowerCase() : "";
		var y = b.createdAt ? b.createdAt.toLowerCase() : "";

		if (x > y) {
			return -1;
		}
		if (x < y) {
			return 1;
		}

		return 0;
	});
	return sorted;
}

export function getSumOfAttachments(attachments) {
	let total = attachments
		.filter(attach => attach.isExpense)
		.reduce(
			(sum, attach) => {
				sum.total += attach.total;
				sum.gst += attach.gst;
				sum.net += attach.total - attach.gst;
				return sum;
			},
			{ total: 0, gst: 0, net: 0 }
		);

	total.total = round(total.total, 2);
	total.gst = round(total.gst, 2);
	total.net = round(total.net, 2);

	return total;
}

export function getInvoiceRemarkOfAttachments(attachments) {
	let total = attachments
		.filter(attach => attach.isExpense)
		.reduce(
			(sum, attach) => {
				sum.invoice += attach.invoice ? attach.invoice + ", " : "";
				sum.remark += attach.remark ? attach.remark + ", " : "";
				return sum;
			},
			{ invoice: "", remark: "" }
		);

	return total;
}

function editReducer(draft, action) {
	switch (action.type) {
		case actions.start: {
			draft.valid = false;
			draft.transaction = { ...action.payload.transaction };
			draft.distributions = action.payload.distributions
				? action.payload.distributions.map(dist => {
						return { ...dist };
				  })
				: [];

			draft.attachments = action.payload.attachments
				? action.payload.attachments.map(attach => {
						return { ...attach };
				  })
				: [];

			draft.messages = action.payload.messages
				? sortMessagesAsc(action.payload.messages)
				: [];

			const editResult = editValidation(original(draft));

			draft.valid = editResult.valid;
			draft.message = editResult.message;

			return;
		}
		case actions.closeEdit: {
			draft.valid = false;
			draft.transaction = null;
			draft.attachments = [];
			draft.distributions = [];
			draft.messages = [];
			draft.valid = false;
			draft.message = "";
			return;
		}
		case actions.showAddAttachment: {
			// TODO: should this be in this reducer
			draft.showAddAttachment = action.payload;
			return;
		}

		case actions.replaceTransaction: {
			if (!draft.transaction) return;

			if (draft.transaction.id !== action.payload.id) return;

			const keys = Object.keys(action.payload);
			for (const prop of keys) {
				if (draft.transaction[prop] !== action.payload[prop]) {
					draft.transaction[prop] = action.payload[prop];
				}
			}

			const editResult = editValidation(draft);
			if (draft.valid !== editResult.valid) draft.valid = editResult.valid;

			draft.message = editResult.message;

			return;
		}
		case actions.updateTransactionField: {
			console.log(
				"editReducer.js actions.updateTransactionField action",
				action
			);

			if (!draft.transaction) return;

			const fieldInfo = action.payload;

			if (Array.isArray(fieldInfo)) {
				for (let i = 0; i < fieldInfo.length; i++)
					draft.transaction[fieldInfo[i].fieldName] = fieldInfo[i].value;
			} else {
				draft.transaction[fieldInfo.fieldName] = fieldInfo.value;
			}

			const editResult = editValidation(draft);
			draft.valid = editResult.valid;
			draft.message = editResult.message;

			return;
		}

		case actions.insertAttachment: {
			if (!draft.transaction) return;

			if (draft.transaction.id !== action.payload.transactionId) return;

			draft.attachments.push({ ...action.payload });

			const editResult = editValidation(draft);
			draft.valid = editResult.valid;
			draft.message = editResult.message;

			return;
		}

		case actions.updateAttachmentField: {
			if (!draft.transaction) return;

			if (draft.transaction.id !== action.payload.attachment.transactionId)
				return;

			const index = draft.attachments.findIndex(
				attach => attach.id === action.payload.attachment.id
			);

			if (index >= 0) {
				draft.attachments[index][action.payload.fieldInfo.fieldName] =
					action.payload.fieldInfo.value;

				const sums = getSumOfAttachments(draft.attachments);

				draft.transaction.total = sums.total;
				draft.transaction.gst = sums.gst;
				draft.transaction.net = sums.net;

				const invRemark = getInvoiceRemarkOfAttachments(draft.attachments);
				draft.transaction.invoice = invRemark.invoice;
				draft.transaction.remark = invRemark.remark;

				const editResult = editValidation(draft);
				draft.valid = editResult.valid;
				draft.message = editResult.message;
			}

			return;
		}

		case actions.deleteAttachment: {
			if (!draft.transaction) return;

			const attachment = draft.attachments.find(
				attach => attach.id === action.payload.id
			);
			if (attachment) {
				const index = draft.attachments.findIndex(
					attach => attach.id === attachment.id
				);
				draft.attachments.splice(index, 1);

				const sums = getSumOfAttachments(draft.attachments);
				draft.transaction.total = sums.total;
				draft.transaction.gst = sums.gst;
				draft.transaction.net = sums.net;

				const editResult = editValidation(draft);
				draft.valid = editResult.valid;
				draft.message = editResult.message;
			}
			break;
		}

		case actions.AddAttachmentDistribution: {
			if (!draft.transaction) return;

			const attachment = action.payload;
			const attach = draft.attachments.find(att => att.id === attachment.id);

			const newDistribution = {
				id: uuidv4(),
				transactionId: draft.transaction.id,
				account: "",
				amount: 0,
				subledgerType: "",
				subledgerId: "",
				subledgerName: "",
				groups: ["CfsAdmin", "CfsApprover"]
			};

			attach.distributions.push(newDistribution);

			return;
		}

		case actions.DeleteAttachmentDistribution: {
			if (!draft.transaction) return;

			const distribution = action.payload.distribution;
			const attachment = action.payload.attachment;
			const attach = draft.attachments.find(att => att.id === attachment.id);

			attach.distributions.splice(
				attach.distributions.findIndex(dist => dist.id === distribution.id),
				1
			);

			return;
		}

		case actions.UpdateAttachmentDistribution: {
			if (!draft.transaction) return;

			const distribution = action.payload.distribution;
			const attachment = action.payload.attachment;
			const fieldInfo = action.payload.fieldInfo;

			const attach = draft.attachments.find(att => att.id === attachment.id);
			const dist = attach.distributions.find(
				dist => dist.id === distribution.id
			);

			if (Array.isArray(fieldInfo)) {
				for (let field of fieldInfo) {
					dist[field.fieldName] = field.value;
				}
			} else {
				dist[fieldInfo.fieldName] = fieldInfo.value;
			}

			return;
		}

		case actions.insertDistribution: {
			if (!draft.transaction) return;

			if (draft.transaction.id !== action.payload.transactionId) return;

			draft.distributions.push(action.payload);

			const editResult = editValidation(draft);
			draft.valid = editResult.valid;
			draft.message = editResult.message;

			return;
		}
		case actions.updateDistributionField: {
			if (!draft.transaction) return;

			const index = draft.distributions.findIndex(
				dist => dist.id === action.payload.id
			);

			if (index >= 0) {
				const fieldInfo = action.payload.fieldInfo;

				if (Array.isArray(fieldInfo)) {
					for (let i = 0; i < fieldInfo.length; i++)
						draft.distributions[index][fieldInfo[i].fieldName] =
							fieldInfo[i].value;
				} else {
					draft.distributions[index][fieldInfo.fieldName] = fieldInfo.value;
				}

				const editResult = editValidation(draft);
				draft.valid = editResult.valid;
				draft.message = editResult.message;
			}

			return;
		}

		case actions.updateDistribution: {
			if (!draft.transaction) return;

			const distribution = action.payload;

			if (draft.transaction.id !== distribution.transactionId) return;

			const index = draft.distributions.findIndex(
				dist => dist.id === distribution.id
			);
			draft.distributions[index] = distribution;

			const editResult = editValidation(draft);
			draft.valid = editResult.valid;
			draft.message = editResult.message;

			return;
		}

		case actions.deleteDistribution: {
			if (!draft.transaction) return;

			const distribution = draft.distributions.find(
				dist => dist.id === action.payload.id
			);
			if (!distribution) return;

			const index = draft.distributions.findIndex(
				dist => dist.id === distribution.id
			);
			draft.distributions.splice(index, 1);

			const editResult = editValidation(draft);
			draft.valid = editResult.valid;
			draft.message = editResult.message;

			return;
		}

		case actions.insertMessage: {
			if (!draft.transaction) return;

			if (draft.transaction.id !== action.payload.transactionId) return;

			draft.messages.unshift(action.payload);
			return;
		}

		case actions.resetStatus: {
			draft.transaction.status = status.uploaded;
			draft.transaction.ready = "";
			draft.transaction.readyDate = "";
			draft.transaction.approval1 = "";
			draft.transaction.approval1Date = "";
			draft.transaction.approval2 = "";
			draft.transaction.approval2Date = "";
			return;
		}
		case actions.resetApHold: {
			draft.transaction.status = status.apHold;
			return;
		}

		case actions.changeStatus: {
			const nextStatus = status.nextStatus(draft.transaction.status);

			switch (nextStatus) {
				case status.ready:
					draft.transaction.status = nextStatus;
					draft.transaction.ready = action.payload.username;
					draft.transaction.readyDate = action.payload.date;

					break;
				case status.approved1:
					draft.transaction.status = nextStatus;
					draft.transaction.approval1 = action.payload.username;
					draft.transaction.approval1Date = action.payload.date;

					break;
				case status.approved2:
					draft.transaction.status = nextStatus;
					draft.transaction.approval2 = action.payload.username;
					draft.transaction.approval2Date = action.payload.date;
					break;
				default:
					draft.transaction.status = nextStatus;
					break;
			}

			return;
		}

		case actions.deleteTransaction: {
			draft.transaction.status = status.deleted;
			return;
		}

		default: {
			break;
		}
	}
}

export const replaceItemAtIndex = (arr, index, newValue) => {
	return [...arr.slice(0, index), newValue, ...arr.slice(index + 1)];
};
export const removeItemAtIndex = (arr, index) => {
	return [...arr.slice(0, index), ...arr.slice(index + 1)];
};

export default editReducer;
