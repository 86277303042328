import React, { useEffect, useState } from "react";
import moment from "moment";

function ExpenseTable({ expences }) {
	const [sumTotal, setSumTotal] = useState(0);
	const [sumGst, setSumGst] = useState(0);
	const [sumNet, setSumNet] = useState(0);

	useEffect(() => {
		const total = expences.reduce((total, row) => {
			return (total += row.total);
		}, 0);
		const gst = expences.reduce((gst, row) => {
			return (gst += row.gst);
		}, 0);
		const net = expences.reduce((net, row) => {
			return (net += row.total - row.gst);
		}, 0);

		setSumTotal(total);
		setSumGst(gst);
		setSumNet(net);

		// setData(expences);
	}, [expences]);

	return (
		<div>
			<div className="mt-3 text-sm text-gray-500 font-medium">
				Summary of Expenses
			</div>
			<div className="flex flex-col w-96 mt-1">
				<div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
					<div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
						<div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
							<table className="min-w-full divide-y divide-gray-200">
								<thead className="bg-gray-50">
									<tr>
										<th
											scope="col"
											className="px-6 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
										>
											Date
										</th>
										<th
											scope="col"
											className="px-6 py-2 text-left text-xs font-medium text-gray-500 text-right uppercase tracking-wider"
										>
											Total
										</th>
										<th
											scope="col"
											className="px-6 py-2 text-left text-xs font-medium text-gray-500 text-right uppercase tracking-wider"
										>
											Gst
										</th>
										<th
											scope="col"
											className="px-6 py-2 text-left text-xs font-medium text-gray-500 text-right uppercase tracking-wider"
										>
											Net
										</th>
									</tr>
								</thead>
								<tbody className="bg-white divide-y divide-gray-200">
									{expences.map(expense => {
										return (
											<tr key={expense.id}>
												<td className="px-6 py-1 whitespace-nowrap text-sm font-medium text-gray-500">
													{moment(expense.date).format("MM/DD/YYYY")}
												</td>
												<td className="px-6 py-1 whitespace-nowrap text-sm text-gray-500 text-right">
													{expense.total ? expense.total.toFixed(2) : ""}
												</td>
												<td className="px-6 py-1 whitespace-nowrap text-sm text-gray-500 text-right">
													{expense.gst ? expense.gst.toFixed(2) : ""}
												</td>
												<td className="px-6 py-1 whitespace-nowrap text-sm text-gray-500 text-right">
													{(expense.total - expense.gst).toFixed(2)}
												</td>
											</tr>
										);
									})}

									{expences.length > 1 && (
										<tr>
											<td className="px-6 py-1 whitespace-nowrap text-sm font-medium text-gray-500"></td>
											<td className="px-6 py-1 whitespace-nowrap text-sm font-medium text-gray-700 text-right">
												{sumTotal ? sumTotal.toFixed(2) : ""}
											</td>
											<td className="px-6 py-1 whitespace-nowrap text-sm font-medium text-gray-700 text-right">
												{sumGst ? sumGst.toFixed(2) : ""}
											</td>
											<td className="px-6 py-1 whitespace-nowrap text-sm font-medium text-gray-700 text-right">
												{sumNet.toFixed(2)}
											</td>
										</tr>
									)}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ExpenseTable;
