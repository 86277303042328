import React from "react";

const CardWithHeader = ({ children, title }) => {
	return (
		<>
			<div className="mt-2 pb-5 border-b border-gray-200 bg-white rounded-lg shadow-lg">
				<div className="border-b border-gray-400 px-4 py-2 sm:px-6 text-gray-600 text-lg">
					{title}
				</div>
				<div className="px-4 py-5 p-1">{children}</div>
			</div>
		</>
	);
};

export default CardWithHeader;
