import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import EnterMessage from "./EnterMessage";
import { ChatMessage } from "./ChatMessage";

function compareMessage(a, b) {
	var x = a.createdAt ? a.createdAt.toLowerCase() : "zzzzzzzzzzzzzzz";
	var y = b.createdAt ? b.createdAt.toLowerCase() : "zzzzzzzzzzzzzzz";

	return -1 * x.localeCompare(y);
}

export default function ChatDiscussion({
	loginUser,
	messages,
	transaction,
	send
}) {
	// const classes = useStyles();

	const [sortedMessages, setSortedMessages] = useState([]);

	useEffect(() => {
		const sorted = messages.slice().sort(compareMessage);
		setSortedMessages(sorted);
	}, [messages]);

	return (
		<div className="margin-2 p-2 border-2 rounded-lg border-gray-300">
			<div>
				<div className="mr-3 mb-2">
					<EnterMessage
						transactionId={transaction.id}
						loginUser={loginUser}
						send={send}
					/>
				</div>

				<div>
					<ul className="mt-4">
						{sortedMessages.map(mesg => {
							return (
								<div className="relative" key={mesg.id}>
									<ChatMessage loginUser={loginUser} mesg={mesg} />
								</div>
							);
						})}
					</ul>
				</div>
			</div>
		</div>
	);
}

ChatDiscussion.propTypes = {
	loginUser: PropTypes.object.isRequired,
	messages: PropTypes.array.isRequired
};
