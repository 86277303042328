import React, { useEffect, useState } from "react";
import { round } from "mathjs";

import Calculator from "svg/Calculator";
import CardWithFancyHeader from "1-atoms/CardWithFancyHeader";
import Distribution from "./Distribution";

import AddButton from "1-atoms/AddButton";

const Distributions = ({
	net,
	accounts,
	addressBooks,
	distributions,
	transactionId,
	attachment,
	fieldUpdated,
	onAdd,
	onDelete
}) => {
	const [sumDistribution, setSumDistribution] = useState(0);

	const [totalValid, setTotalValid] = useState(false);

	function updateSumDistribution() {
		const sum = distributions.reduce((acc, dist) => dist.amount + acc, 0);
		setSumDistribution(round(sum, 2));
	}

	const handleAddClick = async () => {
		if (onAdd) {
			onAdd(transactionId, attachment);
		}
	};

	function handleDistributionDeleted(dist) {
		updateSumDistribution();

		if (onDelete) onDelete(dist, attachment);
	}

	function handleDistributionFieldUpdated(distribution, fieldInfo) {
		updateSumDistribution();
		if (fieldUpdated) fieldUpdated(distribution, fieldInfo, attachment);
	}

	useEffect(() => {
		updateSumDistribution();
	}, [distributions]);

	useEffect(() => {
		if (net === sumDistribution) setTotalValid(true);
		else setTotalValid(false);
	}, [net, sumDistribution]);

	const handleCalculateClick = () => {
		if (!distributions || distributions.length === 0) return;

		if (net === 0) return;

		const amount = round(net / distributions.length, 2);

		distributions.forEach(dist => {
			const field = {
				id: dist.id,
				fieldName: "amount",
				value: amount
			};
			handleDistributionFieldUpdated(dist, field);
		});
	};

	return (
		<div className="mt-8">
			<CardWithFancyHeader
				title={
					<div className="flex justify-between content-center">
						<div className="ml-4 mt-2 mb-2 text-gray-800 text-xl">
							Charge To
						</div>

						{/*<Tooltip title="Evenly spread 'Net' amount of expense across all 'Charge To' accounts" classes={{tooltip: classes2.tooltip}}>*/}
						<button
							className="w-8 h-8 mt-1 bg-gray-200 round-full"
							onClick={handleCalculateClick}
						>
							<Calculator />
						</button>
						{/*</Tooltip>*/}

						<AddButton
							color="primary"
							title="Add a new 'Charge to' account"
							floatingAction={true}
							onClick={handleAddClick}
							testid="add-distribution"
						/>
					</div>
				}
			>
				<div>
					{distributions.map(dist => {
						return (
							<div key={dist.id}>
								<Distribution
									distribution={dist}
									accounts={accounts}
									addressBooks={addressBooks}
									fieldUpdated={handleDistributionFieldUpdated}
									deleteDistribution={handleDistributionDeleted}
								/>
							</div>
						);
					})}
					<div>
						<div className="flex">
							<div className="w-3/4">
								{!totalValid && (
									<div>
										Net: {net} - Sum Distribution: {sumDistribution} difference:
										({round(net - sumDistribution, 2)})
									</div>
								)}
							</div>
							<div className="w-1/4">
								<div>
									Total: <strong>{sumDistribution}</strong>
								</div>
							</div>
						</div>
					</div>
				</div>
			</CardWithFancyHeader>
		</div>
	);
};

export default Distributions;
