import React from "react";
import PropTypes from "prop-types";
import { useImmerReducer } from "use-immer";

import transactionsReducer from "./transactionsReducer";

const TransactionsStateContext = React.createContext();
const TransactionsReducerContext = React.createContext();

function TransactionsProvider(props) {
	const initialState = {
		transactions: [],
		uploadedTransactions: [],
		readyTransactions: [],
		approved1Transactions: [],
		approved2Transactions: [],
		apTransactions: [],
		processedTransactions: [],

		transaction: {},
		distributions: [],
		attachments: [],
		messages: [],

		statusFilter: "Incomplete",
		filters: [{ id: "status", value: "Incomplete" }]
		// filters: [ ]
	};
	const [state, dispatch] = useImmerReducer(transactionsReducer, initialState);

	return (
		<TransactionsStateContext.Provider value={state}>
			<TransactionsReducerContext.Provider value={dispatch}>
				{props.children}
			</TransactionsReducerContext.Provider>
		</TransactionsStateContext.Provider>
	);
}

TransactionsProvider.propTypes = {
	children: PropTypes.any.isRequired
};

function useTransactionsState() {
	const context = React.useContext(TransactionsStateContext);
	if (context === undefined) {
		throw new Error(
			"useTransactionsState must be used within a TransactionsProvider"
		);
	}
	return context;
}

function useTransactionsReducer() {
	const context = React.useContext(TransactionsReducerContext);
	if (context === undefined) {
		throw new Error(
			"useTransactionsReducer must be used within a TransactionsProvider"
		);
	}
	return context;
}

export { TransactionsProvider, useTransactionsState, useTransactionsReducer };
