import React, { useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import {
	useAsyncDebounce,
	useBlockLayout,
	useFilters,
	useGlobalFilter,
	useResizeColumns,
	useSortBy,
	useTable
} from "react-table";
import { matchSorter } from "match-sorter";
import equal from "deep-equal";
import { FixedSizeList } from "react-window";

export const NumberCell = ({ value }) => {
	return (
		<div className="w-full align-middle text-right pr-2">
			{Number.isFinite(value) ? value.toFixed(2) : value}
		</div>
	);
};

export const DefaultCell = ({ value }) => {
	return (
		<div className="h-full flex items-center pr-2 break-words overflow-y-auto">
			<span>{value}</span>
		</div>
	);
};

DefaultCell.propTypes = {
	value: PropTypes.any
};

export const DateTimeCell = ({ value }) => {
	const date = moment.utc(value).format("YYYY/MM/DD");
	const time = moment.utc(value).format("HH:mm");

	return (
		<div className="h-full flex flex-col items-center justify-items-center pr-2 break-words">
			<span>{date}</span>
			<span className="text-xs">{time}</span>
		</div>
	);
};

export const DefaultColumnFilter = ({ column: { filterValue, setFilter } }) => {
	return (
		<input
			className="w-full"
			value={filterValue || ""}
			onChange={e => {
				setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
			}}
			placeholder={`Search`}
		/>
	);
};

DefaultColumnFilter.propTypes = {
	column: PropTypes.object.isRequired
};

export const SelectColumnFilter = ({
	column: { filterValue, setFilter, preFilteredRows, id }
}) => {
	// Calculate the options for filtering
	// using the preFilteredRows
	const options = React.useMemo(() => {
		const options = new Set();
		preFilteredRows.forEach(row => {
			options.add(row.values[id]);
		});
		return [...options.values()];
	}, [id, preFilteredRows]);

	// Render a multi-select box
	return (
		<select
			value={filterValue}
			onChange={e => {
				setFilter(e.target.value || undefined);
			}}
		>
			<option value="">All</option>
			{options.map((option, i) => (
				<option key={i} value={option}>
					{option}
				</option>
			))}
		</select>
	);
};

SelectColumnFilter.propTypes = {
	column: PropTypes.object.isRequired
};

export const GlobalFilter = ({
	preGlobalFilteredRows,
	globalFilter,
	setGlobalFilter
}) => {
	const count = preGlobalFilteredRows.length;
	const [value, setValue] = React.useState(globalFilter);
	const onChange = useAsyncDebounce(value => {
		setGlobalFilter(value || undefined);
	}, 200);

	return (
		<div className="mt-5 mb-1 pb-5 w-full border-b-2">
			<span className="pl-5">
				All Fields Search:{" "}
				<input
					className="w-2/3"
					value={value || ""}
					onChange={e => {
						setValue(e.target.value);
						onChange(e.target.value);
					}}
					placeholder={`${count} records...`}
					style={{
						fontSize: "1.1rem",
						border: "0"
					}}
				/>
			</span>
		</div>
	);
};

GlobalFilter.propTypes = {
	preGlobalFilteredRows: PropTypes.any,
	globalFilter: PropTypes.any,
	setGlobalFilter: PropTypes.any
};

export const Table = ({
	columns,
	data,
	hiddenColumns,
	showHiddenColumns,
	globalFilter,
	filters,
	dispatchFiltersChanged,
	dispatchGlobalFilterChanged
}) => {
	function fuzzyTextFilterFn(rows, id, filterValue) {
		return matchSorter(rows, filterValue, { keys: [row => row.values[id]] });
	}

	// Let the table remove the filter if the string is empty
	fuzzyTextFilterFn.autoRemove = val => !val;

	const filterTypes = React.useMemo(
		() => ({
			// Add a new fuzzyTextFilterFn filter type.
			fuzzyText: fuzzyTextFilterFn,
			// Or, override the default text filter to use
			// "startWith"
			text: (rows, id, filterValue) => {
				return rows.filter(row => {
					const rowValue = row.values[id];
					return rowValue !== undefined
						? String(rowValue)
								.toLowerCase()
								.startsWith(String(filterValue).toLowerCase())
						: true;
				});
			}
		}),
		[]
	);

	const defaultColumn = React.useMemo(
		() => ({
			// Let's set up our default Filter UI
			Filter: DefaultColumnFilter
		}),
		[]
	);

	// Use the state and functions returned from useTable to build your UI
	const {
		getTableProps,
		headerGroups,
		rows,
		prepareRow,
		state,
		setHiddenColumns,
		totalColumnsWidth,
		preGlobalFilteredRows,
		setGlobalFilter,
		setAllFilters
	} = useTable(
		{
			columns,
			data,
			initialState: {
				filters: filters,
				globalFilter: globalFilter
			},
			defaultColumn,
			filterTypes
		},
		useBlockLayout,
		useResizeColumns,
		useFilters,
		useGlobalFilter,
		useSortBy
	);

	useEffect(() => {
		if (!showHiddenColumns) setHiddenColumns(hiddenColumns);
		else setHiddenColumns([]);
	}, [showHiddenColumns]);

	useEffect(() => {
		setAllFilters(filters);
	}, [filters]);

	useEffect(() => {
		setGlobalFilter(globalFilter);
	}, [globalFilter]);

	useEffect(() => {
		const filtersEqual = equal(filters, state.filters);

		if (!filtersEqual) {
			dispatchFiltersChanged(state.filters);
		}
	}, [state.filters]);

	useEffect(() => {
		const filtersEqual = equal(globalFilter, state.globalFilter);

		if (!filtersEqual) {
			dispatchGlobalFilterChanged(state.globalFilter);
		}
	}, [state.globalFilter]);

	const Row = React.useCallback(
		({ index, style }) => {
			const row = rows[index];
			prepareRow(row);

			return (
				<div {...row.getRowProps({ style })} className="flex">
					{row.cells.map(cell => {
						return (
							<div
								key={cell.name}
								{...cell.getCellProps()}
								className="margin-0 align-middle p-1 border-b-2 break-words overflow-y-auto"
							>
								{cell.render("Cell")}
							</div>
						);
					})}
				</div>
			);
		},
		[prepareRow, rows]
	);

	// Render the UI for your table
	return (
		<div {...getTableProps()} className="inline-block border-2 rounded-lg">
			<div className="w-full ">
				{headerGroups.map(headerGroup => (
					<div
						key={headerGroup.name}
						{...headerGroup.getHeaderGroupProps()}
						className="flex flex-wrap w-full "
					>
						{headerGroup.headers.map(column => (
							<div
								key={column.id}
								{...column.getHeaderProps(column.getSortByToggleProps())}
								className="m-0 p-0.5 border-b-2 border-r-2 "
							>
								{column.render("Header")}
								<div>{column.canFilter ? column.render("Filter") : null}</div>
								<span>
									{column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""}
								</span>
							</div>
						))}
					</div>
				))}

				<div>
					<div
						className="text-justify"
						style={{
							textAlign: "left"
						}}
					>
						<GlobalFilter
							preGlobalFilteredRows={preGlobalFilteredRows}
							globalFilter={state.globalFilter}
							setGlobalFilter={setGlobalFilter}
						/>
					</div>
				</div>
			</div>
			<div>
				<FixedSizeList
					height={500}
					itemCount={rows.length}
					itemSize={60}
					style
					width={totalColumnsWidth /* + scrollBarSize */}
				>
					{Row}
				</FixedSizeList>
			</div>
		</div>
	);
};

Table.propTypes = {
	columns: PropTypes.array.isRequired,
	data: PropTypes.array.isRequired,
	isAccounting: PropTypes.bool,
	globalFilter: PropTypes.any,
	filters: PropTypes.any
};
