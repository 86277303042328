import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";

import defaultImage from "assets/img/image_placeholder.jpg";
import defaultAvatar from "assets/img/placeholder.jpg";

export default function ImageUploadEcd(props) {
	const [file, setFile] = React.useState(null);
	const [imagePreviewUrl, setImagePreviewUrl] = React.useState(
		props.avatar ? defaultAvatar : defaultImage
	);
	let fileInput = React.createRef();

	const handleImageChange = e => {
		e.preventDefault();
		let reader = new FileReader();
		let file = e.target.files[0];
		reader.onloadend = () => {
			setFile(file);
			setImagePreviewUrl(reader.result);
		};

		if (props.onChange) {
			props.onChange(file);
		}
		reader.readAsDataURL(file);
	};

	const handleClick = () => {
		fileInput.current.click();
	};

	const handleRemove = () => {
		setFile(null);
		setImagePreviewUrl(props.avatar ? defaultAvatar : defaultImage);
		fileInput.current.value = null;
		if (props.onChange) props.onChange(null);
	};

	return (
		<div className="text-center w-full h-full ">
			<input type="file" onChange={handleImageChange} ref={fileInput} />
			<iframe className="w-full h-96" src={imagePreviewUrl} alt="..." />
			<div className="mt-1">
				{file === null ? (
					<button
						className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
						onClick={() => handleClick()}
					>
						Select image
					</button>
				) : (
					<span>
						<button
							className="mx-2 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
							onClick={() => handleClick()}
						>
							Change
						</button>

						<button
							className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
							onClick={() => handleRemove()}
						>
							Remove
						</button>
					</span>
				)}
			</div>
		</div>
	);
}

ImageUploadEcd.propTypes = {
	avatar: PropTypes.bool,
	addButtonProps: PropTypes.object,
	changeButtonProps: PropTypes.object,
	removeButtonProps: PropTypes.object
};
