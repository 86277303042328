import { Predictions } from "aws-amplify";

export const processFile = async file => {
	console.log("predictionService.js processFile file", file);

	const input = {
		text: {
			source: {
				file
			},
			format: "PLAIN"
		}
	};

	try {
		const result = await Predictions.identify(input);

		console.log("predictionService.js --- result", result);
	} catch (err) {
		console.log("predictionService.js --- err", err);
	}
};
