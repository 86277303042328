import React from "react";
import { Transition } from "@headlessui/react";

const EtegrisModal = ({ children, open, onClose }) => {
	if (!open) return null;

	return (
		<>
			<div className="fixed z-10 inset-0 overflow-y-auto">
				<div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
					<Transition
						show={open}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div
							className="fixed inset-0 transition-opacity"
							aria-hidden="true"
						>
							<div className="absolute inset-0 bg-gray-500 opacity-75" />
						</div>
					</Transition>

					<span
						className="hidden sm:inline-block sm:align-middle sm:h-screen"
						aria-hidden="true"
					>
						&#8203;
					</span>

					{/*<Transition show={open}*/}
					{/*			enter="ease-out duration-300"*/}
					{/*			enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"*/}
					{/*			enterTo="opacity-100 tranlate-y-0 sm:scale-100"*/}
					{/*			leave="ease-in duration-200"*/}
					{/*			leaveFrom="opacity-100 translate-y-0 sm:scale-100"*/}
					{/*			leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"*/}
					{/*>*/}

					<div
						className="w-80 inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-3/4 sm:p-6"
						role="dialog"
						aria-modal="true"
						aria-labelledby="modal-headline"
					>
						<div className="hidden sm:block absolute top-0 right-0 pt-2 pr-2">
							<button
								type="button"
								className="bg-white rounded-full text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
								onClick={() => {
									onClose();
								}}
							>
								<span className="sr-only">Close</span>
								<svg
									heroiconname="x"
									className="h-6 w-6"
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
									aria-hidden="true"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth="2"
										d="M6 18L18 6M6 6l12 12"
									/>
								</svg>
							</button>
						</div>

						{children}
					</div>
					{/*</Transition>*/}
				</div>
			</div>
		</>
	);
};

export default EtegrisModal;
