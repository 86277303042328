import {
	useEditExpenseState,
	useEditExpenseReducer
} from "./EditExpenseContext";
import { useService } from "etegrisService/ServiceProvider";
import { useMemo } from "react";
import * as editActions from "./editActions";

function withService(dispatch, state, service) {
	return async function(action) {
		console.log("useEditExpense.js withService state", state);
		switch (action.type) {
			case editActions.createApproval: {
				const attachment = await service.createApprovalAttachment(state);
				const approvalAction = {
					type: editActions.insertAttachment,
					payload: attachment
				};
				dispatch(approvalAction);
				break;
			}

			case editActions.stampApproval: {
				const bpAttachment = await service.stampApprovalAttachment(
					action.attachment,
					action.options
				);
				const bpAction = {
					type: editActions.insertAttachment,
					payload: bpAttachment
				};
				dispatch(bpAction);
				break;
			}

			default:
				dispatch(action);
				break;
		}
	};
}

const useEditExpense = () => {
	const state = useEditExpenseState();
	const dispatch = useEditExpenseReducer();
	const service = useService();

	const dispatchWithService = useMemo(() => {
		return withService(dispatch, state, service);
	}, [dispatch, state, service]);

	return {
		state,
		dispatch: dispatchWithService,
		service
	};
};

export default useEditExpense;
