import { DiffPatcher } from "jsondiffpatch";
import { getFieldChangesForTransaction } from "./getFieldChangesForTransaction";

const patcher = new DiffPatcher();

const compareCreatedAt = function(a, b) {
	var x = a.createdAt ? a.createdAt.toLowerCase() : "";
	var y = b.createdAt ? b.createdAt.toLowerCase() : "";

	if (x > y) {
		return -1;
	}
	if (x < y) {
		return 1;
	}

	return 0;
};

export function filterSortExpensesDesc(expenses) {
	// TODO: fudge to get rid of automated testing data.  Need to clean up in tests or use different datastore
	const filtered = expenses.filter(
		expense => !expense.meta.startsWith("testing")
	);

	const sorted = filtered.sort(compareCreatedAt);
	return sorted;
}

export const getEditTransactionFromQuery = jqueryTran => {
	let json = null;
	let attachments = null;
	let distributions = null;
	let payment = null;
	let messages = null;

	if (jqueryTran.schema === "0.1") {
		const parsedTran = JSON.parse(jqueryTran.json);
		attachments = parsedTran.attachments;
		distributions = parsedTran.distributions;
		payment = parsedTran.payment;
		json = jqueryTran.json;
		messages = jqueryTran.messages.items;
	} else {
		attachments = jqueryTran.attachments.items.sort(compareCreatedAt);
		distributions = jqueryTran.distributions.items;
		payment = {};
		json = null;
		messages = jqueryTran.messages.items;
	}

	delete jqueryTran.actions;
	delete jqueryTran.attachments;
	delete jqueryTran.distributions;
	delete jqueryTran.messages;
	delete jqueryTran.json;

	const editTran = {
		transaction: jqueryTran,
		attachments,
		distributions,
		messages,
		json: json
	};

	editTran.transaction.payment = payment || {};

	for (var attach of editTran.attachments) {
		if (!attach.distributions) {
			attach.distributions = [];
		}
	}
	return editTran;
};

export function getJsonFromEditState(prevJson, origTransaction, state) {
	let newTranRecord = {
		...state.transaction,
		schema: "0.1"
	};

	const newJsonTran = {
		...newTranRecord,
		attachments: [...state.attachments],
		distributions: [...state.distributions]
	};

	const json = JSON.stringify(newJsonTran);
	const jsonChanges = getJsonChangesFromTwoJsonStrings(prevJson, json);

	let fieldInfos = getFieldInfosFromTwoTransactions(
		origTransaction,
		newTranRecord
	);

	// should json be added to newTranRecord before comparison
	if (jsonChanges) {
		fieldInfos.push({ fieldName: "json", id: newJsonTran.id, value: json });
	}

	return { json, fieldInfos, patch: jsonChanges };
}

///
/// Doesn't care about related records in data store only the transaction record
///
function getFieldInfosFromTwoTransactions(origTransaction, holdTran) {
	let fieldInfos = [];
	let transactionChanges = getFieldChangesForTransaction(
		origTransaction,
		holdTran
	);
	return fieldInfos.concat(transactionChanges);
}

function getJsonChangesFromTwoJsonStrings(prevJson, json) {
	const j1 = prevJson ? JSON.parse(prevJson) : {}; // if first time prev is empty
	const j2 = JSON.parse(json);
	delete j1.updatedAt;
	delete j2.updatedAt;
	const jsonChanges = patcher.diff(j1, j2); // APIs match docs
	return jsonChanges;
}
