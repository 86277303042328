import React, { useEffect } from "react";
import { useMachine } from "@xstate/react";
import * as service from "etegrisService";
import useUser from "etegris/employee/useUser";
import { useUserState } from "etegris/employee/user-context";
import { ServiceProvider } from "../etegrisService/ServiceProvider";
import useBestPractice from "./useBestPractice";

import { BestPracticeTransactionTable } from "./BestPracticeTransactionTable";
// import {callCreateBpPdf} from "etegrisService";

import { bestPractiseMachine } from "./bestPracticeMachine";

export default function BestPracticeView() {
	const {
		loadingTransactions,
		load2,
		error,
		allTransactions
	} = useBestPractice();

	const userState = useUserState();

	const { loadUser } = useUser();

	const [state] = useMachine(bestPractiseMachine);

	useEffect(() => {
		loadUser();
	}, []);

	if (!userState.user) {
		return <div>Not logged in!</div>;
	}

	if (!userState.user.isCfsAdmin) {
		return (
			<div className="w-80 h-80 ml-40 mt-40 bg-blue-400 text-gray-100 text-2xl">
				Not Authorized to use this module!
			</div>
		);
	}

	console.log("state", state);

	return (
		<ServiceProvider service={service}>
			<div className="m-2 p-2 border-2 border-gray-200 rounded-md">
				{/*{error && (*/}
				{/*	<div className="text-red-600">*/}
				{/*		<div>Error loading Best Practice</div>*/}
				{/*		<div>{JSON.stringify(error, null, 2)}</div>*/}
				{/*	</div>*/}
				{/*)}*/}
				<button
					className="jde-button"
					onClick={() => {
						load2();
					}}
				>
					Load Best Practice
				</button>
				{/*<button*/}
				{/*	className="jde-button"*/}
				{/*	onClick={() => {*/}
				{/*		send({ type: "CLEAR_DATA" });*/}
				{/*		send({ type: "SEND", message: "JustHeaders" });*/}
				{/*	}}*/}
				{/*>*/}
				{/*	Load Best Practice*/}
				{/*</button>*/}
			</div>
			{state.matches("Loading") && <div>Loading from state machine</div>}
			{state.matches("Waiting") && <div>Waiting from state machine</div>}
			{state.matches("Loading") && <div>Loading from state machine</div>}
			{/*{state.context.errorMessage && (*/}
			{/*	<div>Error {state.context.errorMessage}</div>*/}
			{/*)}*/}
			{error && <div>Error {error}</div>}

			<div>
				{loadingTransactions && <div>Loading Best Practice Transactions</div>}
				{/*{error && <div>{error}</div>}*/}
				{
					<BestPracticeTransactionTable
						transactions={allTransactions}
						// transactions={state.context.transactions}
					/>
					// allTransactions.map((tran)=> {
					// 	return <div key={tran.tranh_id}>
					// 		{tran.tranh_id}
					// 	</div>
					// })
				}
			</div>
		</ServiceProvider>
	);
}
