import React, { Fragment, useEffect, useState } from "react";
import { useForm } from "react-form";
import { Menu, Transition } from "@headlessui/react";
import { DotsVerticalIcon } from "@heroicons/react/solid";

import * as status from "a-constants/status";

import ExpenseTable from "./ExpenseTable";

import * as editActions from "etegris/transaction/editActions";

import SelectVendor2 from "etegris/inputs/SelectVendor2";
import CardWithHeader from "1-atoms/CardWithHeader";
import CardWithFancyHeader from "1-atoms/CardWithFancyHeader";
import InputField from "etegris/inputs/InputField";
import TextAreaField from "etegris/inputs/TextAreaField";
import DateField from "../../inputs/DateField";

function getVendorFromAn8(vendors, an8) {
	const ven = vendors.find(v => v.an8 === an8);
	if (ven) return ven;
	else return vendors[vendors.length - 1];
}

function getExpensesFromAttachments(attachments) {
	if (!attachments) return [];

	return attachments.filter(att => att.isExpense);
}

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

export const ExpenseInformation = ({
	transaction,
	vendors,
	attachments,
	dispatch
}) => {
	const [showOwner2, setShowOwner2] = useState(
		transaction.owner2 ? true : false
	);

	const [vendor, setVendor] = useState(
		getVendorFromAn8(vendors, transaction.vendorNo)
	);

	const [expenseAttachments, setExpenseAttachments] = useState(
		getExpensesFromAttachments(attachments)
	);

	const [disabled, setDisabled] = useState(false);

	const defaultValues = React.useMemo(
		() => ({
			owner2: transaction.owner2,
			description: transaction.description,
			glDate: transaction.glDate,
			batch: transaction.batch
		}),
		[]
	);

	const {
		Form
		// values,
		// pushFieldValue,
		// removeFieldValue,
		// meta: {
		// 	isSubmitting,
		// 	isSubmitted,
		// 	canSubmit,
		// 	error
		// }
	} = useForm({
		defaultValues,
		onSubmit: async values => {
			await new Promise(resolve => setTimeout(resolve, 1000));
			console.log(values);
		},
		debugForm: false
	});

	useEffect(() => {
		if (!vendor || vendor.vendorNo !== transaction.vendorNo) {
			const newVendor = getVendorFromAn8(vendors, transaction.vendorNo);
			setVendor(newVendor);
		}

		if ((disabled !== transaction.status) !== status.uploaded)
			setDisabled(transaction.status !== status.uploaded);
	}, [transaction]);

	useEffect(() => {
		const expenses = getExpensesFromAttachments(attachments);
		setExpenseAttachments(expenses);
	}, [attachments]);

	const handleFieldChange = async fieldInfo => {
		if (!fieldInfo) return;

		if (!fieldInfo.fieldName) return;

		if (!transaction[fieldInfo.fieldName] && !fieldInfo.value) return;
		if (transaction[fieldInfo.fieldName] === fieldInfo.value) return;

		dispatch({ type: editActions.updateTransactionField, payload: fieldInfo });
	};

	const handleVendorChange = async newVendor => {
		const nVendor = newVendor ? newVendor : { an8: 0, name: "" };

		if (transaction["vendorNo"] === nVendor["an8"]) return;

		const fieldInfos = [
			{
				fieldName: "vendor",
				value: newVendor.name
			},
			{
				fieldName: "vendorNo",
				value: newVendor.an8
			}
		];

		dispatch({ type: editActions.updateTransactionField, payload: fieldInfos });
	};

	function handleAddEmployee() {
		setShowOwner2(true);
	}

	return (
		<div className="mt-8">
			<Form>
				<CardWithFancyHeader
					title={
						<div className="flex justify-between content-center">
							<div className="ml-4 mt-2 mb-2 text-gray-800 text-xl">
								Expense Information
							</div>

							<Menu as="div" className="relative inline-block text-left">
								{({ open }) => (
									<>
										<div>
											<Menu.Button className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
												<DotsVerticalIcon
													className="-mr-1 ml-2 h-5 w-5"
													aria-hidden="true"
												/>
											</Menu.Button>
										</div>

										<Transition
											show={open}
											as={Fragment}
											enter="transition ease-out duration-100"
											enterFrom="transform opacity-0 scale-95"
											enterTo="transform opacity-100 scale-100"
											leave="transition ease-in duration-75"
											leaveFrom="transform opacity-100 scale-100"
											leaveTo="transform opacity-0 scale-95"
										>
											<Menu.Items
												static
												className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
											>
												<div className="py-1">
													<Menu.Item onClick={handleAddEmployee}>
														{({ active }) => (
															<a
																href="#"
																className={classNames(
																	active
																		? "bg-gray-100 text-gray-900"
																		: "text-gray-700",
																	"block px-4 py-2 text-sm"
																)}
															>
																Add Override Responsible Employee
															</a>
														)}
													</Menu.Item>
												</div>
											</Menu.Items>
										</Transition>
									</>
								)}
							</Menu>

							{/*<button className="mt-2 mb-2 mr-4" onClick={handleShowMenu}>*/}
							{/*	<DotsVertical />*/}

							{/*</button>*/}
							{/*<Menu*/}
							{/*	id="information-menu"*/}
							{/*	anchorEl={anchorEl}*/}
							{/*	keepMounted*/}
							{/*	open={Boolean(anchorEl)}*/}
							{/*	onClose={hideMenu}*/}
							{/*>*/}
							{/*	<MenuItem onClick={handleAddEmployee}>*/}
							{/*		Override Responsible Employee*/}
							{/*		<Button*/}
							{/*			justIcon*/}
							{/*			round*/}
							{/*			simple*/}
							{/*			color="warning"*/}
							{/*			className="edit"*/}
							{/*		>*/}
							{/*			<MoreVertIcon />*/}
							{/*		</Button>*/}

							{/*	</MenuItem>*/}
							{/*</Menu>*/}
						</div>
					}
				>
					{showOwner2 && (
						<div>
							<InputField
								id={"owner2"}
								field="owner2"
								label="Responsible Employee"
								placeholder="Enter Responsible Employee"
								onBlur={event => {
									const val = event.target.value;
									handleFieldChange({ fieldName: "owner2", value: val });
								}}
							/>
						</div>
					)}

					<SelectVendor2
						label="Vendor *"
						initialVendor={vendor}
						vendors={vendors}
						onSelectChanged={handleVendorChange}
						disabled={disabled}
					/>

					<TextAreaField
						name="description"
						field="description"
						label="Description"
						placeholder="Enter Description"
						onBlur={event => {
							const val = event.target.value;
							handleFieldChange({ fieldName: "description", value: val });
						}}
					/>

					<ExpenseTable expences={expenseAttachments} />

					<div className="mt-2">
						<CardWithHeader title="For Accounting">
							<div className="flex">
								<div className="w-1/2">
									<DateField
										field="glDate"
										id="glDate"
										label="G/L Date"
										placeholder="Enter G/L Date"
										onValueChanged={handleFieldChange}
										// disabled={disabled}
									/>
								</div>

								<div className="w-1/2 ml-2">
									<InputField
										id={"batch"}
										field="batch"
										label="Batch"
										onBlur={event => {
											const val = event.target.value;
											handleFieldChange({ fieldName: "batch", value: val });
										}}
									/>
								</div>
							</div>
						</CardWithHeader>
					</div>
				</CardWithFancyHeader>
			</Form>
			<CardWithHeader title="Transaction Information">
				<div className="mt-2">
					{transaction.createdBy && (
						<div>
							<div>
								Created By:{" "}
								<strong>
									{transaction.createdBy}{" "}
									{transaction.owner2 ? " for " + transaction.owner2 : ""} on{" "}
									{transaction.createdAt}
								</strong>
							</div>
						</div>
					)}
					{transaction.ready && (
						<div>
							Ready:{" "}
							<strong>
								{transaction.ready} on {transaction.readyDate}
							</strong>
						</div>
					)}
					{transaction.approval1 && (
						<div>
							Approved:{" "}
							<strong>
								{transaction.approval1} on {transaction.approval1Date}
							</strong>
						</div>
					)}
					{transaction.approval2 && (
						<div>
							Approved:{" "}
							<strong>
								{transaction.approval2} on {transaction.approval2Date}
							</strong>
						</div>
					)}
				</div>
				<div className="mt-2">id: {transaction.id}</div>
				<div className="mt-2">
					<div>
						Payment Batch:{" "}
						{transaction.payment ? transaction.payment.paymentBatch : ""}
					</div>
					<div>
						Payment Number:{" "}
						{transaction.payment ? transaction.payment.paymentNumber : ""}
					</div>
				</div>
				<div className="mt-2 flex">
					<div className="mr-2">Groups:</div>
					{transaction.groups &&
						transaction.groups.map(grp => {
							return (
								<div key={grp} className="mx-1">
									{grp}
								</div>
							);
						})}
				</div>
			</CardWithHeader>
		</div>
	);
};
