import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useTransactionsReducer } from "./transactions-provider";
import { useTransactionsState } from "./transactions-provider";
import { useMonitorTransactions } from "./useMonitorTransactions";
import { useInvoiceCacheUserRef } from "../InvoiceCacheProvider";
import { getInvoiceNumbersFromTransactions } from "../transaction/validateInvoices";

export default function TransactionsMonitor({ children }) {
	const dispatch = useTransactionsReducer();
	const state = useTransactionsState();
	const invoiceCache = useInvoiceCacheUserRef();

	useMonitorTransactions(dispatch);

	useEffect(() => {
		let cache = getInvoiceNumbersFromTransactions(state.transactions);

		invoiceCache.current = cache;
	}, [state.transactions]);

	return <>{children}</>;
}

TransactionsMonitor.propTypes = {
	children: PropTypes.any.isRequired
};
