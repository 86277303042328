import { useState } from "react";
import { API } from "aws-amplify";

import * as queries from "graphql/queries";
// import * as mutations from 'graphql/mutations';

import { getEditTransaction } from "etegrisService/serviceGraphql";

const useModifyTransaction = () => {
	const [isProcessing, setIsProcessing] = useState(false);
	const [error, setError] = useState("");

	const process = async () => {
		try {
			setError("");
			setIsProcessing(true);

			const transactionOptions = {
				query: queries.listTransactions,
				variables: {
					limit: 2000
				}
			};

			const tranResults = await API.graphql(transactionOptions);

			const transactions = tranResults.data.listTransactions.items;
			console.log("useModifyTransaction.js process transactions", transactions);

			for (const tran of transactions) {
				const eTran = await getEditTransaction(tran.id);

				let newTran = eTran.transaction;
				newTran.attachments = eTran.attachments;
				newTran.distributions = eTran.distributions;
				newTran.messages = eTran.messages;
				delete newTran.actions;

				const json = JSON.stringify(newTran);
				console.log("useModifyTransaction.js --- json", json.length, json);

				console.log("useModifyTransaction.js process tran", newTran);
			}

			setIsProcessing(false);
		} catch (e) {
			setIsProcessing(false);
			setError(JSON.stringify(e, null, 2));
			console.log("useModifyTransaction.js process.catch(e)", e);
		}
	};

	return {
		processTrans: process,
		isProcessingTrans: isProcessing,
		errorTrans: error
	};
};

export default useModifyTransaction;
