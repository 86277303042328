import React, { useEffect } from "react";

import useSubledgers from "./useSubledgers";

export const SubLedgersView = () => {
	const {
		subLedgers,
		loadSubledgers,
		isProcessingSubledgers,
		errorSubledgers,
		canUploadSubledgers,
		updateS3,
		message
	} = useSubledgers();

	useEffect(() => {
		console.log("SubLedgersView.js subLedgers changed subLedgers", subLedgers);
	}, [subLedgers]);

	const handleLoad = async () => {
		await loadSubledgers();
	};

	const handleUploadtoS3 = async () => {
		await updateS3();
	};

	return (
		<div className="m-10 border-2 border-gray-300">
			<div className="flex">
				<button className="jde-button" onClick={handleLoad}>
					Get Sub ledgers
				</button>

				<button
					className="jde-button"
					disabled={!canUploadSubledgers}
					onClick={handleUploadtoS3}
				>
					Upload Subledgers to S3
				</button>
			</div>

			{isProcessingSubledgers && (
				<div className="text-green-400">Processing Subledgers</div>
			)}
			{errorSubledgers && (
				<div className="text-red-500">
					<div>Error loading subledgers</div>
					<div>{errorSubledgers}</div>
				</div>
			)}

			{message.length > 0 && <div>{message}</div>}

			{
				<div className="mt-10">
					<div>subledger count: {subLedgers.length}</div>
				</div>
			}
		</div>
	);
};
