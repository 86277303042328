import * as actions from "./actions";

export default function transactionsReducer(draft, action) {
	switch (action.type) {
		case actions.refresh: {
			// TODO: do a sort here
			draft.transactions = action.payload;
			return;
		}
		case actions.loading: {
			draft.loadingTransactions = action.payload;
			return;
		}
		case actions.insert: {
			draft.transactions.unshift(action.payload);

			return;
		}
		case actions.replace: {
			const index = draft.transactions.findIndex(
				tran => tran.id === action.payload.id
			);
			if (index == -1) return;

			draft.transactions[index] = action.payload;

			return;
		}
		case actions.setStatusFilter: {
			draft.statusFilter = action.filter;

			return;
		}
		case actions.setFilters: {
			draft.filters = action.filters;

			return;
		}
		case actions.setGlobalFilter: {
			console.log("transactionsReducer.js setGlobalFilter action", action);
			draft.globalFilter = action.globalFilter;

			return;
		}
	}
}

export const removeItemAtIndex = (arr, index) => {
	return [...arr.slice(0, index), ...arr.slice(index + 1)];
};
