const transactionFieldNames = [
	"approval1",
	"approval1Date",
	"approval2",
	"approval2Date",
	"description",
	"batch",
	"gst",
	"invoice",
	"invoiceDate",
	"isBP",
	"glDate",
	"meta",
	"multipleExpenses",
	"net",
	"owner2",
	"ready",
	"readyDate",
	"remark",
	"schema",
	"status",
	"vendor",
	"vendorNo",
	"total",
	"title",
	"userReference"
];

export const getFieldChangesForTransaction = (orig, changed) => {
	return getFieldChangesForTable(orig, changed, transactionFieldNames);
};

function getFieldChangesForTable(orig, changed, fieldNames) {
	const changes = [];

	fieldNames.forEach(fieldName => {
		if (orig[fieldName] !== changed[fieldName]) {
			const change = {
				id: orig.id,
				fieldName: fieldName,
				value: changed[fieldName]
			};
			changes.push(change);
		}
	});

	return changes;
}
