import { round } from "mathjs";

const editValidation = state => {
	if (!state.transaction)
		return { valid: false, message: "Transaction is missing" };

	if (!state.transaction.vendorNo)
		return { valid: false, message: "Vendor is not set" };

	if (!state.transaction.total)
		return { valid: false, message: "Total must not be zero" };

	if (!state.transaction.gst) {
		if (state.transaction.gst !== 0)
			return { valid: false, message: "Gst must be set" };
	}

	if (state.distributions.length === 0) {
		return { valid: false, message: "Must be at least one Charge To account" };
	}

	for (let i = 0; i < state.distributions.length; i++) {
		const dist = state.distributions[i];

		if (!dist.accountNo || dist.accountNo === "0") {
			return {
				valid: false,
				message: `An account must be set for Charge To: [${i + 1}]`
			};
		}

		if (dist.postingEdit === "L") {
			if (!dist.subledgerType)
				return {
					valid: false,
					message: `Charge To account [${dist.account}] must have sub-ledger`
				};
		}

		if (dist.subledgerType === "A") {
			if (!dist.subledgerId || dist.subledgerId === "0") {
				return {
					valid: false,
					message: `Charge To account [${dist.account}] has sub-ledger showing, but is not set`
				};
			}
		}
	}

	const sum = round(
		state.distributions.reduce((acc, dist) => dist.amount + acc, 0),
		2
	);

	const calcedNet = round(state.transaction.total - state.transaction.gst, 2);

	if (calcedNet !== sum)
		return {
			valid: false,
			message: "Sum of Charge To accounts does not equal the expense Net amount"
		};

	return { valid: true, message: "" };
};

export function getErrorMessage(editTransaction) {
	if (!editTransaction.transaction) return "No Transaction";

	if (!editTransaction.transaction.vendor) return "No Vendor";

	if (!editTransaction.transaction.total) return "No Total";

	if (!editTransaction.transaction.gst) return "No GST";

	const sum = round(
		editTransaction.distributions.reduce((acc, dist) => dist.amount + acc, 0),
		2
	);

	if (
		round(
			editTransaction.transaction.total - editTransaction.transaction.gst,
			2
		) !== sum
	)
		return "Sum of Distribution must match Net Amount";
}

export default editValidation;
