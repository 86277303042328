import { PDFDocument, rgb, StandardFonts } from "pdf-lib";
import { getFetchableImageUrl } from "./s3Storage";

export const stampBPImageKeyWithApprolvals = async (
	imagekey,
	options = {
		approval1: "notset",
		approval2: "notset"
	}
) => {
	const url1 = await getFetchableImageUrl(imagekey);

	const pdfBytes = await fetch(url1).then(res => res.arrayBuffer());

	return stampBPWithApprovals(pdfBytes, options);
};

async function stampBPWithApprovals(existingPdfBytes, options) {
	// existingPdfBytes should be a Uint8Array or ArrayBuffer
	// This data can be obtained in a number of different ways
	// If your running in a Node environment, you could use fs.readFile()
	// In the browser, you could make a fetch() call and use res.arrayBuffer()

	// Load a PDFDocument from the existing PDF bytes
	const pdfDoc = await PDFDocument.load(existingPdfBytes);

	// Embed the Helvetica font
	const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);

	// Get the first page of the document
	const pages = pdfDoc.getPages();
	const firstPage = pages[0];

	// Get the width and height of the first page

	function stampField(x, y, text) {
		firstPage.drawText(text, {
			x: x,
			y: y,
			size: 12,
			font: helveticaFont,
			color: rgb(0.1, 0.95, 0.1)
		});
	}

	stampField(175, 180, options.approval1);
	stampField(390, 180, options.approval1Date);

	stampField(175, 152, options.approval2);
	stampField(390, 152, options.approval2Date);

	// Serialize the PDFDocument to bytes (a Uint8Array)
	const pdfBytes = await pdfDoc.save();

	return pdfBytes;
	// For example, `pdfBytes` can be:
	//   • Written to a file in Node
	//   • Downloaded from the browser
	//   • Rendered in an <iframe>
}

export default stampBPWithApprovals;
