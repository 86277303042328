import React, { useEffect, useState } from "react";

const AddressBookStateContext = React.createContext();
const AddressBookSetContext = React.createContext();

function AddressBookProvider({ children, getAddressBookJson }) {
	const [addressBooks, setAddressBooks] = useState([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);

	useEffect(() => {
		const run = async () => {
			setLoading(true);

			const result = await getAddressBookJson();

			if (result.status === "OK") {
				setAddressBooks(result.vendors);
			} else {
				setError(result.error);
			}
			// TODO: should hanlde error an notify the user

			setLoading(false);
		};

		run();
	}, []);

	if (error) {
		return <div>in error</div>;
	}

	if (loading) {
		return <div> Loading Address Book Information</div>;
	}

	return (
		<AddressBookStateContext.Provider value={addressBooks}>
			<AddressBookSetContext.Provider value={setAddressBooks}>
				{children}
			</AddressBookSetContext.Provider>
		</AddressBookStateContext.Provider>
	);
}

function useAddressBookState() {
	const context = React.useContext(AddressBookStateContext);
	if (context === undefined) {
		throw new Error(
			"useAddressBookState must be used within a AddressBookProvider"
		);
	}
	return context;
}

function useAddressBookSetState() {
	const context = React.useContext(AddressBookSetContext);
	if (context === undefined) {
		throw new Error(
			"useAddressBookSetState must be used within a AddressBookProvider"
		);
	}
	return context;
}

export { AddressBookProvider, useAddressBookState, useAddressBookSetState };
