import React, { useEffect, useState } from "react";
import CloudUpload from "svg/CloudUpload";

import ImageUploadEcd from "etegris/transaction/document/ImageUploadEcd";

import CardWithFancyHeader from "1-atoms/CardWithFancyHeader";

const inputGood =
	"h-8 pl-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full border-2 border-gray-300 rounded-md";

export const AddExpense = ({ onUpload, onUploadBP }) => {
	const [file, setFile] = useState(null);
	const [enableUpload, setEnableUpload] = useState(true);
	const [title, setTitle] = useState("");

	const fileChanged = fileInfo => {
		setFile(fileInfo);
	};

	useEffect(() => {
		if (title && file) setEnableUpload(true);
		else setEnableUpload(false);
	}, [file, title]);

	useEffect(() => {
		if (file && file.name) {
			if (title !== file.name) setTitle(file.name);
		} else {
			if (title !== "") setTitle("");
		}
	}, [file]);

	const handleUploadButtonClick = () => {
		onUpload && onUpload(file, title);
	};
	const handleUploadButtonBPClick = () => {
		onUploadBP && onUploadBP(file, title);
	};

	return (
		<CardWithFancyHeader
			title={
				<div className="flex justify-between content-center">
					<div className="ml-4 mt-2 mb-2 text-gray-100 text-xl">
						Add New Expense
					</div>
				</div>
			}
		>
			<div className="h-full">
				<div className="flex h-full">
					<div className="w-1/2 h-full">
						<legend>{file ? file.name : ""}</legend>
						<ImageUploadEcd onChange={fileChanged} />
					</div>
					<div className="w-1/2 m-2">
						<label
							htmlFor="title"
							className="block text-sm font-medium text-gray-500"
						>
							Title
						</label>

						<input
							className={inputGood}
							id="title"
							value={title}
							placeholder="Enter title for document"
							onChange={e => setTitle(e.target.value)}
						/>

						{enableUpload && (
							<div className="flex flex-row mt-3">
								<button
									className="inline-flex items-center mr-2 px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
									onClick={handleUploadButtonClick}
								>
									<CloudUpload /> Upload
								</button>

								<button
									className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
									onClick={handleUploadButtonBPClick}
								>
									<CloudUpload /> Upload BP
								</button>
							</div>
						)}
					</div>
				</div>
			</div>
		</CardWithFancyHeader>
	);
};
