import React, { useEffect, useState } from "react";

import * as actions from "./editActions";
import editReducer from "./editReducer";
import { Hub } from "aws-amplify";
import { useImmerReducer } from "use-immer";
import produce from "immer";

const messagesReducer = (draft, action) => {
	switch (action.type) {
		case actions.insertMessage: {
			if (
				draft.transaction &&
				draft.transaction.id !== action.payload.transactionId
			)
				return;

			draft.messages.unshift(action.payload);
			return;
		}
	}
};

const EditExpenseStateContext = React.createContext();
const EditExpenseReducerContext = React.createContext();

function EditExpenseProvider({ children, editTran }) {
	const initialState = { ...editTran, showAddAttachment: false };
	const initialMessageState = {
		transactionId: editTran.transaction.id,
		messages: editTran.messages
	};

	const [messagesState, messagesDispatch] = useImmerReducer(
		messagesReducer,
		initialMessageState
	);

	const [state, dispatch] = useImmerReducer(editReducer, initialState);
	const [fullState, setFullState] = useState({
		...initialState,
		messagesState: initialMessageState
	});

	const fullDispatch = action => {
		dispatch(action);

		messagesDispatch(action);
	};

	useEffect(() => {
		dispatch({ type: actions.start, payload: editTran });
	}, [editTran, dispatch]);

	useEffect(() => {
		const nextState = produce(fullState, draft => {
			draft.state = state;
			draft.messagesState = messagesState;
		});

		setFullState(nextState);
	}, [state, messagesState]);

	useEffect(() => {
		if (!editTran) return;
		if (!editTran.transaction) return;

		const tranId = editTran.transaction.id;

		const listener = data => {
			if (editTran && editTran.transaction)
				dispatch({
					type: data.payload.event,
					source: "subscription",
					payload: data.payload.data
				});
		};

		const channel = `Transaction-${tranId}`;
		Hub.listen(channel, listener);
		return () => {
			Hub.remove(channel, listener);
		};
	}, [editTran, dispatch]);

	return (
		<EditExpenseStateContext.Provider value={state}>
			<EditExpenseReducerContext.Provider value={fullDispatch}>
				{children}
			</EditExpenseReducerContext.Provider>
		</EditExpenseStateContext.Provider>
	);
}

function useEditExpenseState() {
	const context = React.useContext(EditExpenseStateContext);
	if (context === undefined) {
		throw new Error(
			"useEditExpenseState must be used within a EditTransactionProvider"
		);
	}
	return context;
}

function useEditExpenseReducer() {
	const context = React.useContext(EditExpenseReducerContext);
	if (context === undefined) {
		throw new Error(
			"useEditExpenseReducer must be used within a EditTransactionProvider"
		);
	}
	return context;
}

export { EditExpenseProvider, useEditExpenseState, useEditExpenseReducer };
