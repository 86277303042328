import React from "react";
import AdminTW from "./AdminTW";
import JdeView from "./etegris/jde/JdeView";

const PageJde = () => {
	return (
		<AdminTW>
			<JdeView />
		</AdminTW>
	);
};

export default PageJde;
