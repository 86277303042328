import * as actions from "./actions";
import { useUserState } from "../employee/user-context";

import {
	useTransactionsReducer,
	useTransactionsState
} from "./transactions-provider";
import { API, graphqlOperation } from "aws-amplify";
import * as quires from "../../graphql/queries";
import { filterSortExpensesDesc } from "../../etegrisService/serviceEditTransaction";

const useTransactions = () => {
	const state = useTransactionsState();
	const dispatch = useTransactionsReducer();

	const userState = useUserState();

	async function insertTransaction(transaction) {
		dispatch({ type: actions.insert, payload: transaction });
	}

	async function refreshTransactions2() {
		dispatch({ type: actions.loading, payload: true });

		let statuses = [
			"Incomplete",
			"Ready",
			"Approved 1",
			"Approved 2",
			"A/P Hold",
			"A/P",
			"A/P Initial",
			"A/P Verified",
			"Processed",
			"Deleted"
		];

		let expenses = [];

		for (let i = 0; i < statuses.length; i++) {
			let status = statuses[i];

			let nextToken = null;

			let variables = {
				limit: 500,
				authMode: "AMAZON_COGNITO_USER_POOLS",
				// sortDirection: "DESC",
				status: status
			};

			let holdExpenses = [];
			do {
				let data = await API.graphql(
					graphqlOperation(quires.transactionsByStatus, variables)
				);

				holdExpenses = holdExpenses.concat(
					data.data.transactionsByStatus.items
				);

				nextToken = data.data.transactionsByStatus.nextToken;
				variables = { ...variables, nextToken };
			} while (nextToken);

			expenses = expenses.concat(holdExpenses);

			dispatch({
				type: actions.refresh,
				payload: filterSortExpensesDesc(expenses)
			});
		}

		if (userState.user.isAccounting) {
			dispatch({ type: actions.refresh, payload: expenses });
		} else if (userState.user.isAdmin || userState.user.isApprover) {
			const cfs = expenses.filter(tran => {
				return tran.groups.includes("Cfs") || tran.groups.includes("CfsAdmin")
					? true
					: false;
			});

			dispatch({ type: actions.refresh, payload: cfs });
		} else {
			// must be a worker
			const worker = expenses.filter(tran => {
				return tran.createdBy === userState.user.username ? true : false;
			});

			dispatch({ type: actions.refresh, payload: worker });
		}

		dispatch({ type: actions.loading, payload: false });
	}

	// const getTransactions = async () => {
	//
	// 	let expenses = [];
	// 	let nextToken = null;
	// 	let variables = { limit: 500 };
	// 	do {
	// 		let data = await API.graphql(graphqlOperation(quires.listTransactions, variables))
	// 		expenses = expenses.concat(data.data.listTransactions.items);
	// 		nextToken = data.data.listTransactions.nextToken;
	// 		variables = { nextToken };
	// 	} while (nextToken)
	//
	// 	return filterSortExpensesDesc(expenses);
	// };

	function replaceTransaction(transaction) {
		dispatch({ type: actions.replace, payload: transaction });
	}

	return {
		refreshTransactions: refreshTransactions2,
		insertTransaction,
		replaceTransaction,
		state,
		dispatch
	};
};

export default useTransactions;
