import { v4 as uuidv4 } from "uuid";
import * as status from "../a-constants/status";
import { uploadBytes, uploadFile } from "./s3Storage";
import { API, Auth } from "aws-amplify";
import moment from "moment";
import { parseBestPracticeText } from "./parseBestPracticeText";
import accounts from "1-atoms/accounts.json";
import subledgers from "1-atoms/subLedgers.json";
import { stampBPImageKeyWithApprolvals } from "./stampBPWithApprovals";
import createChequeRequestWithApprovals from "./createChequeRequestWithApprovals";
import cleanEditTransaction from "./cleanEditTransaction";

import * as mutations from "graphql/mutations";
import * as queries from "graphql/queries";
import PdfService from "../infrastructure/pdf/PdfService.mjs";

export {
	getEditTransaction,
	saveHistory,
	getHistoryForTransactionId
} from "./serviceGraphql.js";

async function uploadFileToS3(fileInfo) {
	const uuid = uuidv4();

	const imageKey = `${uuid}_${fileInfo.name}`;

	const result = await uploadFile(imageKey, fileInfo);

	return {
		imageKey: imageKey,
		result: result
	};
}

async function uploadBytesToS3(bytes, fileName) {
	const uuid = uuidv4();

	const imageKey = `${uuid}_${fileName}`;

	const result = await uploadBytes(imageKey, bytes);

	return {
		imageKey: imageKey,
		result: result
	};
}

export async function uploadAttachmentForTransactionId(
	fileInfo,
	title,
	homeGroup,
	tranId
) {
	const uploadResult2 = await uploadFileToS3(fileInfo);

	console.log("index.js uploadAttachmentForTransactionId homeGroup", homeGroup);

	return {
		id: uuidv4(),
		transactionId: tranId,
		imagekey: uploadResult2.imageKey,
		name: title,
		isExpense: false,
		total: 0,
		gst: 0,
		net: 0,
		date: "",
		description: "",
		invoice: "",
		remark: "",
		isBP: false,
		bpNumber: "",
		distributions: [],
		groups: [homeGroup]
	};
}

async function uploadAttachmentForBytesTransactionId(
	bytes,
	fileName,
	title,
	tranId
) {
	const uploadResult2 = await uploadBytesToS3(bytes, fileName);

	return {
		id: uuidv4(),
		transactionId: tranId,
		imagekey: uploadResult2.imageKey,
		name: title,
		isExpense: false,
		total: 0,
		gst: 0,
		net: 0,
		description: "",
		invoice: "",
		remark: "",
		date: "",
		isBP: false,
		bpNumber: "",
		groups: ["CfsAdmin", "CfsApprover"]
	};
}

export async function callCreateBpPdf(bpInfo) {
	const myInit = {
		// OPTIONAL
		headers: {}, // OPTIONAL
		queryStringParameters: {
			// OPTIONAL
			something: "something data",
			transaction: JSON.stringify(bpInfo)
		}
	};

	const response = await API.get("createBestPracticePDF", "/pdf", myInit);

	return response;
}

export async function createBpTransaction(
	bpInfo,
	user,
	accounts,
	addressBooks
) {
	//const vendor = subledgers.find(sub => sub.an8 === bpInfo.an8);

	const vendor = addressBooks.find(sub => sub.an8 === bpInfo.tranh_ven_id);

	const id = uuidv4();

	const expense = {
		id: id,
		meta: "beta",
		schema: "0.1",
		status: "Incomplete",
		//status.uploaded,
		title: bpInfo.tranh_id,
		total: bpInfo.tranh_amount + bpInfo.tranh_hst_amount,
		gst: bpInfo.tranh_hst_amount,
		net: bpInfo.tranh_amount,
		owner2: bpInfo.created_by,
		vendorNo: vendor.an8,
		vendor: vendor.name,
		invoice: "",
		invoiceDate: moment(bpInfo.tranh_date).toISOString(),
		description: bpInfo.tranh_description,
		remark: "",
		batch: "",
		isBP: true,
		ready: "",
		readyDate: "",
		approval1: "",
		approval1Date: "",
		approval2: "",
		approval2Date: "",
		createdBy: user.username,
		groups: [user.homeGroup]
	};

	let distributions = [];
	for (let i = 0; i < bpInfo.details.length; i++) {
		let chargeTo = bpInfo.details[i];

		const account = accounts.find(acc => acc.account === chargeTo.glcode);
		const subledger = addressBooks.find(sub => sub.an8 === chargeTo.an8);

		const dist = {
			id: uuidv4(),
			accountNo: account.aid,
			account: chargeTo.glcode,
			postingEdit: account.pec,
			subledgerType: "A",
			subledgerId: subledger ? subledger.an8 : "",
			subledgerName: subledger ? subledger.name : "",
			amount: chargeTo.amount,
			transactionId: id
		};

		distributions.push(dist);
	}

	let attachments = [
		{
			imagekey: bpInfo.tranh_id + "-cheque-request.pdf",
			name: bpInfo.tranh_id,
			isExpense: false,
			total: 0,
			gst: 0,
			net: 0,
			date: "",
			description: "",
			invoice: "",
			remark: "",
			isBP: true,
			bpNumber: bpInfo.tranh_id,
			transactionId: id
		}
	];

	const newTransaction = {
		...expense,
		distributions,
		attachments
	};

	expense.json = JSON.stringify(newTransaction);

	const newTranResult = await API.graphql({
		query: mutations.createTransaction,
		variables: { input: expense }
	});

	return newTranResult;
}

export async function uploadDocument(
	fileInfo,
	title,
	homeGroup,
	username,
	isBP = false
) {
	const expense = {
		meta: "beta",
		status: status.uploaded,
		title: title,
		total: 0,
		gst: 0,
		net: 0,
		owner2: "",
		vendorNo: "",
		vendor: "",
		invoice: "",
		invoiceDate: "",
		description: "",
		remark: "",
		batch: "",
		isBP: false,
		ready: "",
		readyDate: "",
		approval1: "",
		approval1Date: "",
		approval2: "",
		approval2Date: "",
		createdBy: username,
		groups: [homeGroup]
	};

	const newTranResult = await API.graphql({
		query: mutations.createTransaction,
		variables: { input: expense }
	});

	const newTran = newTranResult.data.createTransaction;

	const toBase64 = file =>
		new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = error => reject(error);
		});

	const base64 = await toBase64(fileInfo);
	var base64result = base64.split(",")[1];
	console.log("base64", base64result);
	const array = Uint8Array.from(window.atob(base64result), v =>
		v.charCodeAt(0)
	);
	var pdfService = PdfService.create(array);
	var text = await pdfService.parsePdf();
	console.log("test", text);

	const uploadResult2 = await uploadFileToS3(fileInfo);

	let bpInfo;
	if (isBP) {
		const myInit = {
			// OPTIONAL
			headers: {}, // OPTIONAL
			queryStringParameters: {
				// OPTIONAL
				pdfKey: uploadResult2.imageKey
			}
		};

		var start = performance.now();
		const response = await API.get("parsePdfApi", "/items", myInit);
		console.log("index.js uploadDocument response", response);
		var end = performance.now();

		console.log(`--------------- parsing PDF Time: \t${end - start}`);

		bpInfo = parseBestPracticeText(response.text);
		console.log("index.js uploadDocument bpInfo", bpInfo);

		const vendor = subledgers.find(sub => sub.an8 === bpInfo.an8);

		const fileInfos = [
			{ fieldName: "title", value: bpInfo.bestPracticeNumber },
			{ fieldName: "vendorNo", value: vendor ? vendor.an8 : "" },
			{ fieldName: "vendor", value: vendor ? vendor.name : "" },
			{ fieldName: "total", value: bpInfo.total },
			{ fieldName: "gst", value: bpInfo.gst },
			{
				fieldName: "invoiceDate",
				value: moment(bpInfo.date).toISOString()
			},
			{ fieldName: "description", value: bpInfo.descriptions.join("\n") },
			{ fieldName: "owner2", value: bpInfo.worker },
			{ fieldName: "isBP", value: true }
		];

		const savedTran = { id: newTran.id };
		console.log("index.js uploadDocument savedTran", savedTran);
		await saveTransactionField(savedTran, fileInfos);
	}

	console.log("index.js uploadDocument newTran", newTran);

	const attachData = {
		imagekey: uploadResult2.imageKey,
		name: title,
		isExpense: false,
		total: 0,
		gst: 0,
		net: 0,
		date: "",
		description: "",
		invoice: "",
		remark: "",
		isBP: isBP,
		bpNumber: isBP ? bpInfo.bestPracticeNumber : "",
		transactionId: newTran.id,
		groups: [homeGroup]
	};

	console.log("index.js uploadDocument attachData", attachData);
	const newAttachment = await API.graphql({
		query: mutations.createAttachment,
		variables: {
			input: attachData
		}
	});

	console.log("index.js uploadNewDocument newAttachment", newAttachment);

	if (isBP) {
		for (let k = 0; k < bpInfo.chargeTo.length; k++) {
			const chargeTo = bpInfo.chargeTo[k];

			console.log("index.js for loop chargeTo", chargeTo);
			const account = accounts.find(acc => acc.account === chargeTo.account);
			const subledger = subledgers.find(
				sub => sub.an8 === chargeTo.subledgerNo
			);

			const dist = {
				transactionId: newTran.id,
				accountNo: account.aid,
				account: account.account,
				postingEdit: account.pec,
				subledgerType: "A",
				subledgerId: subledger ? subledger.an8 : "",
				subledgerName: subledger ? subledger.name : "",
				amount: chargeTo.amount,
				groups: ["CfsAdmin", "CfsApprover"]
			};

			console.log("index.js before upload dist", dist);

			try {
				const newDistribution = await API.graphql({
					query: mutations.createDistribution,
					variables: {
						input: dist
					}
				});
				console.log("index.js ----- newDistribution", newDistribution);
			} catch (e) {
				console.log("index.js creating dist e", e);
			}
		}
	} else {
		const dist = {
			transactionId: newTran.id,
			accountNo: "",
			account: "",
			postingEdit: "",
			subledgerType: "",
			subledgerId: "",
			subledgerName: "",
			amount: 0,
			groups: ["CfsAdmin", "CfsApprover"]
		};

		try {
			const newDistribution = await API.graphql({
				query: mutations.createDistribution,
				variables: {
					input: dist
				}
			});
			console.log("index.js ----- newDistribution", newDistribution);
		} catch (e) {
			console.log("index.js creating distributino e", e);
		}
	}
	// TODO: should trap errors and handle so user can figure out what is going on

	console.log("------- returning newTran");
	return newTran;
}

export const sendEmailToUser = async (message, transaction, to) => {
	console.log(
		"index.js sendEmailToUser document.location.origin",
		document.location.origin
	);

	const html = `<h3>There was a comment on an expense: ${message.transactionId}</h3>
 					<p>Paid To: ${transaction.vendor}</p>
 					<p>Amount: ${transaction.total}</p>
 					<p>Description: ${transaction.description}</p>
 					<p>From: ${message.username}</p>
 					<p>Comment: ${message.comment}</p>
 					<p><a>${document.location.origin}/admin/expense?id=${message.transactionId}</a></a></p>
				`;

	const myInit = {
		// OPTIONAL
		headers: {}, // OPTIONAL
		queryStringParameters: {
			subject: `EDC Comment: ${transaction.vendor} - ${transaction.total}`,
			to: to,
			from: "expense@expensedatacapture.com",
			html: html,
			text: "this is the message: " + message.comment
		}
	};

	await API.get("email", "/email", myInit);
};

export async function checkIfInvoiceExists(invoice) {
	if (invoice === "tom tom") return "invoice already exists!";

	return false;
}

export async function getEditTransactionFromTransactionId(transactionId) {
	const options = {
		query: queries.getTransaction,
		variables: {
			id: transactionId
		}
	};
	const tran = await API.graphql(options);

	const details = await getDetailsForTransactionId(transactionId);

	const editTran = {
		transaction: { ...tran },
		...details
	};

	return await cleanEditTransaction(editTran, saveAttachmentField);
}

export async function getAttachmentsForInvoice(
	attachmentId,
	invoice,
	vendorNo
) {
	if (!invoice || !vendorNo) return false;

	const options = {
		query: queries.listInvoices,
		variables: {
			filter: {
				vendorNo: { eq: vendorNo },
				invoice: { eq: invoice }
			}
		}
	};

	const results = await API.graphql(options);

	const attachments = results.data.listInvoices.items;
	console.log("index.js getAttachmentsForInvoice attachments", attachments);

	if (attachments.length === 0) return false;

	for (const attach of attachments) {
		if (attach.attachmentId === attachmentId) continue;

		console.log("index.js ----- compare", attach.invoice, invoice);
		if (attach.invoice === invoice) {
			return true;
		}
	}

	return false;
}

async function getDetailsForTransactionId(transactionId) {
	const distOptions = {
		query: queries.listDistributions,
		variables: {
			transactionId: transactionId
		}
	};
	const dists = await API.graphql(distOptions);

	const attachOptions = {
		query: queries.listAttachments,
		variables: {
			transactionId: transactionId
		}
	};
	const attaches = await API.graphql(attachOptions);

	const mesgOptions = {
		query: queries.listMessages,
		variables: {
			transactionId: transactionId
		}
	};
	const messages = await API.graphql(mesgOptions);

	console.log("index.js getDetailsForTransactionId dists", dists);

	return {
		distributions: dists,
		attachments: attaches,
		messages: messages
	};
}

export async function createDistribution(transactionId) {
	const newDistribution = {
		transactionId: transactionId,
		account: "",
		amount: 0,
		subledgerType: "",
		subledgerId: "",
		subledgerName: "",
		groups: ["CfsAdmin", "CfsApprover"]
	};
	await API.graphql({
		query: mutations.createDistribution,
		variables: {
			input: newDistribution
		}
	});
}

export const saveTransactionFieldById = async (transactionId, fieldInfo) => {
	return saveTransactionField({ id: transactionId }, fieldInfo);
};

export const saveTransactionField = async (transaction, fieldInfo) => {
	if (!transaction || !transaction.id) return;

	let updateTran = {
		id: transaction.id
	};
	if (Array.isArray(fieldInfo)) {
		for (let i = 0; i < fieldInfo.length; i++)
			updateTran[fieldInfo[i].fieldName] = fieldInfo[i].value;
	} else {
		updateTran[fieldInfo.fieldName] = fieldInfo.value;
	}

	const options = {
		query: mutations.updateTransaction,
		variables: { input: updateTran }
	};
	const result = await API.graphql(options);

	return result;
};

export const saveDistributionField = async (distribution, fieldInfo) => {
	let updateDist = {
		id: distribution.id
	};

	if (Array.isArray(fieldInfo)) {
		for (let i = 0; i < fieldInfo.length; i++)
			updateDist[fieldInfo[i].fieldName] = fieldInfo[i].value;
	} else {
		updateDist[fieldInfo.fieldName] = fieldInfo.value;
	}

	const options = {
		query: mutations.updateDistribution,
		variables: { input: updateDist }
	};
	await API.graphql(options);
};

export const saveAttachmentField = async (attachmentId, fieldInfo) => {
	const updated = {
		id: attachmentId
	};
	if (Array.isArray(fieldInfo)) {
		for (let i = 0; i < fieldInfo.length; i++)
			updated[fieldInfo[i].fieldName] = fieldInfo[i].value;
	} else {
		updated[fieldInfo.fieldName] = fieldInfo.value;
	}

	const options = {
		query: mutations.updateAttachment,
		variables: {
			input: updated
		}
	};

	const result = await API.graphql(options);

	console.log("index.js saveAttachmentField result", result);
};

export async function createApprovalAttachment(editTransaction) {
	if (editTransaction) {
		const tranId = editTransaction.transaction.id;

		const bytes = await createChequeRequestWithApprovals(editTransaction);

		const title2 = editTransaction.transaction.title + " Approved";
		const fileName = title2 + ".pdf";

		return await uploadAttachmentForBytesTransactionId(
			bytes,
			fileName,
			title2,
			tranId
		);
	}
}

export async function stampApprovalAttachment(attachment, options) {
	if (attachment) {
		const bytes = await stampBPImageKeyWithApprolvals(
			attachment.imagekey,
			options
		);

		const title2 = options.title + " Approved";
		const fileName = title2 + ".pdf";

		return await uploadAttachmentForBytesTransactionId(
			bytes,
			fileName,
			title2,
			attachment.transactionId
		);
	}
}

export async function createNotification(notification) {
	const options = {
		query: mutations.createNotification,
		variables: {
			input: notification
		}
	};

	const result = await API.graphql(options);
	console.log("index.js createNotification result", result);
}

export const saveMessage = async message => {
	const options = {
		query: mutations.createMessage,
		variables: {
			input: message
		}
	};

	const result = await API.graphql(options);
	return result;
};

export async function loadUser() {
	const user = await Auth.currentUserInfo();
	const user2 = await Auth.currentAuthenticatedUser();

	const groups = user2.signInUserSession.accessToken.payload["cognito:groups"];

	const isCfs = !!groups.includes("Cfs");
	const isAdmin = !!groups.includes("CfsAdmin");
	const isApprover = !!groups.includes("CfsApprover");

	const isAccounting = !!groups.includes("Accounting");
	const isJdeImport = !!groups.includes("JdeImport");

	const homeGroup = isCfs ? "Cfs" : "Finance";

	const user3 = {
		...user,
		homeGroup,
		isAdmin,
		isApprover,
		isAccounting,
		isJdeImport,
		groups,
		currentGroups: groups.slice()
	};

	return user3;
}
