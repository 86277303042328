import React from "react";
import AdminTW from "./AdminTW";
import DisplayExpensesState from "./etegris/transactions/DisplayExpensesState";

const PageTransactions = () => {
	return (
		<AdminTW>
			<DisplayExpensesState />
		</AdminTW>
	);
};

export default PageTransactions;
