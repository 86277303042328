import React from "react";
import {
	Redirect,
	Route,
	Router,
	Switch,
	useRouteMatch
} from "react-router-dom";
import { UserProvider } from "etegris/employee/user-context";
import { createBrowserHistory } from "history";
import { TransactionsProvider } from "./etegris/transactions/transactions-provider";
import TransactionsMonitor from "./etegris/transactions/TransactionsMonitor";
import { AccountsProvider } from "./etegris/AccountsProvider";
import { AddressBookProvider } from "./etegris/AddressBookProvider";
import { InvoiceCacheProvider } from "./etegris/InvoiceCacheProvider";
import PageExpense from "./PageExpense";
import PageDashboard from "./PageDashboard";
import PageTransactions from "./PageTransactions";
import PageAddTransaction from "./PageAddTransaction";
import PageJde from "./PageJde";
import PageBestPractice from "./bestPractice/PageBestPractice";
import PageJdeAttachment from "./viewjde/PageJdeAttachment";
import { getAddressBookJson } from "./etegrisService/s3Service";

const hist = createBrowserHistory();

function AppTW() {
	if (window.location.pathname.includes("/admin/viewjdeattach")) {
		return (
			<UserProvider>
				<PageJdeAttachment pathName={window.location.pathname} />
			</UserProvider>
		);
	} else
		return (
			<UserProvider>
				<AccountsProvider>
					<AddressBookProvider getAddressBookJson={getAddressBookJson}>
						<InvoiceCacheProvider>
							<TransactionsProvider>
								<TransactionsMonitor>
									<Router history={hist}>
										<Switch>
											<Route path="/admin" component={AdminRoutes} />
											{/*<Route path="/admin/expense" compenent={PageExpense} />*/}
											{/*<Route path="/expense" component={} />*/}
											<Redirect from="/" to="/admin/dashboard" />
										</Switch>
									</Router>
								</TransactionsMonitor>
							</TransactionsProvider>
						</InvoiceCacheProvider>
					</AddressBookProvider>
				</AccountsProvider>
			</UserProvider>
		);
}

function AdminRoutes() {
	let { path } = useRouteMatch();

	return (
		<Switch>
			<Route exact path={path}>
				<h3>Please select please choose something</h3>
			</Route>
			<Route path={`${path}/dashboard`}>
				<PageDashboard />
			</Route>
			<Route path={`${path}/transactions`}>
				<PageTransactions />
			</Route>
			<Route path={`${path}/add`}>
				<PageAddTransaction />
			</Route>
			<Route path={`${path}/expense`}>
				<PageExpense />
			</Route>
			<Route path={`${path}/jde`}>
				<PageJde />
			</Route>
			<Route path={`${path}/bp`}>
				<PageBestPractice />
			</Route>
		</Switch>
	);
}

export default AppTW;
