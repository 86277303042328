/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getTransaction = /* GraphQL */ `
  query GetTransaction($id: ID!) {
    getTransaction(id: $id) {
      id
      userReference
      meta
      schema
      title
      status
      owner2
      batch
      vendorNo
      vendor
      total
      gst
      net
      invoice
      invoiceDate
      multipleExpenses
      glDate
      description
      remark
      isBP
      ready
      readyDate
      approval1
      approval1Date
      approval2
      approval2Date
      json
      createdBy
      createdAt
      updatedAt
      groups
      attachments {
        items {
          id
          transactionId
          imagekey
          name
          createdAt
          meta
          date
          isExpense
          total
          gst
          net
          description
          invoice
          remark
          glDate
          isBP
          bpNumber
          groups
          updatedAt
        }
        nextToken
      }
      distributions {
        items {
          id
          transactionId
          accountNo
          account
          postingEdit
          subledgerType
          subledgerId
          subledgerName
          amount
          createdAt
          meta
          groups
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          transactionId
          userId
          username
          comment
          createdAt
          meta
          groups
          updatedAt
        }
        nextToken
      }
      actions {
        items {
          id
          transactionId
          actionType
          meta
          json
          createdAt
          groups
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listTransactions = /* GraphQL */ `
  query ListTransactions(
    $id: ID
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTransactions(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        userReference
        meta
        schema
        title
        status
        owner2
        batch
        vendorNo
        vendor
        total
        gst
        net
        invoice
        invoiceDate
        multipleExpenses
        glDate
        description
        remark
        isBP
        ready
        readyDate
        approval1
        approval1Date
        approval2
        approval2Date
        json
        createdBy
        createdAt
        updatedAt
        groups
        attachments {
          nextToken
        }
        distributions {
          nextToken
        }
        messages {
          nextToken
        }
        actions {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getHistory = /* GraphQL */ `
  query GetHistory($id: ID!) {
    getHistory(id: $id) {
      id
      transactionId
      user
      jsonDiff
      createdBy
      createdAt
      updatedAt
    }
  }
`;
export const listHistories = /* GraphQL */ `
  query ListHistories(
    $id: ID
    $filter: ModelHistoryFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listHistories(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        transactionId
        user
        jsonDiff
        createdBy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getStatus = /* GraphQL */ `
  query GetStatus($id: ID!) {
    getStatus(id: $id) {
      id
      transactionId
      status
      statusDate
      createdBy
      createdAt
      updatedAt
    }
  }
`;
export const listStatuses = /* GraphQL */ `
  query ListStatuses(
    $id: ID
    $filter: ModelStatusFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listStatuses(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        transactionId
        status
        statusDate
        createdBy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDistribution = /* GraphQL */ `
  query GetDistribution($id: ID!) {
    getDistribution(id: $id) {
      id
      transactionId
      accountNo
      account
      postingEdit
      subledgerType
      subledgerId
      subledgerName
      amount
      createdAt
      meta
      groups
      updatedAt
    }
  }
`;
export const listDistributions = /* GraphQL */ `
  query ListDistributions(
    $id: ID
    $filter: ModelDistributionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDistributions(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        transactionId
        accountNo
        account
        postingEdit
        subledgerType
        subledgerId
        subledgerName
        amount
        createdAt
        meta
        groups
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      id
      transactionId
      userId
      username
      comment
      createdAt
      meta
      groups
      updatedAt
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $id: ID
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMessages(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        transactionId
        userId
        username
        comment
        createdAt
        meta
        groups
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAttachment = /* GraphQL */ `
  query GetAttachment($id: ID!) {
    getAttachment(id: $id) {
      id
      transactionId
      imagekey
      name
      createdAt
      meta
      date
      isExpense
      total
      gst
      net
      description
      invoice
      remark
      glDate
      isBP
      bpNumber
      groups
      updatedAt
    }
  }
`;
export const listAttachments = /* GraphQL */ `
  query ListAttachments(
    $id: ID
    $filter: ModelAttachmentFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAttachments(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        transactionId
        imagekey
        name
        createdAt
        meta
        date
        isExpense
        total
        gst
        net
        description
        invoice
        remark
        glDate
        isBP
        bpNumber
        groups
        updatedAt
      }
      nextToken
    }
  }
`;
export const getInvoice = /* GraphQL */ `
  query GetInvoice($id: ID!) {
    getInvoice(id: $id) {
      id
      transactionId
      attachmentId
      invoice
      vendorNo
      createdAt
      updatedAt
    }
  }
`;
export const listInvoices = /* GraphQL */ `
  query ListInvoices(
    $id: ID
    $filter: ModelInvoiceFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listInvoices(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        transactionId
        attachmentId
        invoice
        vendorNo
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAction = /* GraphQL */ `
  query GetAction($id: ID!) {
    getAction(id: $id) {
      id
      transactionId
      actionType
      meta
      json
      createdAt
      groups
      updatedAt
    }
  }
`;
export const listActions = /* GraphQL */ `
  query ListActions(
    $id: ID
    $filter: ModelActionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listActions(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        transactionId
        actionType
        meta
        json
        createdAt
        groups
        updatedAt
      }
      nextToken
    }
  }
`;
export const getNotification = /* GraphQL */ `
  query GetNotification($id: ID!) {
    getNotification(id: $id) {
      id
      transactionId
      notificationType
      meta
      json
      createdAt
      groups
      updatedAt
    }
  }
`;
export const listNotifications = /* GraphQL */ `
  query ListNotifications(
    $id: ID
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listNotifications(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        transactionId
        notificationType
        meta
        json
        createdAt
        groups
        updatedAt
      }
      nextToken
    }
  }
`;
export const getNextNumbers = /* GraphQL */ `
  query GetNextNumbers($id: ID!) {
    getNextNumbers(id: $id) {
      id
      nextUserReferenceNumber
      createdAt
      updatedAt
    }
  }
`;
export const listNextNumbers = /* GraphQL */ `
  query ListNextNumbers(
    $id: ID
    $filter: ModelNextNumbersFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listNextNumbers(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        nextUserReferenceNumber
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const transactionsByStatus = /* GraphQL */ `
  query TransactionsByStatus(
    $status: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    transactionsByStatus(
      status: $status
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userReference
        meta
        schema
        title
        status
        owner2
        batch
        vendorNo
        vendor
        total
        gst
        net
        invoice
        invoiceDate
        multipleExpenses
        glDate
        description
        remark
        isBP
        ready
        readyDate
        approval1
        approval1Date
        approval2
        approval2Date
        json
        createdBy
        createdAt
        updatedAt
        groups
        attachments {
          nextToken
        }
        distributions {
          nextToken
        }
        messages {
          nextToken
        }
        actions {
          nextToken
        }
      }
      nextToken
    }
  }
`;
