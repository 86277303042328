import { useEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";
import * as editActions from "./editActions";
import * as subscriptions from "graphql/subscriptions";

const useMonitorEditTransaction = dispatch => {
	useEffect(() => {
		const subscriptionInsert = API.graphql(
			graphqlOperation(subscriptions.onCreateMessage)
		).subscribe({
			next: data => {
				const newDist = data?.value?.data?.onCreateMessage;
				if (newDist)
					dispatch({ type: editActions.insertMessage, payload: newDist });
			}
		});

		return () => {
			subscriptionInsert.unsubscribe();
		};
	}, []);

	return {};
};

export default useMonitorEditTransaction;
