import React, { useEffect, useState } from "react";
import { Hub } from "aws-amplify";
import * as service from "etegrisService";

// import LoadingUserView from "./LoadingUserView";

const UserStateContext = React.createContext();
const UserSetStateContext = React.createContext();

const UserProvider = ({ children, initialUser }) => {
	const initialState = initialUser || {
		user: {
			username: "not set",
			groups: [],
			currentGroups: []
		},
		allUsers: ["test1", "test2", "test3", "test4", "wknott"]
	};
	const [state, setState] = useState(initialState);

	const listener = async data => {
		switch (data.payload.event) {
			case "signIn":
				{
					console.log(
						"user-context.js signIn data.payload.event",
						data.payload
					);
					const user = await service.loadUser();
					setState(state => ({ ...state, user: user }));
				}
				break;
			case "signUp":
				console.log("user-context.js signUp data.payload.event", data.payload);
				break;
			case "signOut":
				console.log("user-context.js signOut data.payload.event", data.payload);
				setState(state => ({ ...state, user: null }));
				break;
			case "signIn_failure":
				console.log(
					"user-context.js signIn_failure data.payload.event",
					data.payload
				);
				break;
			case "configured":
				console.log(
					"user-context.js configured data.payload.event",
					data.payload
				);
		}
	};

	useEffect(() => {
		Hub.listen("auth", listener);

		return () => {
			Hub.remove("auth", listener);
		};
	}, []);

	useEffect(() => {
		const run = async () => {
			const user = await service.loadUser();

			setState(state => ({ ...state, user: user }));
		};

		run();
	}, []);

	if (state.user.username === "not set") {
		return <div>Username is not set</div>;
	}

	return (
		<UserStateContext.Provider value={state}>
			<UserSetStateContext.Provider value={setState}>
				{children}
			</UserSetStateContext.Provider>
		</UserStateContext.Provider>
	);
};

function useUserState() {
	const context = React.useContext(UserStateContext);
	if (context === undefined) {
		throw new Error("useUserState must be used within a UserProvider");
	}
	return context;
}

function useSetUserState() {
	const context = React.useContext(UserSetStateContext);
	if (context === undefined) {
		throw new Error("useSetUserState must be used within a UserProvider");
	}
	return context;
}

export { UserProvider, useUserState, useSetUserState };
