import React, { useState, useEffect } from "react";

import EtegrisTabs from "2-molecules/EtegrisTabs";

import AttachmentCommandsTW from "./AttachmentCommandsTW";
import AttachmentExpenseTailWind from "./AttachmentExpenseTailWind";
import { EtegrisImage2TW } from "./EtegrisImage2TW";

const Attachments = ({
	attachments,
	editTransaction,
	checkIfInvoiceExists,
	dispatch
}) => {
	const [tabs, setTabs] = useState([]);

	useEffect(() => {
		if (!attachments) {
			setTabs([]);
			return;
		}

		let count = 1;

		count = 1;
		const tabsHold = attachments.map(attachment => {
			return {
				tabName: `${count++}`,
				tabContent: (
					<>
						{
							<div className="flex sm:flex-col ">
								<AttachmentExpenseTailWind
									attachment={attachment}
									vendorNo={
										editTransaction.transaction
											? editTransaction.transaction.vendorNo
											: ""
									}
									validateExistanceOfInvoice={checkIfInvoiceExists}
									dispatch={dispatch}
								/>

								<div className="flex sm:flex-col">
									<EtegrisImage2TW
										key={attachment.id}
										title={attachment.name}
										url={attachment.imagekey}
									/>
									<AttachmentCommandsTW
										attachment={attachment}
										editTransaction={editTransaction}
										dispatch={dispatch}
									/>
								</div>
							</div>
						}
					</>
				)
			};
		});

		setTabs(tabsHold);
	}, [attachments]);

	if (!attachments || attachments.length === 0)
		return (
			<div>
				<div>No Attachments</div>

				<AttachmentCommandsTW
					editTransaction={editTransaction}
					dispatch={dispatch}
				/>
			</div>
		);

	return (
		<div className="p-3 border-gray-300 rounded-lg">
			<EtegrisTabs title="Attachments:" headerColor="danger" tabs={tabs} />
		</div>
	);
};

export default Attachments;
