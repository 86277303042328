import { PageSizes, PDFDocument, rgb, StandardFonts } from "pdf-lib";

const xOffSet = 72;
const yOffSet = 800;

const x1 = 0 + xOffSet;
const x2 = 50 + xOffSet;
const x4 = 280 + xOffSet;

const x5 = 380 + xOffSet;

const y1 = 0 + yOffSet;
const y2 = y1 - 20;
const y3 = y2 - 15;
const y4 = y3 - 15;
const y5 = y4 - 15;
const y6 = y5 - 15;
const y7 = y6 - 15;
const y8 = y7 - 15;
const y9 = y8 - 15;
const y10 = y9 - 15;
const y11 = y10 - 15;
const y12 = y11 - 15;
const y13 = y12 - 15;
const y14 = y13 - 15;
const y15 = y14 - 15;
const y16 = y15 - 15;
const y17 = y16 - 15;
const y18 = y17 - 15;
const y19 = y18 - 15;
const y20 = y19 - 15;
const y21 = y20 - 15;
const y22 = y21 - 15;
const y23 = y22 - 15;
const y24 = y23 - 15;
const y25 = y24 - 15;
const y26 = y25 - 15;
const y27 = y26 - 15;
const y28 = y27 - 15;
const y29 = y28 - 15;
const y30 = y29 - 15;
const y31 = y30 - 15;
const y32 = y31 - 15;
const y33 = y32 - 15;
const y34 = y33 - 15;
const y35 = y34 - 15;

const y36 = y35 - 15;
const y37 = y36 - 15;
const y38 = y37 - 45;
const y39 = y38 - 15;
const y40 = y39 - 15;
const y41 = y40 - 15;
const y42 = y41 - 15;
const y43 = y42 - 15;
const y44 = y43 - 15;

async function createChequeRequestWithApprovals(editTransaction) {
	const transaction = editTransaction.transaction;
	const distributions = editTransaction.distributions;

	const pdfDoc = await PDFDocument.create();

	// Add a blank page to the document
	const page = pdfDoc.addPage(PageSizes.Letter);
	// page.setSize(PageSizes.Letter[0], PageSizes.Letter[1]);

	// Embed the Helvetica font
	const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);

	// Get the width and height of the page

	// const {width, height} = page.getSize()

	function stampFieldFontSize(x, y, text, fontSize) {
		page.drawText(text, {
			x: x,
			y: y,
			size: fontSize,
			font: helveticaFont
			// color: rgb(0.1, 0.95, 0.1),
			//	rotate: degrees(-45),
		});
	}

	function stampField(x, y, text) {
		stampFieldFontSize(x, y, text, 10);
	}

	function stampField10(x, y, text) {
		stampFieldFontSize(x, y, text, 10);
	}

	// function stampField12(x, y, text) {
	// 	stampFieldFontSize(x, y, text, 10)
	// }

	stampFieldFontSize(x1 + 50, y1, "SQUAMISH NATION", 16);
	// doc.fontSize(16)
	// 	.text('Squamish Nation', x1 + 50, y1);

	stampFieldFontSize(x5 - 30, y1, "CHEQUE-REQUEST", 12);
	// .text(header.tranh_code.toUpperCase(), x5 - 30, y1)
	// .text(header.tranh_status.toUpperCase(), x4 - 30, y1);

	stampField10(x4, y4, "Transaction date");
	stampField10(x5, y4, transaction.invoiceDate);

	// Vendor
	stampField10(x1, y8, "Payable To");
	stampField10(x1, y10, transaction.vendor);
	stampField10(x4, y10, transaction.vendorNo);

	// description

	const lines = transaction.description.split("↵");

	stampField10(x1, y13, "Description");
	for (let i = 0; i < lines.length; i++) {
		const line = lines[i];
		stampField10(x2 - 35, y14 - 15 * i, line); //, x2 - 35, y14, { width: 235});
	}

	// total dollars
	stampField10(x4, y14, "Amount");

	stampField10(x5 - 10, y14, (transaction.total - transaction.gst).toString()); //, {width: 50, align: 'right' });
	stampField10(x4, y16, "GST");
	stampField10(x5 - 10, y16, transaction.gst.toString()); // {width: 50, align: 'right' });

	// doc.lineCap('round')
	// 	.moveTo(x5, y16 + 11)
	// 	.lineTo(x5 + 50, y16 + 11)
	// 	.stroke();
	stampField10(x4, y17, "Total");
	stampField10(x5 - 10, y17, transaction.total.toString()); //{width: 50, align: 'right' });

	// Details
	const colWidth1 = 200;
	// const colWidth2 = 60;
	const colWidth3 = 55;
	const colWidth4 = 12;
	const colWidth4a = 50;
	// const colWidth5 = 200;

	const xDet1 = x1;
	// const xDet2 = xDet1 + colWidth1;
	const xDet3 = xDet1 + colWidth1;
	const xDet4 = xDet3 + colWidth3;
	const xDet4a = xDet4 + colWidth4;
	const xDet5 = xDet4a + colWidth4a;
	// const xDet6 = xDet5 + colWidth5;

	const detailHeaderRow = y20;
	let detailRow = detailHeaderRow - 15;
	// let detailSum = 0;

	// stampField10(xDet1, detailHeaderRow, 'File #');
	stampField10(xDet1, detailHeaderRow, "G/L Code");
	stampField10(xDet3, detailHeaderRow, "Amount");
	stampField10(xDet4, detailHeaderRow, "");
	stampField10(xDet4a, detailHeaderRow, "SBL");
	stampField10(xDet5, detailHeaderRow, "Child");
	// stampField10(xDet6, detailHeaderRow, 'BP ID.');

	var b1Left = 60;
	var b1Lefta = 130;
	var b1Right = b1Left + 350;
	var b1Top = y8 + 4;

	var b2Left = 60;
	var b2Lefta = 130;
	var b2Right = b1Left + 470;
	var b2Top = y13 + 4;
	var b2Height = 75;

	// doc.lineCap('round')
	// 	.moveTo(b1Left, b1Top)
	// 	.lineTo(b1Left + 6, b1Top)
	// 	.stroke();

	const thickness = 2;
	const lineColor = rgb(0.75, 0.2, 0.2);

	page.drawLine({
		start: { x: b2Left, y: detailHeaderRow - 5 },
		end: { x: b2Right, y: detailHeaderRow - 5 },
		thickness: thickness,
		color: lineColor,
		opacity: 0.75
	});
	// doc.lineCap('round')
	// 	.moveTo(b2Left, detailHeaderRow + 11)
	// 	.lineTo(b2Right, detailHeaderRow + 11)
	// 	.stroke();

	// TopBOx
	page.drawLine({
		start: { x: b1Left, y: b1Top },
		end: { x: b1Left + 6, y: b1Top },
		thickness: thickness,
		color: lineColor,
		opacity: 0.75
	});
	page.drawLine({
		start: { x: b1Lefta, y: b1Top },
		end: { x: b1Right, y: b1Top },
		thickness: thickness,
		color: lineColor,
		opacity: 0.75
	});

	page.drawLine({
		start: { x: b1Left, y: b1Top },
		end: { x: b1Left, y: b1Top - 50 },
		thickness: thickness,
		color: lineColor,
		opacity: 0.75
	});
	page.drawLine({
		start: { x: b1Right, y: b1Top },
		end: { x: b1Right, y: b1Top - 50 },
		thickness: thickness,
		color: lineColor,
		opacity: 0.75
	});
	page.drawLine({
		start: { x: b1Left, y: b1Top - 50 },
		end: { x: b1Right, y: b1Top - 50 },
		thickness: thickness,
		color: lineColor,
		opacity: 0.75
	});

	// Description box

	page.drawLine({
		start: { x: b2Left, y: b2Top },
		end: { x: b2Left + 6, y: b2Top },
		thickness: thickness,
		color: lineColor,
		opacity: 0.75
	});

	page.drawLine({
		start: { x: b2Lefta, y: b2Top },
		end: { x: b2Right, y: b2Top },
		thickness: thickness,
		color: lineColor,
		opacity: 0.75
	});
	page.drawLine({
		start: { x: b2Left, y: b2Top },
		end: { x: b2Left, y: b2Top - b2Height },
		thickness: thickness,
		color: lineColor,
		opacity: 0.75
	});
	page.drawLine({
		start: { x: b2Right, y: b2Top },
		end: { x: b2Right, y: b2Top - b2Height },
		thickness: thickness,
		color: lineColor,
		opacity: 0.75
	});

	page.drawLine({
		start: { x: b2Left, y: b2Top - b2Height },
		end: { x: b2Right, y: b2Top - b2Height },
		thickness: thickness,
		color: lineColor,
		opacity: 0.75
	});

	for (let d = 0; d < distributions.length; d++) {
		const dist = distributions[d];
		stampField10(xDet1, detailRow, dist.account);

		stampField10(xDet3 - 10, detailRow, dist.amount.toString()); // {width: 50, align: 'right' });
		stampField10(xDet4, detailRow, "A");
		stampField10(xDet4a, detailRow, dist.subledgerId);
		stampField10(xDet5, detailRow, dist.subledgerName);
		// doc.text(xDet6, detailRow, header.tranh_id, );

		detailRow -= 30;
	}

	detailRow -= 60;

	stampField10(x1, y38, "Requested By: _________________________________");
	stampField10(x1 + 100, y38 + 5, transaction.owner2);
	stampField10(x4, y38, "Date: ______________");
	stampField10(x4 + 45, y38 + 5, transaction.createdAt);

	stampField10(x1, y40, "Manager Approval: _____________________________");
	stampField10(x4, y40, "Date: ______________");
	stampField10(x1, y42, "Director Approval: ____________________________");
	stampField10(x4, y42, "Date: ______________");

	/// Approvals
	// const dateX = 220;

	stampField(175, 180, transaction.approval1);
	stampField(390, 180, transaction.approval1Date);

	stampField(175, 152, transaction.approval2);
	stampField(390, 152, transaction.approval2Date);

	stampFieldFontSize(x1, y44, "Printed: " + new Date().toDateString(), 8);

	stampFieldFontSize(x5, y44, "Page: " + 1 + " of " + 1, 12);

	// Serialize the PDFDocument to bytes (a Uint8Array)
	const pdfBytes = await pdfDoc.save();

	return pdfBytes;
	// For example, `pdfBytes` can be:
	//   • Written to a file in Node
	//   • Downloaded from the browser
	//   • Rendered in an <iframe>
}

export default createChequeRequestWithApprovals;
