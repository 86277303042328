import PdfDocument from "./PdfDocument.mjs";

export default class PdfService {
	static create(array) {
		return new PdfService(PdfDocument.create(array));
	}

	static createNull(pages) {
		return new PdfService(PdfDocument.createNull(pages));
	}

	constructor(document) {
		this._document = document;
	}

	async parsePdf() {
		var fieldPages = await this._document.getDocumentTextFields();
		return this._document.parsePdf(fieldPages);
	}
}
