import React, { useState } from "react";
import PropTypes from "prop-types";
const ServiceContext = React.createContext();
const ServiceStateContext = React.createContext();
const ServiceSetStateContext = React.createContext();

function ServiceProvider({ children, service }) {
	const [state, setState] = useState({ saving: false });

	return (
		<ServiceContext.Provider value={service}>
			<ServiceStateContext.Provider value={state}>
				<ServiceSetStateContext.Provider value={setState}>
					{children}
				</ServiceSetStateContext.Provider>
			</ServiceStateContext.Provider>
		</ServiceContext.Provider>
	);
}

ServiceProvider.propTypes = {
	children: PropTypes.any.isRequired,
	service: PropTypes.object.isRequired
};

function useService() {
	const context = React.useContext(ServiceContext);
	if (context === undefined) {
		throw new Error("useService must be used within a ServiceProvider");
	}
	return context;
}

function useServiceState() {
	const context = React.useContext(ServiceStateContext);
	if (context === undefined) {
		throw new Error("useServiceState must be used within a ServiceProvider");
	}
	return context;
}

function useServiceSetState() {
	const context = React.useContext(ServiceSetStateContext);
	if (context === undefined) {
		throw new Error("useServiceSetState must be used within a ServiceProvider");
	}
	return context;
}

export { ServiceProvider, useService, useServiceState, useServiceSetState };
