import React, { useState } from "react";
import PropTypes from "prop-types";

import SelectAccount2 from "1-atoms/SelectAccount2";

import AddButton from "1-atoms/AddButton";
import Trash from "svg/Trash";
import InputField from "../../inputs/InputField";
import { useForm } from "react-form";
import SelectVendor2 from "../../inputs/SelectVendor2";
import TrashSmall from "../../../svg/TrashSmall";

function getVendorFromAn8(vendors, an8) {
	const ven = vendors.find(v => v.an8 === an8);
	if (ven) return ven;
	else return vendors[vendors.length - 1];
}

const Distribution = ({
	accounts,
	addressBooks,
	distribution,
	fieldUpdated,
	deleteDistribution
}) => {
	const [account, setAccount] = useState(
		distribution
			? {
					aid: distribution.accountNo,
					name: distribution.account
			  }
			: { accountNo: "", account: "" }
	);

	const [showButtons, setShowButtons] = useState(false);

	const [vendor] = useState(
		getVendorFromAn8(addressBooks, distribution.subledgerId)
	);

	const defaultValues = React.useMemo(
		() => ({
			amount: distribution.amount
		}),
		[distribution]
	);

	const {
		Form
		// values,
		// pushFieldValue,
		// removeFieldValue,
		// meta: {
		// 	isSubmitting,
		// 	isSubmitted,
		// 	canSubmit,
		// 	error
		// }
	} = useForm({
		defaultValues,
		onSubmit: async values => {
			await new Promise(resolve => setTimeout(resolve, 1000));
			console.log(values);
		},
		debugForm: false
	});

	const handleAccountChange = async newAccount => {
		const selected = newAccount
			? newAccount
			: {
					accountNo: "",
					account: ""
			  };

		if (distribution.accountNo !== selected.aid) {
			setAccount(selected);

			if (fieldUpdated) {
				const newAccountNo = {
					id: distribution.id,
					fieldName: "accountNo",
					value: selected.aid
				};
				const newAccountName = {
					id: distribution.id,
					fieldName: "account",
					value: selected.name
				};
				let fields = [newAccountNo, newAccountName];

				if (newAccount && newAccount.pec && newAccount.pec === "L") {
					fields.push({
						id: distribution.id,
						fieldName: "postingEdit",
						value: "L"
					});

					fields.push({
						id: distribution.id,
						fieldName: "subledgerType",
						value: "A"
					});
				}

				fieldUpdated(distribution, fields);
			}
		}
	};

	const handleChange = async fieldInfo => {
		if (!fieldInfo) return;

		if (!fieldInfo.fieldName) return;

		if (distribution[fieldInfo.fieldName] === fieldInfo.value) return;

		fieldUpdated(distribution, { id: distribution.id, ...fieldInfo });
	};

	const handleDelete = () => {
		if (deleteDistribution) deleteDistribution(distribution);
	};

	function handleSubDirectoryClicked() {
		const fieldInfo = {
			id: distribution.id,
			fieldName: "subledgerType",
			value: "A"
		};
		if (fieldUpdated) fieldUpdated(distribution, fieldInfo);
	}

	function handleSubledgerChanged(newSubledger) {
		const fields = [
			{
				id: distribution.id,
				fieldName: "subledgerId",
				value: newSubledger.an8
			},
			{
				id: distribution.id,
				fieldName: "subledgerName",
				value: newSubledger.name
			}
		];

		if (fieldUpdated) fieldUpdated(distribution, fields);
	}

	function handleSubLedgerDelete() {
		const fields = [
			{ id: distribution.id, fieldName: "subledgerType", value: "" },
			{ id: distribution.id, fieldName: "subledgerId", value: "" },
			{ id: distribution.id, fieldName: "subledgerName", value: "" }
		];

		if (fieldUpdated) fieldUpdated(distribution, fields);
	}

	if (!distribution) return <div>distribution is not set</div>;

	return (
		<Form>
			<div
				className="m-2 p-2 border-2 border-gray-200 rounded-md"
				onMouseEnter={() => {
					setShowButtons(true);
				}}
				onMouseLeave={() => {
					setShowButtons(false);
				}}
			>
				<div
					className={`flex flex-row absolute right-10 ${
						showButtons ? "" : "hidden"
					} `}
				>
					{!distribution.subledgerType && (
						<AddButton
							testid="add-subledger"
							onClick={handleSubDirectoryClicked}
						/>
					)}

					<button
						data-testid="delete-distribution"
						onClick={handleDelete}
						color="warning"
						className="w-6 h-6 rounded-md"
					>
						<Trash />
					</button>
				</div>

				<div className="flex flex-row items-end">
					<div className="w-3/4">
						<SelectAccount2
							id={`account-${distribution.id}`}
							label="Account *"
							accounts={accounts}
							initialAccount={account}
							onSelectChanged={handleAccountChange}
						/>
					</div>
					<div className="w-1/4 ml-2">
						<InputField
							field="amount"
							label="Net Amount *"
							id="amount"
							onBlur={event => {
								const val = event.target.value;
								const num = parseFloat(val);
								handleChange({
									fieldName: "amount",
									value: num
								});
							}}
						/>
					</div>
				</div>

				{distribution.subledgerType && (
					<div className="flex flex-row ml-6 items-center">
						<SelectVendor2
							label="Subledger"
							initialVendor={vendor}
							vendors={addressBooks}
							onSelectChanged={handleSubledgerChanged}
						/>

						<button
							className={`${
								showButtons ? "" : "hidden"
							} w-4 h-4 ml-2 mt-6 rounded-md `}
							data-testid="delete-subledger"
							onClick={handleSubLedgerDelete}
						>
							<TrashSmall />
						</button>
					</div>
				)}
			</div>
		</Form>
	);
};

Distribution.propTypes = {
	distribution: PropTypes.object.isRequired,
	accounts: PropTypes.array.isRequired,
	addressBooks: PropTypes.array.isRequired,
	fieldUpdated: PropTypes.func.isRequired
};

export default Distribution;
