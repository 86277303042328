import React, { useEffect, useState } from "react";

import * as service from "etegrisService";
import useUser from "etegris/employee/useUser";
import { useUserState } from "etegris/employee/user-context";
import { ServiceProvider } from "../etegrisService/ServiceProvider";
import { EditExpenseProvider } from "./transaction/EditExpenseContext";

import EditExpenseWithState3 from "./transaction/EditExpenseWithState3";
import ServiceDiv from "./ServiceDiv";
import EditExpenseMonitor from "./transaction/EditExpenseMonitor";

export default function ExpenseView({ id }) {
	const [editTran, setEditTran] = useState(null);

	const userState = useUserState();

	const { loadUser } = useUser();

	useEffect(() => {
		loadUser();
	}, []);

	// useEffect(() => {
	//
	// 	const run = async () => {
	// 		if (location.search) {
	// 			if (location.search.includes('?id=')) {
	// 				const id = location.search.replace('?id=', '');
	//
	// 				const eTran = await service.getEditTransaction(id);
	// 				setEditTran(eTran);
	// 			}
	// 		}
	// 	}
	// 	run();
	//
	// }, [location]);

	useEffect(() => {
		const run = async () => {
			if (id) {
				const eTran = await service.getEditTransaction(id);
				setEditTran(eTran);
			}
		};
		run();
	}, [id]);

	if (!id)
		return (
			<div>
				No transaction! Go to the Transactions page and select a transaction.
			</div>
		);

	if (!editTran) return <div>Transaction loading...</div>;

	return (
		<>
			<ServiceProvider service={service}>
				<EditExpenseProvider editTran={editTran}>
					<EditExpenseMonitor>
						<ServiceDiv />
						<EditExpenseWithState3 user={userState.user} />
					</EditExpenseMonitor>
				</EditExpenseProvider>
			</ServiceProvider>
		</>
	);
}
