import React from "react";

import Plus from "svg/Plus";

const AddButton = ({ onClick: onClick, testid }) => {
	function handleClick() {
		if (onClick) onClick();
	}

	return (
		// <Tooltip title={title} classes={{tooltip: classes.tooltip}}>
		<button
			className="w-9 h-9 mt-1 mr-2 bg-purple-600 shadow-lg rounded-full"
			data-testid={testid ? testid : "add-button"}
			onClick={handleClick}
		>
			<Plus />
		</button>
		// </Tooltip>
	);
};

export default AddButton;
