export const doesInvoiceExist = (invCache, vendorNo, invoice, attachmentId) => {
	const found = invCache.has(vendorNo);
	if (!found) return false;

	const vendor = invCache.get(vendorNo);

	if (false === vendor.has(invoice)) return false;

	if (false === vendor.has(invoice)) return false;

	let invoiceCache = vendor.get(invoice);
	if (invoiceCache.size === 0) return false;

	if (invoiceCache.size === 1) {
		const attach = invoiceCache.entries().next().value;
		if (attach[1].attachmentId === attachmentId) return false;
		else return `Invoice exists! ${attach[1].title}`;
	} else {
		return "Multiple Invoices Exist! Search for invoice in All Transactions";
	}
};

export function addAttachment(tran, attach, invCache) {
	const attachStub = { attachmentId: attach.id, title: tran.title };

	if (tran.status === "Deleted") return;

	if (!invCache.has(tran.vendorNo)) {
		invCache.set(tran.vendorNo, new Map());
	}

	let vendor = invCache.get(tran.vendorNo);

	if (!vendor.has(attach.invoice)) {
		vendor.set(attach.invoice, new Map());
	}

	const invoice = vendor.get(attach.invoice);

	invoice.set(attach.id, attachStub);
}

export const getInvoiceNumbersFromTransactions = transactions => {
	let invCache = new Map();

	for (const record of transactions) {
		if (!record.vendorNo) continue;

		const tran = record.json ? JSON.parse(record.json) : { attachments: [] };
		for (const attach of tran.attachments) {
			if (!attach.invoice) continue;

			addAttachment(tran, attach, invCache);
		}
	}
	return invCache;
};
