import { API, graphqlOperation } from "aws-amplify";
import * as mutations from "graphql/mutations.js";
import * as quires from "graphql/queries.js";
import * as subscriptions from "graphql/subscriptions";
import {
	filterSortExpensesDesc,
	getEditTransactionFromQuery
} from "./serviceEditTransaction";

export const createTransaction = async transaction => {
	const result = await API.graphql(
		graphqlOperation(mutations.createTransaction, { input: transaction })
	);

	return result.data.createTransaction;
};

export const getTransactions = async () => {
	let expenses = [];
	let nextToken = null;
	let variables = { limit: 500 };
	do {
		let data = await API.graphql(
			graphqlOperation(quires.listTransactions, variables)
		);
		expenses = expenses.concat(data.data.listTransactions.items);
		nextToken = data.data.listTransactions.nextToken;
		variables = { nextToken };
	} while (nextToken);

	return filterSortExpensesDesc(expenses);
};

export const getInvoices = async () => {
	let invoices = [];
	let nextToken = null;
	let variables = { limit: 500 };
	do {
		let data = await API.graphql(
			graphqlOperation(quires.listInvoices, variables)
		);
		invoices = invoices.concat(data.data.listInvoices.items);
		nextToken = data.data.listInvoices.nextToken;
		variables = { nextToken };
	} while (nextToken);

	return invoices;
};

export function getTransactionFromRaw(tran) {
	return {
		...tran,
		payload: { ...JSON.parse(tran.json), createdAt: tran.createdAt }
	};
}

export const getEditTransaction = async idParam => {
	try {
		const queryResult = await API.graphql(
			graphqlOperation(quires.getTransaction, { id: idParam })
		);

		let tran = queryResult.data.getTransaction;

		console.log(
			"serviceGraphql.js getEditTransaction queryResult",
			queryResult
		);

		const editTran = getEditTransactionFromQuery(tran);

		console.log("serviceGraphql.js getEditTransaction editTran", editTran);

		return editTran;
	} catch (e) {
		console.log("serviceGraphql.js getTransaction error catch e", e);
	}
};

export const saveAction = async action => {
	const createResult = await API.graphql(
		graphqlOperation(mutations.createAction, { input: action })
	);
	const dataResult = createResult.data.createAction;

	return dataResult;
};

export const createAttachment = async newAttachment => {
	const createResult = await API.graphql(
		graphqlOperation(mutations.createAttachment, { input: newAttachment })
	);
	const dataResult = createResult.data.createAttachment;

	return dataResult;
};

export const saveHistory = async (transactionId, user, diff) => {
	const input = {
		transactionId: transactionId,
		user: user,
		jsonDiff: diff
	};

	const createResult = await API.graphql(
		graphqlOperation(mutations.createHistory, { input: input })
	);
	const dataResult = createResult.data.createAction;

	return dataResult;
};

export const getHistoryForTransactionId = async transactionId => {
	let variables = {
		filter: {
			transactionId: {
				eq: transactionId
			}
		},
		limit: 100
	};

	let history = [];
	let nextToken = null;
	do {
		let data = await API.graphql(
			graphqlOperation(quires.listHistories, variables)
		);

		history = history.concat(data.data.listHistories.items);
		nextToken = data.data.listHistories.nextToken;
		variables = { nextToken };
	} while (nextToken);

	return history;
};

export const getSubscriptionToActions = onNewAction => {
	return API.graphql(graphqlOperation(subscriptions.onCreateAction)).subscribe({
		next: actionData => {
			if (actionData.value.data.onCreateAction) {
				const rawAction = actionData.value.data.onCreateAction;
				const action = getTransactionFromRaw(rawAction);
				if (onNewAction) onNewAction(action);
			} else {
				console.log("discarding action", actionData);
			}
		}
	});
};
