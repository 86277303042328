import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import {
	DefaultCell,
	DateTimeCell,
	NumberCell,
	SelectColumnFilter,
	Table
} from "4-cards/Table";

import Pencil from "svg/Pencil";

function TransactionLink({ row }) {
	return (
		<div className="h-full">
			<Link
				to={{
					pathname: "/admin/expense",
					search: `?id=${row.original.id}`,
					state: { fromTransactions: true }
				}}
			>
				<div className="h-full flex items-center">
					<Pencil />
				</div>
			</Link>
		</div>
	);
}

export const TransactionTable = ({
	transactions,
	isAccounting,
	globalFilter,
	filters,
	dispatch
}) => {
	const columns = useMemo(
		() => [
			{
				Header: "Row",
				accessor: (row, i) => i + 1,
				width: 50,
				Cell: DefaultCell
			},
			{
				Header: "",
				id: "link",
				width: 45,
				Cell: TransactionLink
			},
			{
				Header: "Status",
				accessor: "status",
				Filter: SelectColumnFilter,
				filter: "equals",
				Cell: DefaultCell
			},
			{
				Header: "Title",
				accessor: "title",
				Cell: DefaultCell
			},
			// {
			// 	Header: "Description",
			// 	accessor: "description"
			// },
			{
				Header: "Created",
				accessor: "createdAt",
				Cell: DateTimeCell,
				width: 125
			},
			{
				Header: "Vendor",
				accessor: "vendor",
				Cell: DefaultCell
			},
			{
				Header: "Total",
				accessor: "total",
				width: 75,
				Cell: NumberCell
			},
			{
				Header: "Invoice",
				accessor: "invoice",
				sortable: false,
				Cell: DefaultCell
			},
			{
				Header: "Remark",
				accessor: "remark",
				sortable: false,
				Cell: DefaultCell
			}
			// {
			// 	Header: "Id",
			// 	accessor: "id",
			// 	sortable: false,
			// 	Cell: MoreInfoCell
			// }
		],
		[]
	);

	function dispatchFiltersChanged(newFilters) {
		dispatch({ type: "setFilters", filters: newFilters });
	}
	function dispatchGlobalFilterChanged(newFilter) {
		dispatch({ type: "setGlobalFilter", globalFilter: newFilter });
	}
	const hiddenColumns = ["id", "batch", "invoice", "remark"];

	return (
		<Table
			columns={columns}
			data={transactions}
			hiddenColumns={hiddenColumns}
			showHiddenColumns={isAccounting}
			globalFilter={globalFilter}
			filters={filters}
			dispatchFiltersChanged={dispatchFiltersChanged}
			dispatchGlobalFilterChanged={dispatchGlobalFilterChanged}
		/>
	);
};

TransactionTable.propTypes = {
	transactions: PropTypes.array.isRequired,
	isAccounting: PropTypes.bool.isRequired,
	globalFilter: PropTypes.any,
	filters: PropTypes.any,
	dispatch: PropTypes.func.isRequired
};
