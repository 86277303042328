import React from "react";
import AdminTW from "../AdminTW";
import BestPracticeView from "./BestPracticeView";

const PageBestPractice = () => {
	return (
		<AdminTW>
			<BestPracticeView />
		</AdminTW>
	);
};

export default PageBestPractice;
