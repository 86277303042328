import React from "react";
import { useLocation } from "react-router-dom";
import AdminTW from "./AdminTW";
import ExpenseView from "./etegris/ExpenseView";

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
	return new URLSearchParams(useLocation().search);
}

const PageExpense = () => {
	const query = useQuery();

	const id = query.get("id");

	console.log("PageExpense.js PageExpense id", id);

	return (
		<AdminTW>
			<ExpenseView id={id} />
		</AdminTW>
	);
};

export default PageExpense;
