import React from "react";
import AdminTW from "./AdminTW";
import AddTransactionView from "./etegris/AddTransactionView";

const PageDashboard = () => {
	return (
		<AdminTW>
			<AddTransactionView />
		</AdminTW>
	);
};

export default PageDashboard;
