/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createTransaction = /* GraphQL */ `
  mutation CreateTransaction(
    $input: CreateTransactionInput!
    $condition: ModelTransactionConditionInput
  ) {
    createTransaction(input: $input, condition: $condition) {
      id
      userReference
      meta
      schema
      title
      status
      owner2
      batch
      vendorNo
      vendor
      total
      gst
      net
      invoice
      invoiceDate
      multipleExpenses
      glDate
      description
      remark
      isBP
      ready
      readyDate
      approval1
      approval1Date
      approval2
      approval2Date
      json
      createdBy
      createdAt
      updatedAt
      groups
      attachments {
        items {
          id
          transactionId
          imagekey
          name
          createdAt
          meta
          date
          isExpense
          total
          gst
          net
          description
          invoice
          remark
          glDate
          isBP
          bpNumber
          groups
          updatedAt
        }
        nextToken
      }
      distributions {
        items {
          id
          transactionId
          accountNo
          account
          postingEdit
          subledgerType
          subledgerId
          subledgerName
          amount
          createdAt
          meta
          groups
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          transactionId
          userId
          username
          comment
          createdAt
          meta
          groups
          updatedAt
        }
        nextToken
      }
      actions {
        items {
          id
          transactionId
          actionType
          meta
          json
          createdAt
          groups
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateTransaction = /* GraphQL */ `
  mutation UpdateTransaction(
    $input: UpdateTransactionInput!
    $condition: ModelTransactionConditionInput
  ) {
    updateTransaction(input: $input, condition: $condition) {
      id
      userReference
      meta
      schema
      title
      status
      owner2
      batch
      vendorNo
      vendor
      total
      gst
      net
      invoice
      invoiceDate
      multipleExpenses
      glDate
      description
      remark
      isBP
      ready
      readyDate
      approval1
      approval1Date
      approval2
      approval2Date
      json
      createdBy
      createdAt
      updatedAt
      groups
      attachments {
        items {
          id
          transactionId
          imagekey
          name
          createdAt
          meta
          date
          isExpense
          total
          gst
          net
          description
          invoice
          remark
          glDate
          isBP
          bpNumber
          groups
          updatedAt
        }
        nextToken
      }
      distributions {
        items {
          id
          transactionId
          accountNo
          account
          postingEdit
          subledgerType
          subledgerId
          subledgerName
          amount
          createdAt
          meta
          groups
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          transactionId
          userId
          username
          comment
          createdAt
          meta
          groups
          updatedAt
        }
        nextToken
      }
      actions {
        items {
          id
          transactionId
          actionType
          meta
          json
          createdAt
          groups
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteTransaction = /* GraphQL */ `
  mutation DeleteTransaction(
    $input: DeleteTransactionInput!
    $condition: ModelTransactionConditionInput
  ) {
    deleteTransaction(input: $input, condition: $condition) {
      id
      userReference
      meta
      schema
      title
      status
      owner2
      batch
      vendorNo
      vendor
      total
      gst
      net
      invoice
      invoiceDate
      multipleExpenses
      glDate
      description
      remark
      isBP
      ready
      readyDate
      approval1
      approval1Date
      approval2
      approval2Date
      json
      createdBy
      createdAt
      updatedAt
      groups
      attachments {
        items {
          id
          transactionId
          imagekey
          name
          createdAt
          meta
          date
          isExpense
          total
          gst
          net
          description
          invoice
          remark
          glDate
          isBP
          bpNumber
          groups
          updatedAt
        }
        nextToken
      }
      distributions {
        items {
          id
          transactionId
          accountNo
          account
          postingEdit
          subledgerType
          subledgerId
          subledgerName
          amount
          createdAt
          meta
          groups
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          transactionId
          userId
          username
          comment
          createdAt
          meta
          groups
          updatedAt
        }
        nextToken
      }
      actions {
        items {
          id
          transactionId
          actionType
          meta
          json
          createdAt
          groups
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createHistory = /* GraphQL */ `
  mutation CreateHistory(
    $input: CreateHistoryInput!
    $condition: ModelHistoryConditionInput
  ) {
    createHistory(input: $input, condition: $condition) {
      id
      transactionId
      user
      jsonDiff
      createdBy
      createdAt
      updatedAt
    }
  }
`;
export const updateHistory = /* GraphQL */ `
  mutation UpdateHistory(
    $input: UpdateHistoryInput!
    $condition: ModelHistoryConditionInput
  ) {
    updateHistory(input: $input, condition: $condition) {
      id
      transactionId
      user
      jsonDiff
      createdBy
      createdAt
      updatedAt
    }
  }
`;
export const deleteHistory = /* GraphQL */ `
  mutation DeleteHistory(
    $input: DeleteHistoryInput!
    $condition: ModelHistoryConditionInput
  ) {
    deleteHistory(input: $input, condition: $condition) {
      id
      transactionId
      user
      jsonDiff
      createdBy
      createdAt
      updatedAt
    }
  }
`;
export const createStatus = /* GraphQL */ `
  mutation CreateStatus(
    $input: CreateStatusInput!
    $condition: ModelStatusConditionInput
  ) {
    createStatus(input: $input, condition: $condition) {
      id
      transactionId
      status
      statusDate
      createdBy
      createdAt
      updatedAt
    }
  }
`;
export const updateStatus = /* GraphQL */ `
  mutation UpdateStatus(
    $input: UpdateStatusInput!
    $condition: ModelStatusConditionInput
  ) {
    updateStatus(input: $input, condition: $condition) {
      id
      transactionId
      status
      statusDate
      createdBy
      createdAt
      updatedAt
    }
  }
`;
export const deleteStatus = /* GraphQL */ `
  mutation DeleteStatus(
    $input: DeleteStatusInput!
    $condition: ModelStatusConditionInput
  ) {
    deleteStatus(input: $input, condition: $condition) {
      id
      transactionId
      status
      statusDate
      createdBy
      createdAt
      updatedAt
    }
  }
`;
export const createDistribution = /* GraphQL */ `
  mutation CreateDistribution(
    $input: CreateDistributionInput!
    $condition: ModelDistributionConditionInput
  ) {
    createDistribution(input: $input, condition: $condition) {
      id
      transactionId
      accountNo
      account
      postingEdit
      subledgerType
      subledgerId
      subledgerName
      amount
      createdAt
      meta
      groups
      updatedAt
    }
  }
`;
export const updateDistribution = /* GraphQL */ `
  mutation UpdateDistribution(
    $input: UpdateDistributionInput!
    $condition: ModelDistributionConditionInput
  ) {
    updateDistribution(input: $input, condition: $condition) {
      id
      transactionId
      accountNo
      account
      postingEdit
      subledgerType
      subledgerId
      subledgerName
      amount
      createdAt
      meta
      groups
      updatedAt
    }
  }
`;
export const deleteDistribution = /* GraphQL */ `
  mutation DeleteDistribution(
    $input: DeleteDistributionInput!
    $condition: ModelDistributionConditionInput
  ) {
    deleteDistribution(input: $input, condition: $condition) {
      id
      transactionId
      accountNo
      account
      postingEdit
      subledgerType
      subledgerId
      subledgerName
      amount
      createdAt
      meta
      groups
      updatedAt
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $input: CreateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    createMessage(input: $input, condition: $condition) {
      id
      transactionId
      userId
      username
      comment
      createdAt
      meta
      groups
      updatedAt
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage(
    $input: UpdateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    updateMessage(input: $input, condition: $condition) {
      id
      transactionId
      userId
      username
      comment
      createdAt
      meta
      groups
      updatedAt
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage(
    $input: DeleteMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    deleteMessage(input: $input, condition: $condition) {
      id
      transactionId
      userId
      username
      comment
      createdAt
      meta
      groups
      updatedAt
    }
  }
`;
export const createAttachment = /* GraphQL */ `
  mutation CreateAttachment(
    $input: CreateAttachmentInput!
    $condition: ModelAttachmentConditionInput
  ) {
    createAttachment(input: $input, condition: $condition) {
      id
      transactionId
      imagekey
      name
      createdAt
      meta
      date
      isExpense
      total
      gst
      net
      description
      invoice
      remark
      glDate
      isBP
      bpNumber
      groups
      updatedAt
    }
  }
`;
export const updateAttachment = /* GraphQL */ `
  mutation UpdateAttachment(
    $input: UpdateAttachmentInput!
    $condition: ModelAttachmentConditionInput
  ) {
    updateAttachment(input: $input, condition: $condition) {
      id
      transactionId
      imagekey
      name
      createdAt
      meta
      date
      isExpense
      total
      gst
      net
      description
      invoice
      remark
      glDate
      isBP
      bpNumber
      groups
      updatedAt
    }
  }
`;
export const deleteAttachment = /* GraphQL */ `
  mutation DeleteAttachment(
    $input: DeleteAttachmentInput!
    $condition: ModelAttachmentConditionInput
  ) {
    deleteAttachment(input: $input, condition: $condition) {
      id
      transactionId
      imagekey
      name
      createdAt
      meta
      date
      isExpense
      total
      gst
      net
      description
      invoice
      remark
      glDate
      isBP
      bpNumber
      groups
      updatedAt
    }
  }
`;
export const createInvoice = /* GraphQL */ `
  mutation CreateInvoice(
    $input: CreateInvoiceInput!
    $condition: ModelInvoiceConditionInput
  ) {
    createInvoice(input: $input, condition: $condition) {
      id
      transactionId
      attachmentId
      invoice
      vendorNo
      createdAt
      updatedAt
    }
  }
`;
export const updateInvoice = /* GraphQL */ `
  mutation UpdateInvoice(
    $input: UpdateInvoiceInput!
    $condition: ModelInvoiceConditionInput
  ) {
    updateInvoice(input: $input, condition: $condition) {
      id
      transactionId
      attachmentId
      invoice
      vendorNo
      createdAt
      updatedAt
    }
  }
`;
export const deleteInvoice = /* GraphQL */ `
  mutation DeleteInvoice(
    $input: DeleteInvoiceInput!
    $condition: ModelInvoiceConditionInput
  ) {
    deleteInvoice(input: $input, condition: $condition) {
      id
      transactionId
      attachmentId
      invoice
      vendorNo
      createdAt
      updatedAt
    }
  }
`;
export const createAction = /* GraphQL */ `
  mutation CreateAction(
    $input: CreateActionInput!
    $condition: ModelActionConditionInput
  ) {
    createAction(input: $input, condition: $condition) {
      id
      transactionId
      actionType
      meta
      json
      createdAt
      groups
      updatedAt
    }
  }
`;
export const updateAction = /* GraphQL */ `
  mutation UpdateAction(
    $input: UpdateActionInput!
    $condition: ModelActionConditionInput
  ) {
    updateAction(input: $input, condition: $condition) {
      id
      transactionId
      actionType
      meta
      json
      createdAt
      groups
      updatedAt
    }
  }
`;
export const deleteAction = /* GraphQL */ `
  mutation DeleteAction(
    $input: DeleteActionInput!
    $condition: ModelActionConditionInput
  ) {
    deleteAction(input: $input, condition: $condition) {
      id
      transactionId
      actionType
      meta
      json
      createdAt
      groups
      updatedAt
    }
  }
`;
export const createNotification = /* GraphQL */ `
  mutation CreateNotification(
    $input: CreateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    createNotification(input: $input, condition: $condition) {
      id
      transactionId
      notificationType
      meta
      json
      createdAt
      groups
      updatedAt
    }
  }
`;
export const updateNotification = /* GraphQL */ `
  mutation UpdateNotification(
    $input: UpdateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    updateNotification(input: $input, condition: $condition) {
      id
      transactionId
      notificationType
      meta
      json
      createdAt
      groups
      updatedAt
    }
  }
`;
export const deleteNotification = /* GraphQL */ `
  mutation DeleteNotification(
    $input: DeleteNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    deleteNotification(input: $input, condition: $condition) {
      id
      transactionId
      notificationType
      meta
      json
      createdAt
      groups
      updatedAt
    }
  }
`;
export const createNextNumbers = /* GraphQL */ `
  mutation CreateNextNumbers(
    $input: CreateNextNumbersInput!
    $condition: ModelNextNumbersConditionInput
  ) {
    createNextNumbers(input: $input, condition: $condition) {
      id
      nextUserReferenceNumber
      createdAt
      updatedAt
    }
  }
`;
export const updateNextNumbers = /* GraphQL */ `
  mutation UpdateNextNumbers(
    $input: UpdateNextNumbersInput!
    $condition: ModelNextNumbersConditionInput
  ) {
    updateNextNumbers(input: $input, condition: $condition) {
      id
      nextUserReferenceNumber
      createdAt
      updatedAt
    }
  }
`;
export const deleteNextNumbers = /* GraphQL */ `
  mutation DeleteNextNumbers(
    $input: DeleteNextNumbersInput!
    $condition: ModelNextNumbersConditionInput
  ) {
    deleteNextNumbers(input: $input, condition: $condition) {
      id
      nextUserReferenceNumber
      createdAt
      updatedAt
    }
  }
`;
