import { useCallback, useState } from "react";

import testData from "./testData.json";

const useBestPractice = () => {
	const [loadingTransactions, setLoadingTransactions] = useState(false);
	const [allTransactions, setAllTransactions] = useState([]);
	const [transactionCount, setTransactionCount] = useState(0);
	const [error, setError] = useState("");

	const load = useCallback(async () => {
		setLoadingTransactions(true);
		setError("");

		try {
			console.log("useBestPractice.js load testData", testData);

			// console.log('useBestPractice.js load data', data);
			// const allData = JSON.parse(data.json);
			// console.log('useBestPractice.js load transactions', allData);
			//
			setTransactionCount(testData.transactions.length);
			setAllTransactions(testData.transactions);

			// const exports = await getExportsQL(transQL2);
			//
			// console.log(exports);
			// setImportLineCount(exports.length);
			//
			// const errorTrans = exports.filter(exp=> exp.error);
			// setErrorCount(errorTrans.length);
			//
			// setAllTransactions(exports);
		} catch (e) {
			const message = "error in fetching Best Practice transactions e\n" + e;
			console.log(message, e);
			setError(message);
		}

		setLoadingTransactions(false);
	}, []);

	const load2 = useCallback(async () => {
		setLoadingTransactions(true);
		setError("");

		try {
			const response = await fetch("https://jde.squamish.net/bestpractice");
			// const response = await fetch(
			// 	"https://192.168.200.117:44307/bestpractice"
			// );
			const transactions = await response.json();

			console.log("useBestPractice.js load data", transactions);

			setTransactionCount(transactions.length);
			setAllTransactions(transactions);

			// const exports = await getExportsQL(transQL2);
			//
			// console.log(exports);
			// setImportLineCount(exports.length);
			//
			// const errorTrans = exports.filter(exp=> exp.error);
			// setErrorCount(errorTrans.length);
			//
			// setAllTransactions(exports);
		} catch (e) {
			const message = "error in fetching Best Practice transactions e\n" + e;
			console.log(message, e);
			setError(message);
		}

		setLoadingTransactions(false);
	}, []);

	return {
		loadingTransactions,
		allTransactions,
		transactionCount,
		error,
		load,
		load2
	};
};

export default useBestPractice;
