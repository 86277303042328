import { useEffect } from "react";
import { API, Auth, graphqlOperation } from "aws-amplify";
import * as subscriptions from "graphql/subscriptions";
import awsConfig from "aws-exports";

import useTransactions from "./useTransactions";

import * as actions from "./actions";

export const useMonitorTransactions = dispatch => {
	const { refreshTransactions } = useTransactions();

	useEffect(() => {
		refreshTransactions();
	}, []);

	useEffect(() => {
		// Subscribe to creation of Todo

		API.configure(awsConfig);
		Auth.configure(awsConfig);

		const subscription = API.graphql(
			graphqlOperation(subscriptions.onCreateTransaction)
		).subscribe({
			next: data => {
				const newTran = data?.value?.data?.onCreateTransaction;
				if (newTran) dispatch({ type: actions.insert, payload: newTran });
			}
		});

		return () => {
			subscription.unsubscribe();
		};
	}, []);

	useEffect(() => {
		const subscription = API.graphql(
			graphqlOperation(subscriptions.onUpdateTransaction)
		).subscribe({
			next: data => {
				const updateTran = data?.value?.data?.onUpdateTransaction;
				if (updateTran)
					dispatch({ type: actions.replace, payload: updateTran });
			}
		});

		return () => {
			subscription.unsubscribe();
		};
	}, []);

	return {};
};
