import React, { useState, useEffect } from "react";

const PageJdeAttachment = ({ pathName }) => {
	const [preFetchUrl, setPreFetch] = useState(null);

	useEffect(() => {
		const key = pathName
			.replace("/admin/viewjdeattach/", "")
			.replace("%20", " "); //'9a935a15-c800-4006-b803-25ec0ac5ae91_10060109.pdf';

		if (key === "[object%20Object]") return;

		let run = async () => {
			console.log("PageJdeAttachment.js -------- key", key);

			const result = await getPreSignedUrl(key);

			setPreFetch(result.url);
		};

		run();
	}, [pathName]);

	if (!preFetchUrl) return <div>preFetchUrl not set</div>;

	return <iframe src={preFetchUrl} width="100%" height="600px" />;
};
// import JdeAttachmentView from "./JdeAttachmentView";

import { getPreSignedUrl } from "../etegrisService/s3Service";

export default PageJdeAttachment;
