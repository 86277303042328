import React from "react";
import useMonitorEditTransaction from "./useMonitorEditTransaction";
import { useEditExpenseReducer } from "./EditExpenseContext";

export default function EditExpenseMonitor({ children }) {
	const dispatch = useEditExpenseReducer();

	useMonitorEditTransaction(dispatch);

	return <>{children}</>;
}
