import { useEffect, useCallback } from "react";
import { Auth } from "aws-amplify";

import { useUserState, useSetUserState } from "./user-context";

export const useUser = () => {
	const setState = useSetUserState();
	const userState = useUserState();

	useEffect(() => {
		const run = async () => {
			await loadUser();
		};
		run();
	}, []);

	const loadUser = useCallback(async () => {
		const user = await Auth.currentUserInfo();
		const user2 = await Auth.currentAuthenticatedUser();

		const groups =
			user2.signInUserSession.accessToken.payload["cognito:groups"];

		const isCfs = !!groups.includes("Cfs");
		const isAdmin = !!groups.includes("CfsAdmin");
		const isCfsAdmin = !!groups.includes("CfsAdmin");
		const isApprover = !!groups.includes("CfsApprover");

		const isAccounting = !!groups.includes("Accounting");
		const isJdeImport = !!groups.includes("JdeImport");

		const homeGroup = isCfs ? "Cfs" : "Finance";

		const user3 = {
			...user,
			homeGroup,
			isAdmin,
			isCfsAdmin,
			isApprover,
			isAccounting,
			isJdeImport,
			groups,
			currentGroups: groups.slice()
		};
		setState(state => ({ ...state, user: user3 }));
	}, []);

	function clearUser() {
		setState(state => ({ ...state, user: null }));
	}

	return {
		userState,
		loadUser,
		clearUser
	};
};

export default useUser;
