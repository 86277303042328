import React, { useRef, useCallback, useState } from "react";
import { useCombobox } from "downshift";
import { useVirtual } from "react-virtual";

// const menuStyles = {
// 	maxHeight: 250,
// 	maxWidth: 400,
// 	overflowY: 'scroll',
// 	backgroundColor: '#eee',
// 	padding: 0,
// 	listStyle: 'none',
// 	position: 'relative',
// }

function getInitialOrNull(vendors, initialVendor) {
	return initialVendor ? initialVendor : vendors[vendors.length - 1];
}

function getItemToString(item) {
	if (!item) return "";

	if (item.an8 === "0") return "";

	return `${item.name} (${item.an8})`;
}

const SelectVendor2 = ({ label, vendors, initialVendor, onSelectChanged }) => {
	const [inputValue, setInputValue] = React.useState("");
	const [currentVendor, setCurrentVendor] = React.useState(
		getInitialOrNull(vendors, initialVendor)
	);

	const [showButtons, setShowButtons] = useState(false);

	function getItems(search) {
		return vendors.filter(n => n.searchField.includes(search));
	}

	const items = getItems(inputValue);

	const listRef = useRef();
	const rowVirtualizer = useVirtual({
		size: items.length,
		parentRef: listRef,
		estimateSize: useCallback(() => 30, []),
		overscan: 2
	});

	const {
		getInputProps,
		getToggleButtonProps,
		getItemProps,
		getLabelProps,
		getMenuProps,
		highlightedIndex,
		getComboboxProps,
		selectItem,
		isOpen
	} = useCombobox({
		items,
		selectedItem: currentVendor,
		inputValue,
		itemToString: getItemToString,
		onInputValueChange: ({ inputValue: newValue }) => {
			setInputValue(newValue);
		},
		onSelectedItemChange: ({ selectedItem }) => {
			setCurrentVendor(selectedItem);

			const sendItem = selectedItem
				? selectedItem
				: vendors[vendors.length - 1];
			if (onSelectChanged) onSelectChanged(sendItem);
		},
		scrollIntoView: () => {},
		onHighlightedIndexChange: ({ highlightedIndex }) =>
			rowVirtualizer.scrollToIndex(highlightedIndex)
	});

	return (
		<div
			className="relative inline-block text-left w-full"
			onMouseEnter={() => {
				setShowButtons(true);
			}}
			onMouseLeave={() => {
				setShowButtons(false);
			}}
		>
			<div className="block mt-3 font-medium leading-5 text-gray-700">
				<label
					{...getLabelProps()}
					className="text-sm text-gray-500 font-medium"
				>
					{label}
				</label>
				<div
					{...getComboboxProps()}
					className="mt-1 relative flex flex-row border-2 border-gray-200 rounded-md shadow-sm"
				>
					<input
						{...getInputProps({ type: "text" })}
						className="border-0 flex-grow rounded-md"
					/>
					<button
						className={`${
							showButtons ? "inline-flex" : "hidden"
						} justify-center w-5 ml-1 rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500`}
						tabIndex={-1}
						onClick={() => {
							selectItem(null);
						}}
						aria-label="clear selection"
					>
						&#215;
					</button>

					<button
						className={`${
							showButtons ? "inline-flex" : "hidden"
						} justify-center w-5 rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500`}
						type="button"
						{...getToggleButtonProps()}
						aria-label="toggle menu"
					>
						&#8595;
					</button>
				</div>
			</div>

			<ul
				{...getMenuProps({ ref: listRef })}
				className="origin-top-left z-50 max-h-40 absolute left-0  w-full overflow-auto border-2 border-gray-200 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
			>
				{isOpen && (
					<>
						<li key="total-size" style={{ height: rowVirtualizer.totalSize }} />
						{rowVirtualizer.virtualItems.map(virtualRow => (
							<li
								key={items[virtualRow.index].an8}
								{...getItemProps({
									index: virtualRow.index,
									item: items[virtualRow.index],
									style: {
										backgroundColor:
											highlightedIndex === virtualRow.index
												? "lightgray"
												: "inherit",
										fontWeight:
											currentVendor &&
											currentVendor.an8 === items[virtualRow.index].an8
												? "bold"
												: "normal",
										position: "absolute",
										top: 0,
										// left: 0,
										width: "100%",
										height: virtualRow.size,
										transform: `translateY(${virtualRow.start}px)`
									}
								})}
							>
								{`${items[virtualRow.index].name} (${items[virtualRow.index].an8})`}
							</li>
						))}
					</>
				)}
			</ul>
		</div>
	);
};

export default SelectVendor2;
