import React from "react";
import ReactDOM from "react-dom";
import Amplify from "aws-amplify";
import { withAuthenticator } from "@aws-amplify/ui-react";
import { AmazonAIPredictionsProvider } from "@aws-amplify/predictions";
import "@aws-amplify/ui-react/styles.css";
import "./index.css";
import "react-datetime/css/react-datetime.css";

import awsExports from "./aws-exports";

import AppTW from "./AppTW";

Amplify.configure(awsExports);

Amplify.addPluggable(new AmazonAIPredictionsProvider());

const NewApp = withAuthenticator(AppTW);

ReactDOM.render(<NewApp />, document.getElementById("root"));
