import React from "react";

const CardWithFancyHeader = ({ children, title }) => {
	return (
		<>
			<div className="mt-5 pb-1 pt-px bg-white shadow-lg rounded-lg h-full">
				<div className="mx-3 -mt-5 mb-3 border-t border-gray-200 bg-gray-200  rounded-lg">
					{title}
				</div>

				<div className="px-4 pb-4 w-full h-full">{children}</div>
			</div>
		</>
	);
};

export default CardWithFancyHeader;
