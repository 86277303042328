import React from "react";
import { useField, splitFormProps } from "react-form";
import Datetime from "react-datetime";
import moment from "moment";

// function validateAddressStreet(value) {
// 	if (!value) {
// 		return "A street is required";
// 	}
// 	return false;
// }

const DateField = React.forwardRef((props, ref) => {
	// Let's use splitFormProps to get form-specific props
	const [field, fieldOptions, rest] = splitFormProps(props);

	// Use the useField hook with a field and field options
	// to access field state
	const {
		meta: { error, isTouched, isValidating },
		getInputProps,
		setValue
	} = useField(field, fieldOptions);

	// const inputClassesGood = "h-8 pl-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full border-2 border-gray-300 rounded-md";
	// const inputClassesError = `block w-full h-7 pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md`
	//
	// const inputClasses = error ? inputClassesError : inputClassesGood;

	const { id, label, disabled, name, placeholder, onValueChanged } = rest;

	const handleOnBlur = newDate => {
		setValue(!newDate ? "" : newDate.utc());

		const value = !newDate
			? ""
			: typeof newDate === "string"
			? newDate
			: newDate.utc().toISOString();

		const fieldName = name ? name : field;

		if (onValueChanged) {
			const fieldInfo = { fieldName, value };
			onValueChanged(fieldInfo);
		}
	};

	const { value } = getInputProps();

	if (disabled) {
		return (
			<div className="block font-medium leading-5 text-gray-700">
				<label htmlFor={id} className="block text-sm font-medium text-gray-500">
					{label}
				</label>
				<div className="w-full h-8 text-sm border-2 border-gray-300 rounded-md">
					{value.format("MM/DD/YYYY")}
				</div>
			</div>
		);
	}

	// Build the field
	return (
		<>
			<div className="block font-medium leading-5 text-gray-700">
				<label htmlFor={id} className="block text-sm font-medium text-gray-500">
					{label}
				</label>

				<div className="mt-1 relative rounded-md shadow-sm">
					<Datetime
						timeFormat={false}
						dateFormat={"MM/DD/YYYY"}
						closeOnSelect={true}
						initialValue={value ? moment(value) : ""}
						inputProps={{
							ref,
							// value,
							// onChange: (event) => {
							// 	console.log('DateField.js onChange event.target.value', event.target.value);
							// 	onChange(event);
							// },
							placeholder,
							className:
								"w-full h-8 text-sm border-2 border-gray-300 rounded-md"
						}}
						onClose={handleOnBlur}
						// onChange={handleOnBlur}
					/>

					{isValidating ? (
						<em>Validating</em>
					) : isTouched && error ? (
						<div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
							<svg
								className="h-5 w-5 text-red-500"
								fill="currentColor"
								viewBox="0 0 20 20"
							>
								<path
									fillRule="evenodd"
									d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
									clipRule="evenodd"
								/>
							</svg>
						</div>
					) : null}
				</div>
				{error && <p className="mt-2 text-sm text-red-600">{error}</p>}
			</div>
		</>
	);
});

DateField.displayName = "InputField";

export default DateField;
