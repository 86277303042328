import { Storage } from "aws-amplify";

export const uploadFile = async (imageKey, fileInfo) => {
	// TODO: should this be checking for other file types?
	const contentType = imageKey.endsWith("pdf")
		? "application/pdf"
		: "image/jpeg";

	return await Storage.put(imageKey, fileInfo, { contentType: contentType });
};

export const uploadBytes = async (imageKey, bytes) => {
	// TODO: should this be checking for other file types?
	const contentType = imageKey.endsWith("pdf")
		? "application/pdf"
		: "image/jpeg";

	return await Storage.put(imageKey, bytes, { contentType: contentType });
};

export const getFetchableImageUrl = async imageKey => {
	const url = await Storage.get(imageKey);

	return url;
};

export const getFileFromS3ForKey = async imageKey => {
	const response = await Storage.get(imageKey, { download: true });

	return response;
};
