// ui stuff
export const closeEdit = "closeEdit";
export const showAddAttachment = "showAddAttachment";

export const changeStatus = "changeStatus";
export const resetStatus = "resetStatus";
export const resetApHold = "resetApHold";
export const deleteTransaction = "deleteTransaction";

export const start = "start";
export const replaceTransaction = "replaceTransaction";
export const replaceTransactionField = "replaceTransactionField";
export const updateTransactionField = "updateTransactionField";

export const insertAttachment = "insertAttachment";
export const updateAttachment = "updateAttachment";
export const deleteAttachment = "deleteAttachment";
export const updateAttachmentField = "updateAttachmentField";
export const deleteAttachmentFromDataStore = "deleteAttachmentFromDataStore";

export const createApproval = "createApproval";
export const stampApproval = "stampApproval";

export const AddAttachmentDistribution = "AddAttachmentDistribution";
export const DeleteAttachmentDistribution = "DeleteAttachmentDistribution";
export const UpdateAttachmentDistribution = "UpdateAttachmentDistribution";
export const insertDistribution = "insertDistribution";
export const updateDistribution = "updateDistribution";
export const updateDistributionField = "updateDistributionField";
export const deleteDistribution = "deleteDistribution";
export const insertMessage = "insertMessage";
