import React from "react";
import { TrashIcon } from "@heroicons/react/solid";

import AddButton from "1-atoms/AddButton";

import * as editActions from "../editActions";

const AttachmentCommands = ({ attachment, editTransaction, dispatch }) => {
	// const isBp = (editTransaction && editTransaction.transaction) ? editTransaction.transaction.isBp : false;

	return (
		<div className="flex justify-between p-2">
			<div className="flex">
				<div className="has-tooltip">
					<span className="tooltip rounded shadow-lg p-2 bg-gray-100 text-gray-600 -mt-8">
						Create Stamp Approval document
					</span>

					<button
						className="ml-1 relative inline-flex items-center px-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
						data-testid="create-approval"
						onClick={() => {
							const action = {
								type: editActions.createApproval,
								editTransaction: editTransaction,
								payload: {
									editTransaction
								}
							};
							dispatch(action);
						}}
					>
						Create Approval
					</button>
				</div>

				<div className="has-tooltip">
					<span className="tooltip rounded shadow-lg p-2 bg-gray-100 text-gray-600 -mt-8">
						Create BP Approval document
					</span>
					<button
						className="ml-1 relative inline-flex items-center px-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
						data-testid="stamp-bp-approval"
						onClick={() => {
							const options = {
								approval1: editTransaction.transaction.approval1,
								approval1Date: editTransaction.transaction.approval1Date,
								approval2: editTransaction.transaction.approval2,
								approval2Date: editTransaction.transaction.approval2Date
							};

							const action = {
								type: editActions.stampApproval,
								payload: {
									attachment,
									options
								},
								attachment: attachment,
								options
							};
							dispatch(action);
						}}
					>
						Create BP Approval
					</button>
				</div>
			</div>

			<div className="flex">
				<div className="has-tooltip">
					<span className="tooltip rounded shadow-lg p-2 bg-gray-100 text-gray-600 -mt-8">
						Delete the document
					</span>

					<button
						className="w-7 h-7 pl-1 mt-2 mx-2 text-red-400 hover:text-red-600 border-gray-200 rounded-full"
						data-testid="delete-attachment"
						onClick={() => {
							const action = {
								type: editActions.deleteAttachment,
								payload: attachment
							};
							dispatch(action);
						}}
					>
						<TrashIcon className="w-5 h-5" />
					</button>
				</div>

				<div className="has-tooltip">
					<span className="tooltip rounded shadow-lg p-2 bg-gray-100 text-gray-600 -mt-8">
						Add new document
					</span>

					<AddButton
						testid={"add-attachment"}
						onClick={() => {
							const action = {
								type: editActions.showAddAttachment,
								payload: true
							};
							dispatch(action);
						}}
					/>
				</div>
			</div>
		</div>
	);
};

export default AttachmentCommands;
