import { pdfjs } from "react-pdf";

// TODO: will this affect the amplify test environment
if (process.env.NODE_ENV === "test")
	pdfjs.GlobalWorkerOptions.workerSrc = "./pdf.worker.js";
else
	pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

export default class PdfDocument {
	static create(array) {
		return new PdfDocument(pdfjs, array);
	}

	static createNull(pages) {
		return new PdfDocument(new NullPdfjs(pages), null);
	}

	constructor(pdfjsP, array) {
		this._pdfjs = pdfjsP;
		this._array = array;
	}

	async parseArray() {
		const loadingTask = await this._pdfjs.getDocument(this._array);
		this._pdf = await loadingTask.promise;
	}

	get numPages() {
		return this._pdf._pdfInfo.numPages;
	}

	async getPage(pageNumber) {
		const page = await this._pdf.getPage(pageNumber);
		return Page.create(page);
	}

	async getDocumentTextFields() {
		await this.parseArray();

		let pages = [];
		for (let pageNumber = 1; pageNumber <= this.numPages; pageNumber++) {
			const page = await this._pdf.getPage(pageNumber);

			const newPage = await this.getTextFieldsForPage(page, pageNumber);

			pages.push(newPage);
		}

		return pages;
	}

	async getTextFieldsForPage(page) {
		const textContent = await page.getTextContent();

		let textItems = [];
		let index = -1;
		textContent.items.forEach(item => {
			index++;

			if (item.str === "") return;

			let i1 = {
				index: index,
				x: item.transform[4],
				y: item.transform[5],
				str: item.str
			};
			textItems.push(i1);
		});

		return {
			page: page._pageIndex + 1,
			fields: textItems
		};
	}

	async parsePdf(fieldPages) {
		let pages = [];
		for (let pageNumber = 0; pageNumber < fieldPages.length; pageNumber++) {
			const page = fieldPages[pageNumber];
			const fields = page.fields;

			let lines = {};
			fields.forEach(field => {
				if (!lines[field.y]) lines[field.y] = [];

				lines[field.y].push(field);
			});

			var sortedLines = Object.entries(lines)
				.map(line => {
					return { line: line[0], fields: line[1] };
				})
				.sort(compareYDescending);

			var linesStr = sortedLines.map(line => {
				return line.fields
					.sort(compareX)
					.map(item => item.str)
					.join(" ");
			});

			const newPage = { page: page.page, lines: linesStr };

			pages.push(newPage);
		}

		return pages;
	}

	// Transform(viewTransform, itemTransform) {
	// 	const transformResult = this._pdfjs.Util.transform(
	// 		viewTransform,
	// 		itemTransform
	// 	);
	// 	const tx = this._pdfjs.Util.transform(transformResult, [1, 0, 0, -1, 0, 0]);
	// 	return tx;
	// }
}

class Page {
	static create(page) {
		return new Page(page);
	}

	constructor(page) {
		this._page = page;
	}

	getTextContent() {
		return this._page.getTextContent();
	}
}

class NullPdfjs {
	constructor(pages) {
		this._pages = pages;
	}

	getDocument() {
		return {
			promise: new Promise(resolve => {
				resolve(new NullDocument(this._pages));
			})
		};
	}
}

class NullDocument {
	constructor(pages) {
		this._pages = pages;
	}

	get _pdfInfo() {
		return { numPages: this._pages.length };
	}

	getPage(pageNumber) {
		return new Promise(resolve => {
			resolve(new NullPage(this._pages[pageNumber - 1], pageNumber));
		});
	}
}

class NullPage {
	constructor(pageItems, pageNumber) {
		this._pageItems = pageItems;

		this._pageIndex = pageNumber - 1;
	}

	getTextContent() {
		return new Promise(resolve => {
			const textArray = {
				items: this._pageItems
			};
			resolve(textArray);
		});
	}
}

function compareX(a, b) {
	return a.x - b.x;
}

function compareYDescending(a, b) {
	return -1 * ("" + a.line).localeCompare(b.line);
}
