import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import AWS from "aws-sdk";

import config from "aws-exports";
import backupAccounts from "1-atoms/accounts.json";

const sortedAccounts = backupAccounts.sort((a, b) =>
	a.name.toUpperCase().localeCompare(b.name.toUpperCase())
);

const AccountsStateContext = React.createContext();
const AccountsSetContext = React.createContext();

function AccountsProvider(props) {
	const [accounts, setAccounts] = useState(sortedAccounts);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const run = async () => {
			setLoading(true);

			const credentials = await Auth.currentCredentials();
			const cred = Auth.essentialCredentials(credentials);

			let s3 = new AWS.S3({
				apiVersion: "2006-03-01",
				region: config.aws_user_files_s3_bucket_region,
				credentials: cred
			});

			var params = {
				Bucket: config.aws_user_files_s3_bucket,
				Key: "public/accounts.json"
			};

			const data = await s3.getObject(params).promise();

			const string = new TextDecoder("utf-8").decode(data.Body);
			const json = JSON.parse(string);
			json.push({ aid: "", account: "", name: "", groupByField: "" });
			setAccounts(json);
			setLoading(false);
			// s3.getObject(params, function (err, data) {
			// 	if (err) {
			// 		console.log('AccountsProvider.js getObject err', err);
			// 	}
			// 	const string = new TextDecoder("utf-8").decode(data.Body);
			// 	const json = JSON.parse(string);
			// 	json.push({ aid: '', account: '', name: '', groupByField: '' });
			// 	setAccounts(json);
			// 	setLoading(false);
			// });
		};

		run();
	}, []);

	if (loading) return <div>Loading Accounts!</div>;

	return (
		<AccountsStateContext.Provider value={accounts}>
			<AccountsSetContext.Provider value={setAccounts}>
				{props.children}
			</AccountsSetContext.Provider>
		</AccountsStateContext.Provider>
	);
}

function useAccountsState() {
	const context = React.useContext(AccountsStateContext);
	if (context === undefined) {
		throw new Error("useAccountsState must be used within a AccountsProvider");
	}
	return context;
}

function useAccountsSetState() {
	const context = React.useContext(AccountsSetContext);
	if (context === undefined) {
		throw new Error(
			"useAccountsSetState must be used within a AccountsProvider"
		);
	}
	return context;
}

export { AccountsProvider, useAccountsState, useAccountsSetState };
