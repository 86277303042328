import React, { useEffect, useState } from "react";
import { SizeMe } from "react-sizeme";

import { getFetchableImageUrl } from "etegrisService/s3Storage";
import Iframe from "react-iframe";

export const EtegrisImage2TW = ({ url, title }) => {
	const [safeUrl, setSafeUrl] = useState(url);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		if (safeUrl && safeUrl.substring(0, 6) !== "https:") {
			const run = async () => {
				const newUrl = await getFetchableImageUrl(url);
				setSafeUrl(newUrl);
				setLoading(false);
			};
			run();
		} else {
			setLoading(false);
		}
	}, [getFetchableImageUrl]);

	if (!url) return <div>no image to display</div>;

	const pdf = url.endsWith("pdf") ? true : false;

	return (
		<div>
			<div>
				{pdf &&
					(loading ? (
						<div>Loading...</div>
					) : (
						<div>
							<SizeMe
								monitorHeight
								refreshRate={128}
								refreshMode={"debounce"}
								render={() => (
									<div
										style={{
											overflow: "scroll",
											margin: "15px"
										}}
									>
										<div>{title}</div>
										<Iframe url={safeUrl} width="100%" height="600px"></Iframe>
									</div>
								)}
							/>
						</div>
					))}
				{!pdf && (
					<div>
						<div>{title}</div>
						<img src={safeUrl} />
					</div>
				)}
			</div>
		</div>
	);
};
