import React from "react";
import useAccounts from "./useAccounts";

export default function AccountsView() {
	const {
		isProcessingAccounts,
		loadAccounts,
		errorAccounts,
		canUploadAccounts,
		updateS3,
		messageAccounts
	} = useAccounts();

	const handleGetAccountsButtonClick = async () => {
		await loadAccounts();
		console.log("handle GetAccounts clicked");
	};

	const handleUploadAccountsOnClick = async () => {
		await updateS3();
		console.log("handleUploadAccountsOnClick clicked");
	};

	return (
		<div className="m-10 border-2 border-gray-300">
			<button className="jde-button" onClick={handleGetAccountsButtonClick}>
				Update Accounts
			</button>

			<button
				className="jde-button"
				disabled={!canUploadAccounts}
				onClick={handleUploadAccountsOnClick}
			>
				Upload Accounts to S3
			</button>

			{isProcessingAccounts && (
				<div className="text-green-400">Processing Accounts</div>
			)}
			{messageAccounts.length > 0 && (
				<div className="mt-20">{messageAccounts}</div>
			)}
			{errorAccounts && (
				<div className="text-red-500">
					<div>Error loading accounts</div>
					<div>{errorAccounts}</div>
				</div>
			)}
		</div>
	);
}
