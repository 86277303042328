import React from "react";
import { v4 as uuidv4 } from "uuid";

import { AddExpense } from "./document/AddExpense";
import { ExpenseStatus } from "./status/ExpenseStatus";
import Attachments from "./document/Attachments";
import ErrorBoundary from "../../ErrorBoundry";
import ChatDiscussion from "../chat/ChatDiscussion";
import { ExpenseInformation } from "./info/ExpenseInformation";
import Distributions from "./distribution/Distributions";
import { round } from "mathjs";
import employees from "../employee/employees";
import * as editActions from "./editActions";

import AttachmentsModal from "./document/AttachmentsModal";
import EtegrisModal from "./EtegrisModal";

import { doesInvoiceExist } from "./validateInvoices";

// removed history as parameter
const EditExpenseLayout = ({
	user,
	state,
	addressBooks,
	accounts,
	service,
	invCacheRef,
	dispatch
}) => {
	const handleClose = () => {
		dispatch({ type: editActions.showAddAttachment, payload: false });
	};

	const handleSend = async message => {
		await service.saveMessage(message);

		employees.forEach(emp => {
			if (message.comment.includes(emp.name))
				service.sendEmailToUser(message, state.transaction, emp.email);
		});
	};

	async function handleOnUpload(file, title) {
		const newAttach = await service.uploadAttachmentForTransactionId(
			file,
			title,
			user.homeGroup,
			state.transaction.id
		);

		dispatch({ type: editActions.insertAttachment, payload: newAttach });
		dispatch({ type: editActions.showAddAttachment, payload: false });
	}

	const handleFieldUpdated = async (distribution, fieldInfo) => {
		dispatch({
			type: editActions.updateDistributionField,
			payload: { id: distribution.id, fieldInfo }
		});
	};

	const handleOnAdd = transactionId => {
		const newDistribution = {
			id: uuidv4(),
			transactionId: transactionId,
			account: "",
			amount: 0,
			subledgerType: "",
			subledgerId: "",
			subledgerName: "",
			groups: ["CfsAdmin", "CfsApprover"]
		};

		dispatch({
			type: editActions.insertDistribution,
			payload: newDistribution
		});
	};
	const handleOnDelete = async distribution => {
		dispatch({
			type: editActions.deleteDistribution,
			payload: distribution
		});
	};

	// function validateInvoices(invoices, key, attachmentId) {
	// 	if (invoices[key]) {
	// 		const obj = invoices[key];
	// 		if (obj.attachment.id === attachmentId)
	// 			return false;
	// 		else
	// 			return `Invoice exists! ${invoices[key].title}`;
	// 	} else
	// 		return false;
	// }

	function ValidateInvoice(vendorNo, invoice, attachmentId) {
		let invoices = invCacheRef.current;
		return doesInvoiceExist(invoices, vendorNo, invoice, attachmentId);
	}

	if (!state || !state.transaction || !state.transaction.id)
		return <div>No Transaction passed to component!</div>;

	return (
		<React.Fragment>
			<EtegrisModal onClose={handleClose} open={state.showAddAttachment}>
				<AddExpense onUpload={handleOnUpload} onUploadBP dispatch={dispatch} />
			</EtegrisModal>

			<ExpenseStatus
				editTransaction={state}
				user={user}
				dispatch={dispatch}
			></ExpenseStatus>

			<div className="flex flex-row">
				<div className="w-1/3">
					<AttachmentsModal
						editTransaction={state}
						accounts={accounts}
						addressBooks={addressBooks}
						checkIfInvoiceExists={ValidateInvoice}
						dispatch={dispatch}
					/>

					<Attachments
						attachments={state.attachments}
						editTransaction={state}
						checkIfInvoiceExists={ValidateInvoice}
						dispatch={dispatch}
						accounts={accounts}
					/>

					<ErrorBoundary>
						<ChatDiscussion
							loginUser={user}
							transaction={state.transaction}
							messages={state.messages}
							send={handleSend}
						/>
					</ErrorBoundary>
				</div>

				<div className="w-1/3">
					<ExpenseInformation
						transaction={state.transaction}
						vendors={addressBooks}
						attachments={state.attachments}
						dispatch={dispatch}
					/>

					{/*{*/}
					{/*	history && <ErrorBoundary> <DisplayHistory history={history} /></ErrorBoundary>*/}
					{/*}*/}
				</div>

				<div className="w-1/3 mx-3">
					<Distributions
						net={
							state.transaction
								? round(state.transaction.total - state.transaction.gst, 2)
								: 0
						}
						transactionId={state.transaction.id}
						accounts={accounts}
						addressBooks={addressBooks}
						distributions={state.distributions}
						fieldUpdated={handleFieldUpdated}
						onAdd={handleOnAdd}
						onDelete={handleOnDelete}
					/>
				</div>
			</div>
		</React.Fragment>
	);
};

export default EditExpenseLayout;
