import React, { useState } from "react";
import PropTypes from "prop-types";
// import { Link } from 'react-router-dom';
import * as service from "etegrisService";

import {
	DefaultCell,
	DateTimeCell,
	NumberCell,
	SelectColumnFilter,
	Table
} from "4-cards/Table";
import { useUserState } from "../etegris/employee/user-context";
import { useAccountsState } from "../etegris/AccountsProvider";
import { useAddressBookState } from "../etegris/AddressBookProvider";

// import Pencil from "svg/Pencil";

function CreatePdf({ row }) {
	const userState = useUserState();

	const accounts = useAccountsState();
	const addressBooks = useAddressBookState();

	const handleClick = async () => {
		await service.callCreateBpPdf(row.original);

		await service.createBpTransaction(
			row.original,
			userState.user,
			accounts,
			addressBooks
		);
	};

	return (
		<div className="h-full">
			<button>
				<div className="jde-button" onClick={handleClick}>
					Create
				</div>
			</button>
		</div>
	);
}

const columns = [
	{
		Header: "Row",
		accessor: (row, i) => i + 1,
		width: 50,
		Cell: DefaultCell
	},
	{
		Header: "",
		id: "create",
		width: 90,
		Cell: CreatePdf
	},
	{
		Header: "BP #",
		accessor: "tranh_id",
		// Filter: SelectColumnFilter,
		filter: "contains",
		Cell: DefaultCell
	},
	{
		Header: "Vendor AB#",
		accessor: "tranh_ven_id",
		Cell: DefaultCell
	},
	{
		Header: "Vendor Name",
		accessor: "tranh_ven_name",
		Cell: DefaultCell
	},
	{
		Header: "Status",
		accessor: "tranh_status",
		Filter: SelectColumnFilter,
		filter: "equals",
		Cell: DefaultCell
	},
	{
		Header: "Tran Date",
		accessor: "tranh_date",
		Cell: DateTimeCell,
		width: 125
	},
	{
		Header: "Amount",
		accessor: "tranh_amount",
		filter: "contains",
		width: 100,
		Cell: NumberCell
	}
	// {
	// 	Header: "Invoice",
	// 	accessor: "invoice",
	// 	sortable: false,
	// 	Cell: DefaultCell
	// },
	// {
	// 	Header: "Remark",
	// 	accessor: "remark",
	// 	sortable: false,
	// 	Cell: DefaultCell
	// }
];

export const BestPracticeTransactionTable = ({ transactions }) => {
	const [filters, setFilters] = useState([]);
	const [globalFilter, setGlobalFilter] = useState("");

	function dispatchFiltersChanged(newFilters) {
		setFilters(newFilters);
	}

	function dispatchGlobalFilterChanged(newFilter) {
		setGlobalFilter(newFilter);
	}

	const hiddenColumns = [];

	return (
		<Table
			columns={columns}
			data={transactions}
			hiddenColumns={hiddenColumns}
			showHiddenColumns={true}
			globalFilter={globalFilter}
			filters={filters}
			dispatchFiltersChanged={dispatchFiltersChanged}
			dispatchGlobalFilterChanged={dispatchGlobalFilterChanged}
		/>
	);
};

BestPracticeTransactionTable.propTypes = {
	transactions: PropTypes.array.isRequired
};
