import { assign, createMachine, forwardTo } from "xstate";

console.log("bestPracticeMachine.js ------- window.location", window.location);

const wsUrl = window.location.href.startsWith("http://")
	? "ws://marmot.squamish.net:5001/ws"
	: "wss://jde.squamish.net/ws";

console.log("bestPracticeMachine.js ------- wsUrl", wsUrl);

export const bestPractiseMachine = createMachine({
	context: {
		transactions: [],
		transactionCount: 0,
		loading: false,
		errorMessage: ""
	},
	on: {
		SEND: {
			actions: forwardTo("websocket")
		},
		CLEAR_DATA: {
			actions: assign({
				transactions: () => {
					console.log("bestPractiseMachine.js ----- Clearing Data");
					return [];
				}
			})
		},
		LOADING: {
			actions: assign({
				loading: () => {
					return true;
				}
			})
		},
		FINISHED_LOADING: {
			actions: assign({
				loading: () => {
					return false;
				}
			})
		},
		RECEIVED_TRANSACTIONS: {
			actions: assign({
				transactions: (context, event) => {
					console.log(
						"bestPractiseMachine.js RECEIVED_TRANSACTIONS --------- event.transactions",
						event.transactions
					);

					return [...context.transactions, ...event.transactions];
				}
			})
		},
		ERROR: {
			actions: assign({
				errorMessage: (context, event) => {
					return "Error: " + event.errorMessage;
				}
			})
		}
	},
	invoke: {
		id: "websocket",
		src: () => (send, onReceive) => {
			console.log("bestPractiseMachine.js  ------ connnection machine");
			const webSocket = new WebSocket(wsUrl);

			onReceive(event => {
				console.log("bestPractiseMachine.js onReceive event", event);
				if (event.type === "SEND") {
					webSocket.send(event.message);
				}
			});

			const listener = event => {
				let data = JSON.parse(event.data);

				if (!data.Status) {
					send({
						type: "Error",
						errorMessage: "Status not defined",
						data: event.data
					});
				}

				switch (data.Status) {
					case "OK":
						console.log("bestPractiseMachine.js  data.message", data.message);
						if (data.Message) {
							switch (data.Message) {
								case "StartingToSend":
									send({ type: "LOADING" });
									break;
								case "SendingTransactions":
									console.log(
										"bestPractiseMachine.js SendingTransactions event.data",
										data
									);
									send({
										type: "RECEIVED_TRANSACTIONS",
										transactions: data.Transactions
									});
									break;
								case "FinishedSendingTransactions":
									send({ type: "FINISHED_LOADING" });
									break;
								default:
									send({
										type: "ERROR",
										errorMessage: "Unknown Message type " + data.message,
										data: event.data
									});
							}
						}
						break;
					case "ERROR":
						console.log("bestPractiseMachine.js ERROR ------ data", data);
						send({
							type: "ERROR",
							errorMessage: "Server Error: " + data.ErrorMessage,
							data: event.data
						});
						break;
				}
			};
			webSocket.addEventListener("message", listener);

			return () => {
				console.log("bestPractiseMachine.js  -------- disconnecting webSocket");
				webSocket.removeEventListener("message", listener);
				//webSocket.();
			};
		}
	}
});
