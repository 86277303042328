import { useState } from "react";

const useVouchers = () => {
	const [vouchers, setVouchers] = useState([]);
	const [isLoadingVouchers, setIsLoadingVouchers] = useState(false);
	const [voucherError, setVoucherError] = useState(null);

	const [isProcessingPayments, setIsProcesingPayments] = useState(false);
	const [processPaymentsError, setProcessingPaymentsError] = useState(null);

	const loadVouchers = async () => {
		try {
			setVoucherError(null);

			setIsLoadingVouchers(true);
			const response = await fetch("https://jde.squamish.net/vouchers");
			setIsLoadingVouchers(false);

			const data = await response.json();
			setVouchers(data);
		} catch (e) {
			console.log("JdeView.js handleButtonClick catch e", e);
			setVoucherError(e.message);
		}
	};

	const processPayments = () => {
		setProcessingPaymentsError(null);
		setIsProcesingPayments(true);

		try {
			const vouchersToProcess = [vouchers[0]];

			vouchersToProcess.forEach(voucher => {
				console.log(
					"useVouchers.js processPayments doc, paymentBatch, paymentNumber, urrf",
					voucher.doc,
					voucher.paymentBatch,
					voucher.paymentNumber,
					voucher.urrf
				);
			});
		} catch (e) {
			setProcessingPaymentsError(e.message);
		}

		setIsProcesingPayments(false);
	};

	const clearErrors = () => {
		setVoucherError(null);
		setProcessingPaymentsError(null);
	};

	return {
		vouchers,
		loadVouchers,
		isLoadingVouchers,
		voucherError,
		processPayments,
		isProcessingPayments,
		processPaymentsError,
		clearErrors
	};
};

export default useVouchers;
