import React from "react";
import { Auth } from "aws-amplify";
import EtegrisMenu from "./EtegrisMenu";
import useUser from "./etegris/employee/useUser";

const UserAccount = () => {
	const { userState, clearUser } = useUser();

	const handleLogOutClick = async () => {
		await Auth.signOut();
		clearUser();
		location.reload();
	};

	const items = [
		{
			label: "Logout",
			onClick: handleLogOutClick
		}
	];

	return (
		<>
			<EtegrisMenu
				buttonText={userState.user ? userState.user.username : "not set"}
				items={items}
				icon={
					<svg
						heroicon="user"
						className="w-8 h-8 p-1 bg-gray-300 rounded-full flex-shrink-0"
						xmlns="http://www.w3.org/2000/svg"
						fill="none"
						viewBox="0 0 24 24"
						stroke="currentColor"
					>
						<path
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth={2}
							d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
						/>
					</svg>
				}
			></EtegrisMenu>
			<span className="w-full mx-2 my-1 py-1 bg-gray-200 text-center rounded-md">
				{userState.user.homeGroup}
			</span>
		</>
	);
};

export default UserAccount;
