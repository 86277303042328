import React, { useEffect, useState } from "react";

import useEditExpense from "./useEditExpense";
import { useAccountsState } from "../AccountsProvider";
import { useAddressBookState } from "../AddressBookProvider";
import EditExpenseLayout from "./EditExpenseLayout";

import { useInvoiceCacheUserRef } from "../InvoiceCacheProvider";

const EditExpenseWithState3 = ({ user }) => {
	const { state, dispatch, service } = useEditExpense();
	const [history, setHistory] = useState(null);

	const accounts = useAccountsState();
	const addressBooks = useAddressBookState();

	let invCacheRef = useInvoiceCacheUserRef();

	useEffect(() => {
		const run = async () => {
			const history = await service.getHistoryForTransactionId(
				state.transaction.id
			);

			setHistory(
				history.filter(tran => tran.transactionId === state.transaction.id)
			);
		};

		run();
	}, []);

	return (
		<EditExpenseLayout
			state={state}
			history={history}
			user={user}
			addressBooks={addressBooks}
			accounts={accounts}
			service={service}
			invCacheRef={invCacheRef}
			dispatch={dispatch}
		/>
	);
};

export default EditExpenseWithState3;
