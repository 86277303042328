import { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import AWS from "aws-sdk";

import config from "aws-exports";

const useSubledgers = () => {
	const [subLedgers, setSubLedgers] = useState([]);
	const [isProcessing, setIsProcessing] = useState(false);
	const [error, setError] = useState("");
	const [message, setMessage] = useState("");

	const [canUploadSubledgers, setCanUploadSubledgers] = useState(false);

	useEffect(() => {
		if (subLedgers && subLedgers.length > 0) setCanUploadSubledgers(true);
		else setCanUploadSubledgers(false);
	}, [subLedgers]);

	const load = async () => {
		try {
			setIsProcessing(true);
			setError("");

			const response = await fetch("https://jde.squamish.net/subledgers");
			const data = await response.json();

			console.log("subledgers data", data);
			setSubLedgers(data);

			setIsProcessing(false);
			setMessage(`Loaded ${data.length} Address Book records`);
		} catch (e) {
			setIsProcessing(false);
			console.log("useSubledgers.js load.catch(e)", e);
			setError(JSON.stringify(e, null, 2));
		}
	};

	const updateS3 = async () => {
		if (subLedgers.length === 0) return;

		const credentials = await Auth.currentCredentials();

		console.log("FetchData.js useEffect credentials", credentials);

		const cred = Auth.essentialCredentials(credentials);
		console.log("FetchData.js ---- cred", cred);

		let s3 = new AWS.S3({
			apiVersion: "2006-03-01",
			region: config.aws_user_files_s3_bucket_region,
			credentials: cred
		});
		var params = {
			Bucket: config.aws_user_files_s3_bucket,
			Key: "public/subLedgers.json",
			Body: JSON.stringify(subLedgers),
			CacheControl: "no-cache"
		};
		console.log("--------- about to uplaod to S3");
		s3.upload(params, function(err, data) {
			console.log(err, data);
			if (err) {
				setMessage("Error trying to upload Address Book Errors to EDC");
			} else {
				setMessage("Uploaded Address Book records to EDC!");
			}
		});
	};

	return {
		subLedgers,
		loadSubledgers: load,
		isProcessingSubledgers: isProcessing,
		errorSubledgers: error,
		canUploadSubledgers,
		updateS3,
		message: message
	};
};

export default useSubledgers;
