import { Auth } from "aws-amplify";
import AWS from "aws-sdk";
import config from "aws-exports";

export const getAddressBookJson = async () => {
	const credentials = await Auth.currentCredentials();
	const cred = Auth.essentialCredentials(credentials);

	let s3 = new AWS.S3({
		apiVersion: "2006-03-01",
		region: config.aws_user_files_s3_bucket_region,
		credentials: cred
	});

	var params = {
		Bucket: config.aws_user_files_s3_bucket,
		Key: "public/subLedgers.json"
	};

	try {
		const result = await s3.getObject(params).promise();
		const string = new TextDecoder("utf-8").decode(result.Body);
		const json = JSON.parse(string);

		const vendors = getVendorsFromAddressBooks(json);

		return { status: "OK", vendors };
	} catch (e) {
		console.log("AddressBookProvider.js in getObject e", e);
		console.log("AddressBookProvider.js  JSON.stringify(e)", JSON.stringify(e));

		return { status: "ERROR", error: e };
	}
};

export const getPreSignedUrl = async key => {
	const credentials = await Auth.currentCredentials();
	const cred = Auth.essentialCredentials(credentials);

	let s3 = new AWS.S3({
		apiVersion: "2006-03-01",
		region: config.aws_user_files_s3_bucket_region,
		credentials: cred
	});

	var params = {
		Bucket: config.aws_user_files_s3_bucket,
		Key: key
	};

	try {
		const result = s3.getSignedUrl("getObject", params);

		return { status: "OK", url: result };
	} catch (e) {
		console.log("s3Service.js in getObject e", e);
		console.log("s3Service.js  JSON.stringify(e)", JSON.stringify(e));

		return { status: "ERROR", error: e };
	}
};

const nullVendor = { an8: "0", name: "", groupByField: "Z", searchField: "" };

function sort(a, b) {
	const searchType = a.searchType.localeCompare(b.searchType);
	if (searchType !== 0) return searchType;

	return a.name.toUpperCase().localeCompare(b.name.toUpperCase());
}

function getVendorsFromAddressBooks(rawAddressBooks) {
	const vendors = [
		...rawAddressBooks
			.map(sub => {
				return {
					...sub,
					groupByField: sub.searchType,
					searchField: `${sub.name} (${sub.an8})`.toLowerCase()
				};
			})
			.sort(sort),
		nullVendor
	];
	return vendors;
}
