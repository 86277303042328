import { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import AWS from "aws-sdk";

import config from "aws-exports";

const useAccounts = () => {
	const [accounts, setAccounts] = useState([]);
	const [isProcessing, setIsProcessing] = useState(false);
	const [error, setError] = useState("");
	const [message, setMessage] = useState("");

	const [canUploadAccounts, setCanUploadAccounts] = useState(false);

	useEffect(() => {
		if (accounts && accounts.length > 0) setCanUploadAccounts(true);
		else setCanUploadAccounts(false);
	}, [accounts]);

	const load = async () => {
		try {
			setIsProcessing(true);
			setError("");

			const response = await fetch("https://jde.squamish.net/accounts");
			const data = await response.json();

			console.log("accounts data", data);
			setAccounts(data);

			setIsProcessing(false);
			setMessage(`Loaded ${data.length} Accounts!`);
		} catch (e) {
			setIsProcessing(false);
			console.log("useAccounts.js load.catch(e)", e);
			setError(JSON.stringify(e, null, 2));
		}
	};

	const updateS3 = async () => {
		if (accounts.length === 0) return;

		const credentials = await Auth.currentCredentials();

		console.log("FetchData.js useEffect credentials", credentials);

		const cred = Auth.essentialCredentials(credentials);
		console.log("FetchData.js ---- cred", cred);

		let s3 = new AWS.S3({
			apiVersion: "2006-03-01",
			region: config.aws_user_files_s3_bucket_region,
			credentials: cred
		});
		var params = {
			Bucket: config.aws_user_files_s3_bucket,
			Key: "public/accounts.json",
			Body: JSON.stringify(accounts),
			CacheControl: "no-cache"
		};
		console.log("--------- about to uplaod to S3");

		s3.upload(params, function(err, data) {
			console.log(err, data);
			if (err) {
				setMessage("Error uploading Accounts to S3!  Check console");
			} else {
				setMessage("Uploaded Accounts to S3!");
			}
		});
	};

	return {
		accounts,
		loadAccounts: load,
		isProcessingAccounts: isProcessing,
		errorAccounts: error,
		canUploadAccounts,
		updateS3,
		messageAccounts: message
	};
};

export default useAccounts;
