/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateTransaction = /* GraphQL */ `
  subscription OnCreateTransaction {
    onCreateTransaction {
      id
      userReference
      meta
      schema
      title
      status
      owner2
      batch
      vendorNo
      vendor
      total
      gst
      net
      invoice
      invoiceDate
      multipleExpenses
      glDate
      description
      remark
      isBP
      ready
      readyDate
      approval1
      approval1Date
      approval2
      approval2Date
      json
      createdBy
      createdAt
      updatedAt
      groups
      attachments {
        items {
          id
          transactionId
          imagekey
          name
          createdAt
          meta
          date
          isExpense
          total
          gst
          net
          description
          invoice
          remark
          glDate
          isBP
          bpNumber
          groups
          updatedAt
        }
        nextToken
      }
      distributions {
        items {
          id
          transactionId
          accountNo
          account
          postingEdit
          subledgerType
          subledgerId
          subledgerName
          amount
          createdAt
          meta
          groups
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          transactionId
          userId
          username
          comment
          createdAt
          meta
          groups
          updatedAt
        }
        nextToken
      }
      actions {
        items {
          id
          transactionId
          actionType
          meta
          json
          createdAt
          groups
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onUpdateTransaction = /* GraphQL */ `
  subscription OnUpdateTransaction {
    onUpdateTransaction {
      id
      userReference
      meta
      schema
      title
      status
      owner2
      batch
      vendorNo
      vendor
      total
      gst
      net
      invoice
      invoiceDate
      multipleExpenses
      glDate
      description
      remark
      isBP
      ready
      readyDate
      approval1
      approval1Date
      approval2
      approval2Date
      json
      createdBy
      createdAt
      updatedAt
      groups
      attachments {
        items {
          id
          transactionId
          imagekey
          name
          createdAt
          meta
          date
          isExpense
          total
          gst
          net
          description
          invoice
          remark
          glDate
          isBP
          bpNumber
          groups
          updatedAt
        }
        nextToken
      }
      distributions {
        items {
          id
          transactionId
          accountNo
          account
          postingEdit
          subledgerType
          subledgerId
          subledgerName
          amount
          createdAt
          meta
          groups
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          transactionId
          userId
          username
          comment
          createdAt
          meta
          groups
          updatedAt
        }
        nextToken
      }
      actions {
        items {
          id
          transactionId
          actionType
          meta
          json
          createdAt
          groups
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onDeleteTransaction = /* GraphQL */ `
  subscription OnDeleteTransaction {
    onDeleteTransaction {
      id
      userReference
      meta
      schema
      title
      status
      owner2
      batch
      vendorNo
      vendor
      total
      gst
      net
      invoice
      invoiceDate
      multipleExpenses
      glDate
      description
      remark
      isBP
      ready
      readyDate
      approval1
      approval1Date
      approval2
      approval2Date
      json
      createdBy
      createdAt
      updatedAt
      groups
      attachments {
        items {
          id
          transactionId
          imagekey
          name
          createdAt
          meta
          date
          isExpense
          total
          gst
          net
          description
          invoice
          remark
          glDate
          isBP
          bpNumber
          groups
          updatedAt
        }
        nextToken
      }
      distributions {
        items {
          id
          transactionId
          accountNo
          account
          postingEdit
          subledgerType
          subledgerId
          subledgerName
          amount
          createdAt
          meta
          groups
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          transactionId
          userId
          username
          comment
          createdAt
          meta
          groups
          updatedAt
        }
        nextToken
      }
      actions {
        items {
          id
          transactionId
          actionType
          meta
          json
          createdAt
          groups
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onCreateHistory = /* GraphQL */ `
  subscription OnCreateHistory {
    onCreateHistory {
      id
      transactionId
      user
      jsonDiff
      createdBy
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateHistory = /* GraphQL */ `
  subscription OnUpdateHistory {
    onUpdateHistory {
      id
      transactionId
      user
      jsonDiff
      createdBy
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteHistory = /* GraphQL */ `
  subscription OnDeleteHistory {
    onDeleteHistory {
      id
      transactionId
      user
      jsonDiff
      createdBy
      createdAt
      updatedAt
    }
  }
`;
export const onCreateStatus = /* GraphQL */ `
  subscription OnCreateStatus {
    onCreateStatus {
      id
      transactionId
      status
      statusDate
      createdBy
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateStatus = /* GraphQL */ `
  subscription OnUpdateStatus {
    onUpdateStatus {
      id
      transactionId
      status
      statusDate
      createdBy
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteStatus = /* GraphQL */ `
  subscription OnDeleteStatus {
    onDeleteStatus {
      id
      transactionId
      status
      statusDate
      createdBy
      createdAt
      updatedAt
    }
  }
`;
export const onCreateDistribution = /* GraphQL */ `
  subscription OnCreateDistribution {
    onCreateDistribution {
      id
      transactionId
      accountNo
      account
      postingEdit
      subledgerType
      subledgerId
      subledgerName
      amount
      createdAt
      meta
      groups
      updatedAt
    }
  }
`;
export const onUpdateDistribution = /* GraphQL */ `
  subscription OnUpdateDistribution {
    onUpdateDistribution {
      id
      transactionId
      accountNo
      account
      postingEdit
      subledgerType
      subledgerId
      subledgerName
      amount
      createdAt
      meta
      groups
      updatedAt
    }
  }
`;
export const onDeleteDistribution = /* GraphQL */ `
  subscription OnDeleteDistribution {
    onDeleteDistribution {
      id
      transactionId
      accountNo
      account
      postingEdit
      subledgerType
      subledgerId
      subledgerName
      amount
      createdAt
      meta
      groups
      updatedAt
    }
  }
`;
export const onCreateMessage = /* GraphQL */ `
  subscription OnCreateMessage {
    onCreateMessage {
      id
      transactionId
      userId
      username
      comment
      createdAt
      meta
      groups
      updatedAt
    }
  }
`;
export const onUpdateMessage = /* GraphQL */ `
  subscription OnUpdateMessage {
    onUpdateMessage {
      id
      transactionId
      userId
      username
      comment
      createdAt
      meta
      groups
      updatedAt
    }
  }
`;
export const onDeleteMessage = /* GraphQL */ `
  subscription OnDeleteMessage {
    onDeleteMessage {
      id
      transactionId
      userId
      username
      comment
      createdAt
      meta
      groups
      updatedAt
    }
  }
`;
export const onCreateAttachment = /* GraphQL */ `
  subscription OnCreateAttachment {
    onCreateAttachment {
      id
      transactionId
      imagekey
      name
      createdAt
      meta
      date
      isExpense
      total
      gst
      net
      description
      invoice
      remark
      glDate
      isBP
      bpNumber
      groups
      updatedAt
    }
  }
`;
export const onUpdateAttachment = /* GraphQL */ `
  subscription OnUpdateAttachment {
    onUpdateAttachment {
      id
      transactionId
      imagekey
      name
      createdAt
      meta
      date
      isExpense
      total
      gst
      net
      description
      invoice
      remark
      glDate
      isBP
      bpNumber
      groups
      updatedAt
    }
  }
`;
export const onDeleteAttachment = /* GraphQL */ `
  subscription OnDeleteAttachment {
    onDeleteAttachment {
      id
      transactionId
      imagekey
      name
      createdAt
      meta
      date
      isExpense
      total
      gst
      net
      description
      invoice
      remark
      glDate
      isBP
      bpNumber
      groups
      updatedAt
    }
  }
`;
export const onCreateInvoice = /* GraphQL */ `
  subscription OnCreateInvoice {
    onCreateInvoice {
      id
      transactionId
      attachmentId
      invoice
      vendorNo
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateInvoice = /* GraphQL */ `
  subscription OnUpdateInvoice {
    onUpdateInvoice {
      id
      transactionId
      attachmentId
      invoice
      vendorNo
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteInvoice = /* GraphQL */ `
  subscription OnDeleteInvoice {
    onDeleteInvoice {
      id
      transactionId
      attachmentId
      invoice
      vendorNo
      createdAt
      updatedAt
    }
  }
`;
export const onCreateAction = /* GraphQL */ `
  subscription OnCreateAction {
    onCreateAction {
      id
      transactionId
      actionType
      meta
      json
      createdAt
      groups
      updatedAt
    }
  }
`;
export const onUpdateAction = /* GraphQL */ `
  subscription OnUpdateAction {
    onUpdateAction {
      id
      transactionId
      actionType
      meta
      json
      createdAt
      groups
      updatedAt
    }
  }
`;
export const onDeleteAction = /* GraphQL */ `
  subscription OnDeleteAction {
    onDeleteAction {
      id
      transactionId
      actionType
      meta
      json
      createdAt
      groups
      updatedAt
    }
  }
`;
export const onCreateNotification = /* GraphQL */ `
  subscription OnCreateNotification {
    onCreateNotification {
      id
      transactionId
      notificationType
      meta
      json
      createdAt
      groups
      updatedAt
    }
  }
`;
export const onUpdateNotification = /* GraphQL */ `
  subscription OnUpdateNotification {
    onUpdateNotification {
      id
      transactionId
      notificationType
      meta
      json
      createdAt
      groups
      updatedAt
    }
  }
`;
export const onDeleteNotification = /* GraphQL */ `
  subscription OnDeleteNotification {
    onDeleteNotification {
      id
      transactionId
      notificationType
      meta
      json
      createdAt
      groups
      updatedAt
    }
  }
`;
export const onCreateNextNumbers = /* GraphQL */ `
  subscription OnCreateNextNumbers {
    onCreateNextNumbers {
      id
      nextUserReferenceNumber
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateNextNumbers = /* GraphQL */ `
  subscription OnUpdateNextNumbers {
    onUpdateNextNumbers {
      id
      nextUserReferenceNumber
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteNextNumbers = /* GraphQL */ `
  subscription OnDeleteNextNumbers {
    onDeleteNextNumbers {
      id
      nextUserReferenceNumber
      createdAt
      updatedAt
    }
  }
`;
