export const uploaded = "Incomplete";
export const valid = "Valid";
export const ready = "Ready";
export const approved1 = "Approved 1";
export const approved2 = "Approved 2";
export const ap = "A/P";
export const apHold = "A/P Hold";
export const apInitial = "A/P Initial";
export const apVerified = "A/P Verified";
export const processed = "Processed";
export const deleted = "Deleted";

export const orderedStatus = [
	uploaded,
	ready,
	approved1,
	approved2,
	apHold,
	ap,
	apInitial,
	apVerified,
	processed
];

export const nextStatus = status => {
	switch (status) {
		case uploaded:
			return ready;
		case ready:
			return approved1;
		case approved1:
			return approved2;
		case approved2:
			return ap;
		case apHold:
			return ap;
		case ap:
			return apInitial;
		case apInitial:
			return apVerified;
		case apVerified:
			return null;
		case processed:
			return null;
		case deleted:
			return uploaded;
		default:
			console.log("status.js nextStatus status", status);
			throw new Error(`Unknown status ${status} in nextStatus`);
	}
};
