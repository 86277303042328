import React, { useEffect } from "react";

import * as service from "etegrisService";
import useUser from "etegris/employee/useUser";
import { useUserState } from "etegris/employee/user-context";
import { ServiceProvider } from "../../etegrisService/ServiceProvider";

import useModifyTransaction from "../useModifyTransaction";
import AccountsView from "./AccountsView";
import { SubLedgersView } from "./SubLedgersView";
import { VoucherTable } from "./VouchersTable";
import useVouchers from "./useVouchers";

export default function JdeView() {
	const {
		loadVouchers,
		isLoadingVouchers,
		voucherError,
		vouchers,
		processPayments,
		isProcessingPayments,
		processPaymentsError,
		clearErrors
	} = useVouchers();

	const userState = useUserState();

	const { loadUser } = useUser();

	const {
		isProcessingTrans,
		processTrans,
		errorTrans
	} = useModifyTransaction();

	useEffect(() => {
		loadUser();
	}, []);

	const handleButtonClick = async () => {
		clearErrors();
		loadVouchers();
	};

	const handleProcessPaymentsClick = async () => {
		clearErrors();
		processPayments();
	};

	const handleModifyTransactionsButtonClick = async () => {
		await processTrans();

		console.log("JdeView.js  update invoices clicked");
	};

	if (!userState.user) {
		return <div>Not logged in!</div>;
	}

	if (!userState.user.isJdeImport) {
		return (
			<div className="w-80 h-80 ml-40 mt-40 bg-blue-400 text-gray-100 text-2xl">
				Not Authorized to use this module!
			</div>
		);
	}

	return (
		<ServiceProvider service={service}>
			<div className="m-2 p-2 border-2 border-gray-200 rounded-md">
				<button className="jde-button" onClick={handleButtonClick}>
					Load vouchers
				</button>
				{isLoadingVouchers && (
					<div className={"text-blue-600"}>Loading Vouchers</div>
				)}
				{voucherError && (
					<div className="text-red-600">
						<div>Error loading vouchers</div>
						<div>{JSON.stringify(voucherError, null, 2)}</div>
					</div>
				)}

				<button className="jde-button" onClick={handleProcessPaymentsClick}>
					Process Payments
				</button>
				{isProcessingPayments && (
					<div className="text-gree-400">Processing Payments for vouchers</div>
				)}
				{processPaymentsError && (
					<div className="text-red-600">
						<div>Error Processing Payments</div>
						<div>{processPaymentsError}</div>
					</div>
				)}
				<button
					className="jde-button"
					onClick={handleModifyTransactionsButtonClick}
				>
					Update Transactions
				</button>
				{isProcessingTrans && (
					<div className="text-green-400">Processing Transactions</div>
				)}
				{errorTrans && (
					<div className="text-red-600">
						<div>Error Updating Transactions</div>
						<div>{errorTrans}</div>
					</div>
				)}

				<div className="mt-5">
					<VoucherTable vouchers={vouchers} />
				</div>
			</div>

			<AccountsView />

			<SubLedgersView />
		</ServiceProvider>
	);
}
