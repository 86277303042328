import React, { useState, useEffect } from "react";
// import { round } from "mathjs";

import EtegrisModalWithButton from "../EtegrisModalWithButton";
import EtegrisTabs from "2-molecules/EtegrisTabs";
// import * as actions from "../editActions";
import AttachmentCommandsTW from "./AttachmentCommandsTW";
import AttachmentExpenseTailWind from "./AttachmentExpenseTailWind";

// import Distributions from "../distribution/Distributions";
import { EtegrisImage2TW } from "./EtegrisImage2TW";

const AttachmentsModal = ({
	editTransaction,
	checkIfInvoiceExists,
	dispatch
}) => {
	const [tabs, setTabs] = useState([]);

	// const handleDistributionFieldUpdated = (distribution, fieldInfo, attachment) => {
	//
	// 	const action = {
	// 		type: actions.UpdateAttachmentDistribution,
	// 		payload: {
	// 			attachment,
	// 			distribution,
	// 			fieldInfo
	// 		}
	// 	};
	//
	// 	dispatch(action);
	//
	// };
	// const handleOnAddDistribution = (transactionId, attachment) => {
	// 	dispatch({ type: actions.AddAttachmentDistribution, payload: attachment });
	// }
	// const handleOnDeleteDistribution = (distribution, attachment) => {
	//
	// 	dispatch({ type: actions.DeleteAttachmentDistribution, payload: { distribution, attachment } })
	// }

	useEffect(() => {
		let count = 1;
		const tabsHoldModal = editTransaction.attachments.map(attachment => {
			return {
				tabName: `${count++}`,
				tabContent: (
					<>
						{
							<div className="flex flex-row ">
								<div className="w-2/3">
									<EtegrisImage2TW
										key={attachment.id}
										title={attachment.name}
										url={attachment.imagekey}
									/>
									<AttachmentCommandsTW
										attachment={attachment}
										editTransaction={editTransaction}
										dispatch={dispatch}
									/>
								</div>

								<div className="w-1/3 ml-3">
									<AttachmentExpenseTailWind
										attachment={attachment}
										vendorNo={editTransaction.transaction.vendorNo}
										validateExistanceOfInvoice={checkIfInvoiceExists}
										dispatch={dispatch}
									/>

									{/*{*/}
									{/*	attachment.isExpense &&*/}
									{/*	<Distributions*/}
									{/*		net={attachment.total ? round(attachment.total - attachment.gst, 2) : 0}*/}
									{/*		transactionId={attachment.transactionId}*/}
									{/*		attachment={attachment}*/}
									{/*		accounts={accounts}*/}
									{/*		addressBooks={addressBooks}*/}
									{/*		distributions={attachment.distributions}*/}

									{/*		fieldUpdated={handleDistributionFieldUpdated}*/}
									{/*		onAdd={handleOnAddDistribution}*/}
									{/*		onDelete={handleOnDeleteDistribution}*/}
									{/*	/>*/}
									{/*}*/}
								</div>
							</div>
						}
					</>
				)
			};
		});

		setTabs(tabsHoldModal);
	}, [editTransaction]);

	return (
		<EtegrisModalWithButton>
			<EtegrisTabs title="Attachments:" headerColor="danger" tabs={tabs} />
		</EtegrisModalWithButton>
	);
};

export default AttachmentsModal;
