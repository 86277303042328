/* This example requires Tailwind CSS v2.0+ */
import React, { useEffect } from "react";
import { useSelect } from "downshift";

// const people = [
// 	{ id: 1, name: 'Wade Cooper' },
// 	{ id: 2, name: 'Arlene Mccoy' },
// 	{ id: 3, name: 'Devon Webb' },
// 	{ id: 4, name: 'Tom Cook' },
// 	{ id: 5, name: 'Tanya Fox' },
// 	{ id: 6, name: 'Hellen Schmidt' },
// 	{ id: 7, name: 'Caroline Schultz' },
// 	{ id: 8, name: 'Mason Heaney' },
// 	{ id: 9, name: 'Claudie Smitham' },
// 	{ id: 10, name: 'Emil Schaefer' },
// ]

export default function SelectEmployee({
	employees,
	show,
	changedCalled,
	callClosed
}) {
	const {
		isOpen,
		openMenu,
		selectedItem,
		highlightedIndex,
		getMenuProps,
		// openMenu,
		getItemProps
	} = useSelect({ items: employees, initialIsOpen: true });

	useEffect(() => {
		console.log(
			"SelectEmployee.js useEffect[selectedItem] selectedItem",
			selectedItem
		);

		if (selectedItem) changedCalled(selectedItem);
	}, [selectedItem]);

	useEffect(() => {
		openMenu();
	}, [show]);

	useEffect(() => {
		if (isOpen === false) {
			if (callClosed) callClosed();
		}
	}, [isOpen]);

	// const onChange = (value) => {
	// 	console.log('SelectEmployee.js onChange value', value);
	// 	if (changedCalled) changedCalled(value);
	// };

	const menuStyles = {
		maxHeight: 300,
		maxWidth: 300,
		overflowY: "scroll",
		backgroundColor: "#fffeee",
		//margin: "5rem",
		listStyle: "none",
		position: "absolute",
		bottom: "1rem",
		zIndex: 10
	};

	return (
		<ul {...getMenuProps()} style={menuStyles}>
			{isOpen &&
				employees.map((item, index) => (
					<li
						className="px-2"
						style={
							highlightedIndex === index ? { backgroundColor: "#bde4ff" } : {}
						}
						key={`${item.id}${index}`}
						{...getItemProps({ item, index })}
					>
						{item.name}
					</li>
				))}
		</ul>
	);
}
