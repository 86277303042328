import React from "react";
import { useField, splitFormProps } from "react-form";

// function validateAddressStreet(value) {
// 	if (!value) {
// 		return "A street is required";
// 	}
// 	return false;
// }

const InputField = React.forwardRef((props, ref) => {
	// Let's use splitFormProps to get form-specific props
	const [field, fieldOptions, rest] = splitFormProps(props);

	// Use the useField hook with a field and field options
	// to access field state
	const {
		meta: { error, isTouched, isValidating },
		value,
		getInputProps
	} = useField(field, fieldOptions);

	const inputClassesGood =
		"h-8 pl-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full border-2 border-gray-300 rounded-md";
	const inputClassesError = `block w-full h-7 pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md`;

	const inputClasses = error ? inputClassesError : inputClassesGood;

	const { id, label, length } = rest;

	// Build the field
	return (
		<>
			<div className="block font-medium leading-5 text-gray-700">
				<label htmlFor={id} className="block text-sm font-medium text-gray-500">
					{label}
				</label>

				<div className="mt-1 relative rounded-md shadow-sm">
					<input
						{...getInputProps({ ref, ...rest })}
						className={inputClasses}
						name={id}
					/>
					{(value ? value.length : 0) > length ? (
						<div className="p-1 bg-yellow-100">
							Field is longer than {length}. It will be truncated on import to
							JDE
						</div>
					) : null}
					{isValidating ? (
						<em>Validating</em>
					) : isTouched && error ? (
						<div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
							<svg
								className="h-5 w-5 text-red-500"
								fill="currentColor"
								viewBox="0 0 20 20"
							>
								<path
									fillRule="evenodd"
									d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
									clipRule="evenodd"
								/>
							</svg>
						</div>
					) : null}
				</div>
				{error && <p className="mt-2 text-sm text-red-600">{error}</p>}
			</div>
		</>
	);
});

InputField.displayName = "InputField";

export default InputField;

// function MyForm() {
// 	// Use the useForm hook to create a form instance
// 	const {
// 		Form,
// 		meta: { isSubmitting, canSubmit }
// 	} = useForm({
// 		onSubmit: async (values, instance) => {
// 			// onSubmit (and everything else in React Form)
// 			// has async support out-of-the-box
// 			await sendToFakeServer(values);
// 			console.log("Huzzah!");
// 		},
// 		debugForm: true
// 	});
//
// 	return (
// 		<Form>
// 			<div>
// 				<label>
// 					Name: <InputField field="name" validate={fakeCheckValidName} />
// 				</label>
// 			</div>
// 			<div>
// 				<label>
// 					Address Street:{" "}
// 					<InputField field="address.street" validate={validateAddressStreet} />
// 				</label>
// 			</div>
//
// 			<div>
// 				<button type="submit" disabled={!canSubmit}>
// 					Submit
// 				</button>
// 			</div>
//
// 			<div>
// 				<em>{isSubmitting ? "Submitting..." : null}</em>
// 			</div>
// 		</Form>
// 	);
// }
