import React from "react";
import { useHistory } from "react-router-dom";

import * as service from "etegrisService";
import { useUserState } from "etegris/employee/user-context";
import { ServiceProvider } from "../etegrisService/ServiceProvider";
import { processFile } from "../etegrisService/predictionService";

import { AddExpense } from "./transaction/document/AddExpense";

export default function AddTransactionView() {
	const history = useHistory();
	const userState = useUserState();

	const onUploadClicked = async (file, title) => {
		const tran = await service.uploadDocument(
			file,
			title,
			userState.user.homeGroup,
			userState.user.username,
			false
		);

		history.push(`/admin/expense?id=${tran.id}`);
	};

	const onUploadBPClicked = async (file, title) => {
		const tran = await service.uploadDocument(
			file,
			title,
			userState.user.homeGroup,
			userState.user.username,
			true
		);

		history.push(`/admin/expense?id=${tran.id}`);
	};

	const setShowAdd = () => {};

	return (
		<ServiceProvider service={service}>
			<AddExpense
				onUpload={onUploadClicked}
				onUploadBP={onUploadBPClicked}
				onCloseAdd={setShowAdd}
				processFile={processFile}
			/>
		</ServiceProvider>
	);
}
