import React, { useRef } from "react";

const InvoiceCacheUserRefContext = React.createContext();

function InvoiceCacheProvider({ children }) {
	const InvoiceCache = useRef(new Map());

	return (
		<InvoiceCacheUserRefContext.Provider value={InvoiceCache}>
			{children}
		</InvoiceCacheUserRefContext.Provider>
	);
}

function useInvoiceCacheUserRef() {
	const context = React.useContext(InvoiceCacheUserRefContext);
	if (context === undefined) {
		throw new Error(
			"useInvoiceCacheState must be used within a AccountsProvider"
		);
	}
	return context;
}

export { InvoiceCacheProvider, useInvoiceCacheUserRef };
