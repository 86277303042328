import React, { useEffect, useState } from "react";

// const ArrowNarrowLeft = () => <svg className="mr-3 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg"
// 								   viewBox="0 0 20 20"
// 								   fill="currentColor">
// 	<path fillRule="evenodd"
// 		  d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
// 		  clipRule="evenodd"/>
// </svg>;
//
// const ArrowNarrowRight = () => <svg className="ml-3 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg"
// 									viewBox="0 0 20 20"
// 									fill="currentColor">
// 	<path fillRule="evenodd"
// 		  d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
// 		  clipRule="evenodd"/>
// </svg>;

const EtegrisTabs = ({ tabs, title }) => {
	const [index, setIndex] = useState(tabs.length > 0 ? tabs[0].tabName : "");

	useEffect(() => {
		if (index === "" && tabs.length > 0) setIndex(tabs[0].tabName);
	}, [tabs]);

	const className =
		"inline-block h-8 border-2 border-transparent  px-2 inline-flex items-center align-middle text-sm leading-5 font-medium bg-gray-200 text-gray-800 hover:border-gray-100 focus:outline-none hover:bg-gray-50 hover:rounded-lg focus:border-gray-400 transition ease-in-out duration-150";
	const classNameSelected =
		"inline-block h-8  rounded-lg  px-2 inline-flex items-center align-middle  leading-5 font-bold bg-gray-300 text-gray-800 focus:outline-none focus:text-indigo-800 focus:border-indigo-700 transition ease-in-out duration-150";

	return (
		<>
			<div className="mt-5 pt-px bg-white shadow-lg rounded-lg">
				<div className="mx-3 -mt-5  border-t border-gray-200 bg-gray-200  rounded-lg">
					<div className="flex justify-start">
						<div className="mx-2 mt-5 text-sm text-gray-800">{title}</div>

						<div className="flex flex-wrap w-2/3 p-2">
							{tabs.map(tab => {
								return (
									<div
										key={tab.tabName}
										className={
											tab.tabName === index ? classNameSelected : className
										}
										onClick={() => setIndex(tab.tabName)}
									>
										{tab.tabName}
									</div>
								);
							})}
						</div>
					</div>
				</div>

				<div className="m-2">
					{tabs.map(tab => {
						if (tab.tabName === index) {
							return <div key={tab.tabName}>{tab.tabContent}</div>;
						}
						return null;
					})}
				</div>
			</div>
		</>
	);
};

export default EtegrisTabs;
