import React, { useState } from "react";
import { Transition } from "@headlessui/react";

const EtegrisMenu = ({ buttonText, items, icon }) => {
	const [isOpen, setIsOpen] = useState(false);

	return (
		<>
			<div className="px-3 mt-6 relative inline-block text-left">
				<div>
					<button
						type="button"
						data-testid="etegris-menu-button"
						className="group w-full bg-gray-100 rounded-md px-3.5 py-2 text-sm font-medium text-gray-700 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-purple-500"
						id="options-menu"
						aria-haspopup="true"
						aria-expanded="true"
						onClick={() => setIsOpen(!isOpen)}
					>
						<span
							role="button-content"
							className="flex w-full justify-between items-center"
						>
							<span className="flex min-w-0 items-center justify-between space-x-3">
								{icon && icon}

								<span className="flex-1 min-w-0">
									<span className="text-gray-900 text-sm font-medium truncate">
										{buttonText}
									</span>
									{/*<span className="text-gray-500 text-sm truncate">@jessyschwarz</span>*/}
								</span>
							</span>
							{buttonText && (
								<svg
									heroicon="selector"
									className="flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 20 20"
									fill="currentColor"
									aria-hidden="true"
								>
									<path
										fillRule="evenodd"
										d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
										clipRule="evenodd"
									/>
								</svg>
							)}
						</span>
					</button>
				</div>
			</div>
			<Transition
				show={isOpen}
				enter="transition ease-out duration-100"
				enterFrom="transform opacity-0 scale-95"
				enterTo="transform opacity-100 scale-100"
				leave="transition ease-in duration-75"
				leaveFrom="transform opacity-100 scale-100"
				leaveTo="transform opacity-0 scale-95"
			>
				<div
					className="z-10 w-56 mx-3 origin-top absolute right-0 left-0 mt-1 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200"
					role="menu"
					aria-orientation="vertical"
					aria-labelledby="options-menu"
				>
					{items.map(item => {
						return (
							<div key={item.label} className="py-1">
								<a
									href="#"
									onClick={item.onClick}
									data-testid={`item-${item.label}`}
									className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
									role="menuitem"
								>
									{item.label}
								</a>
							</div>
						);
					})}
				</div>
			</Transition>
		</>
	);
};

export default EtegrisMenu;
