// const employees = [
// 	{login: 'bregan', name: 'Brendan Regan', email: 'bregan@etegris.com'},
// 	{login: 'sguerrer', name: 'Sandy Guerrero', email: 'bregan@etegris.com'},
// 	{login: 'dnahanee', name: 'Desiree George', email: 'bregan@etegris.com'},
// {login: 'kmcreynold', name: 'Kelly McReynolds', email: 'bregan@etegris.com'},
// {login: 'arwilson', name: 'Rene Campbell', email: 'bregan@etegris.com'},
// ];
const employees = [
	{
		id: 1,
		accGroup: "NV",
		hbu: "",
		login: "ap",
		name: "A/P",
		email: "ap@squamish.net"
	},
	{
		id: 2,
		accGroup: "NV",
		hbu: "0201",
		login: "cchen",
		name: "Caithlyn Chen",
		email: "caithlyn_chen@squamish.net"
	},
	{
		id: 3,
		accGroup: "NV",
		hbu: "3030",
		login: "cricketts",
		name: "Crystal Ricketts",
		email: "crystal_ricketts@squamish.net"
	},
	{
		id: 4,
		accGroup: "NV",
		hbu: "0201",
		login: "calmendare",
		name: "Cyril Almendarez",
		email: "cyril_almendarez@squamish.net"
	},
	{
		id: 5,
		accGroup: "NV",
		hbu: "0201",
		login: "dmurphy",
		name: "Denis Murphy",
		email: "Denis_Murphy@squamish.net"
	},
	{
		id: 6,
		accGroup: "NV",
		hbu: "3030",
		login: "eguss2",
		name: "Edmond Guss",
		email: "edmund_guss@squamish.net"
	},
	{
		id: 7,
		accGroup: "NV",
		hbu: "3030",
		login: "gracelewis",
		name: "Grace Lewis",
		email: "grace_lewis@squamish.net"
	},
	{
		id: 8,
		accGroup: "NV",
		hbu: "",
		login: "jajoseph",
		name: "Jordan Ann Joseph",
		email: "JordanAnn_Joseph@squamish.net"
	},
	{
		id: 9,
		accGroup: "SV",
		hbu: "",
		login: "jharry",
		name: "Josie Harry",
		email: "Josie_Harry@squamish.net"
	},
	{
		id: 10,
		accGroup: "NV",
		hbu: "3030",
		login: "kmcreynold",
		name: "Kelley McReynolds",
		email: "kelley_mcreynolds@squamish.net"
	},
	{
		id: 11,
		accGroup: "SV",
		hbu: "",
		login: "lbaker4",
		name: "Lisa Baker",
		email: "Lisa_Baker@squamish.net"
	},
	{
		id: 12,
		accGroup: "NV",
		hbu: "0201",
		login: "lzhang",
		name: "Laura Zhang",
		email: "laura_zhang@squamish.net"
	},
	{
		id: 13,
		accGroup: "NV",
		hbu: "",
		login: "nkhalid",
		name: "Nasheeba Khalid",
		email: "Nasheeba_Khalid@squamish.net"
	},
	{
		id: 14,
		accGroup: "NV",
		hbu: "3030",
		login: "arwilson",
		name: "Renee Campbell",
		email: "renee_cambell@squamish.net"
	},
	{
		id: 15,
		accGroup: "NV",
		hbu: "3030",
		login: "rgraskie",
		name: "Rosalind Graskie",
		email: "rosalind_graskie@squamish.net"
	},
	{
		id: 16,
		accGroup: "NV",
		hbu: "0201",
		login: "rsoni",
		name: "Rushil Soni",
		email: "rushil_soni@squamish.net"
	},
	{
		id: 18,
		accGroup: "NV",
		hbu: "3030",
		login: "sguerrer",
		name: "Sandy Guerrero",
		email: "sandy_guerrero@squamish.net"
	},
	{
		id: 20,
		accGroup: "NV",
		hbu: "0201",
		login: "Sharif",
		name: "Sharif Entezari",
		email: "Sharif_Entezari@squamish.net"
	},
	{
		id: 21,
		accGroup: "NV",
		hbu: "0201",
		login: "skaramian",
		name: "Sepideh Karamian",
		email: "Sepideh_Karamian@squamish.net"
	},
	{
		id: 22,
		accGroup: "NV",
		hbu: "",
		login: "tandrei",
		name: "Theresa Andrei",
		email: "theresa_andrei@squamish.net"
	},
	{
		id: 24,
		accGroup: "NV",
		hbu: "",
		login: "bregan",
		name: "Brendan Regan",
		email: "bregan@etegris.com"
	}
];

export default employees;
