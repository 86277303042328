export function parseBestPracticeText(text) {
	const descriptionLine = 13;
	const amountLine = 16;

	const lines = text.split("\n");

	let nextLinePayee = false;
	// let nextLineDetails = false;
	// let nextLineBandNumber = false;
	let nextLineWorker = false;

	let json = {
		chargeTo: []
	};

	let detailHeaderLine = 0;
	let totalDetailLine = 0;
	for (let i = 0; i < lines.length; i++) {
		const line = lines[i];
		if (line.startsWith("File #")) detailHeaderLine = i;
		if (line.startsWith("Total:")) totalDetailLine = i;
	}

	const detailStart = detailHeaderLine + 1;
	const detailEnd = totalDetailLine - 1;

	for (let i = detailStart; i < detailEnd; i = i + 2) {
		const line = lines[i];
		const fields = line.split("\t");
		const chargeTo = {
			subledgerNo: fields[4],
			account: fields[1],
			amount: +fields[2]
		};

		json.chargeTo.push(chargeTo);
	}

	for (let i = 0; i < lines.length; i++) {
		const line = lines[i];
		const fields = line.split("\t");

		// if(line.startsWith('Total:'))
		// 	nextLineDetails = false;

		if (nextLinePayee) {
			json.an8 = fields[1];
			nextLinePayee = false;
		}

		if (nextLineWorker) {
			json.worker = line;
			nextLineWorker = false;
		}

		if (line === "Payable To") nextLinePayee = true;

		// if (line.startsWith('File #'))
		// 	nextLineDetails = true;
		if (line.startsWith("Requested By:")) nextLineWorker = true;

		if (fields[0] === "Total") {
			json.total = +fields[1];
		}

		if (fields[0] === "GST") {
			json.gst = +fields[1];
		}

		if (i === 8) {
			json.bestPracticeNumber = lines[8];
		}
		if (i === 9) {
			json.date = lines[9];
		}
	}

	let descriptionLines = [];
	for (let j = descriptionLine + 1; j < amountLine; j++) {
		descriptionLines.push(lines[j]);
	}
	json.descriptions = descriptionLines;

	return json;
}
