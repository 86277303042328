import React, { useState } from "react";
import SelectEmployee from "./SelectEmployee";

import employees from "../employee/employees";

const EnterMessage = ({ transactionId, loginUser, send }) => {
	const [textMessage, setTextMessage] = useState("");
	const [show, setShow] = useState(false);

	const handleSendClicked = async () => {
		if (textMessage.length === 0) return;

		const message = {
			transactionId: transactionId,
			userId: loginUser.id,
			username: loginUser.username,
			comment: textMessage,
			meta: "beta",
			groups: ["CfsAdmin", "CfsApprover"]
		};

		setTextMessage("");

		send && send(message);
	};

	const handleKeyPress = event => {
		if (event.key === "Enter") {
			handleSendClicked();
		}
	};

	const employeeSelected = emp => {
		console.log("EnterMessage.js employeeSelected emp", emp);
		setTextMessage(textMessage + emp.name + " ");
		setShow(false);
	};

	function onChange(event) {
		const text = event.target.value;
		setTextMessage(text);

		if (text.endsWith("@")) {
			setShow(true);
		}
	}

	function callClosed() {
		setShow(false);
	}

	return (
		<div>
			{show && (
				<SelectEmployee
					className="absolute"
					employees={employees}
					show={show}
					changedCalled={employeeSelected}
					callClosed={callClosed}
				/>
			)}

			<div className="flex flex-row">
				<label htmlFor="standard-adornment-amount" className="ml-2">
					Enter Text
				</label>
				<input
					className="w-4/5 mx-2 border-b-2 hover:border-b-4"
					onKeyPress={handleKeyPress}
					id="standard-adornment-amount"
					value={textMessage}
					onChange={onChange}
				/>
				<button
					data-testid="send-message"
					aria-label="toggle password visibility"
					onClick={handleSendClicked}
				>
					<svg
						className="w-6 h-6"
						fill="none"
						stroke="currentColor"
						viewBox="0 0 24 24"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth={2}
							d="M11.933 12.8a1 1 0 000-1.6L6.6 7.2A1 1 0 005 8v8a1 1 0 001.6.8l5.333-4zM19.933 12.8a1 1 0 000-1.6l-5.333-4A1 1 0 0013 8v8a1 1 0 001.6.8l5.333-4z"
						/>
					</svg>
				</button>
			</div>
		</div>
	);
};

export default EnterMessage;
